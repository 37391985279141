import dayjs from 'dayjs';
import PlusIcon from '~/assets/images/common/plus.svg?component-solid';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { Form, FormItem, FormList } from '~/components/common/BetterForm';
import { FormListRemoverWrapper } from '~/components/common/BetterForm/FormListRemover';
import { Button } from '~/components/common/Buttons';
import { Section } from '~/components/common/FormSectionTitle';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { BillFileListView } from '~/components/file-attachments/BillFileListView';
import { LabeledVendorSearch } from '~/components/vendors';
import { useLocalization } from '~/contexts/global';
import { PropertyUnitLeaseSelect } from '~/pages/bills/components/PropertyUnitLeaeSelect';
import { cn } from '~/utils/classnames';
import type { Component } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm/type';
import type { TBillStore } from '~/pages/bills/store';

const AddBillForm: Component<{ form?: BetterForm.ContextValue; store: TBillStore; class?: string }> = (props) => {
  const { t } = useLocalization();

  return (
    <div class="mx-auto flex w-10/12 flex-col gap-5">
      <Form
        initialValues={{
          billDate: dayjs().format('YYYY-MM-DD'),
          dueDate: dayjs().format('YYYY-MM-DD'),
        }}
        defaultForm={props.form}
        class={cn('text-left', props.class)}>
        <Section title={t('Vendor information')} border>
          <div class="grid grid-cols-3 gap-5">
            <div class="col-span-full lg:col-span-2">
              <FormItem
                class="w-full"
                label={t(`Vendor`)}
                formFieldName="vendorId"
                component={LabeledVendorSearch}
                rules={[{ required: true, message: t(`Please select vendor`) }]}
                placeholder={t('Please select vendor')}
              />
            </div>
          </div>
        </Section>

        <Section title={t('Bill dates')} border>
          <div class="grid grid-cols-3 gap-5">
            <FormItem
              required
              class="col-span-full lg:col-span-1"
              formFieldName="billDate"
              label={t('Bill date')}
              rules={[{ required: true, message: t(`Please enter the {name}`, { name: t('Bill date') }) }]}
              component={DueDateInputField}
            />
            <FormItem
              required
              class="col-span-full lg:col-span-1"
              formFieldName="dueDate"
              label={t('Due date')}
              rules={[{ required: true, message: t(`Please enter the {name}`, { name: t('Due date') }) }]}
              component={DueDateInputField}
            />
          </div>
        </Section>

        <Section title={t('Bill detail')} border>
          <div class="grid grid-cols-3 gap-5">
            <PropertyUnitLeaseSelect form={props.form} />

            <FormItem
              class="col-span-full lg:col-span-2 lg:col-start-1"
              formFieldName="memo"
              label={t('Memo')}
              component={LabeledTextInput}
              placeholder={t('Please enter')}
            />
            <FormItem
              class="col-span-full lg:col-span-1"
              formFieldName="reference"
              label={t('Reference')}
              component={LabeledTextInput}
              placeholder={t('Please enter')}
            />
          </div>
        </Section>
        <Section title={t('Bill line items')} border>
          <FormList
            class="rounded-md border p-3"
            formFieldName={'lineItems'}
            renderItemsGroup={({ index, remove }) => {
              return (
                <FormListRemoverWrapper class="grid grid-cols-3 gap-5 " index={index} remove={remove} showRemove={true}>
                  <FormItem
                    class="col-span-full lg:col-span-1"
                    placeholder={t('Please select chart of account')}
                    label={t(`Chart of account`)}
                    formFieldName={['chartOfAccountId']}
                    component={LabeledChartOfAccountSelect}
                    types={['expense'] as MagicDoor.Api.ChartOfAccountType[]}
                    rules={[{ message: t(`Please select chart of account`), required: true }]}
                  />
                  <FormItem
                    class="col-span-full lg:col-span-1"
                    placeholder={t('Please input the amount')}
                    label={t(`Amount`)}
                    formFieldName={['amount']}
                    prepend="$"
                    component={LabeledNumberInput}
                    rules={[{ message: t(`Please input the amount`), required: true }]}
                  />
                  <FormItem
                    class="col-span-full lg:col-span-1"
                    placeholder={t('Please input description')}
                    label={t(`Description`)}
                    formFieldName={['description']}
                    component={LabeledTextInput}
                  />
                </FormListRemoverWrapper>
              );
            }}
            autoCombineFieldName={false}>
            {({ add }) => {
              return (
                <Button variant="text" color="link" onClick={add}>
                  <PlusIcon /> {t('Add line item')}
                </Button>
              );
            }}
          </FormList>
        </Section>
        <Section title={t('Bill invoice')}>
          <BillFileListView onlyUpload={true} autoUpload={false} />
        </Section>
      </Form>
    </div>
  );
};

export default AddBillForm;
