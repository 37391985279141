import { Route, Routes, useParams, useNavigate } from '@solidjs/router';
import { createMemo, onMount } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Empty } from '~/components/common/Empty';
import { UnitLeaseNavbar } from '~/components/units';
import { useLocalization, useProperties, useUnits } from '~/contexts/global';
import { LeaseProvider, LeaseRentTransactionsProvider, LeasesListProvider } from '~/contexts/local';
import { EditUnit } from '~/pages/units/edit-unit/EditUnit';
import { isUndefined } from '~/utils/tool';
import { UnitFiles } from './files';
import { Leases } from './leases';
import { UnitNotes } from './notes/UnitNotes';
import { Overview } from './overview';
import { Photos } from './photos';
import { RentalApplication } from './rental';
import { UnitInformation } from './unit-information';

export const UnitDetails = () => {
  const params = useParams<{ propertyId: string; portfolioId: string; unitId: string }>();
  const navigate = useNavigate();
  const { t } = useLocalization();
  const { current } = useProperties();
  const { unit, getUnit, unitActions } = useUnits();

  onMount(async () => {
    await getUnit(params.unitId);
    if (isUndefined(unit())) {
      unitActions.refetch();
    }
  });

  const breadcrumbItems = createMemo(() => [
    { label: t('Properties'), link: '/portfolios' },
    { label: current()?.portfolio?.name || t('Loading'), link: `/portfolios/${current()?.portfolio?.id}` },
    { label: current()?.name || t('Loading'), link: `/portfolios/${current()?.portfolio?.id}/properties/${current()?.id}` },
    { label: t('Units'), link: `/portfolios/${current()?.portfolio?.id}/properties/${current()?.id}/units` },
    { label: unit()?.name || t('Loading') },
  ]);

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <LeasesListProvider>
        <LeaseProvider>
          <LeaseRentTransactionsProvider>
            <div class="flex flex-col gap-5 px-8 pb-5">
              <UnitLeaseNavbar source="unit" />
              <Routes>
                <Route path="/*" component={Overview} />
                <Route path="/leases/*" component={Leases} />
                <Route path="/rental/*" component={RentalApplication} />
                <Route path="/transactions/*" element={<Empty description={t('🚧 This section is under construction')} />} />
                <Route path="/unit-information/*" component={UnitInformation} />
                <Route path="/photos/*" component={Photos} />
                <Route path="/communications/*" element={<Empty description={t('🚧 This section is under construction')} />} />
                <Route path="/edit/*" component={EditUnit} />
                <Route path="/files/*" component={UnitFiles} />
                <Route path="/notes/*" component={UnitNotes} />
              </Routes>
            </div>
          </LeaseRentTransactionsProvider>
        </LeaseProvider>
      </LeasesListProvider>
    </>
  );
};
