import { Presenter } from './Presenter';
import type { PresentableSignerList } from '~/pdfsigner/ui/types/presentableSignerList';
import type { PdfState } from '~/pdfsigner/usecases/state/pdfAppState';

export class SignerListPresenter extends Presenter<PresentableSignerList> {
  createModel(state: PdfState): PresentableSignerList {
    return {
      signers: state.selectedDocument?.signers || [],
      selectedSigner: state.selectedSigner,
    };
  }
}
