import { LeaseDraftPreviewHeader } from '~/pdfsigner/ui/components/header/LeaseDraftPreviewHeader';
import { LeaseDraftPreviewToolbar } from './toolbar/LeaseDraftPreviewToolbar';
import type { JSX } from 'solid-js';

export interface LeaseDraftPreviewContainerProps {
  children: JSX.Element;
  onSave: () => void;
  onStartSigning: () => void;
}

export const LeaseDraftPreviewContainer = (props: LeaseDraftPreviewContainerProps) => {
  return (
    <div class="flex h-[calc(100vh_-_125px)] flex-col overflow-hidden px-8 pt-5">
      <LeaseDraftPreviewHeader onStartSigning={props.onStartSigning} />
      <div class="flex flex-1 items-start gap-3 overflow-hidden">
        <div class="thinscroll h-full min-w-0 grow overflow-auto rounded-lg">{props.children}</div>
        <LeaseDraftPreviewToolbar onSave={props.onSave} />
      </div>
    </div>
  );
};
