import { useNavigate } from '@solidjs/router';
import { createEffect, createMemo, createResource, createSignal, on, untrack } from 'solid-js';
import { createStore } from 'solid-js/store';
import { useFormContext } from '~/components/common/BetterForm/context';
import { FileRepository } from '~/components/common/Upload/request';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/localization';
import { createMagicDoorContext } from '~/contexts/utils';
import { RentalApplicationRepository } from '~/repositories/rentalApplicationRepository';
import { RentalApplicationPaymentSettingRepository } from '~/repositories/settings/rentalApplicationPaymentSettingsRepository';
import { TenantRepository } from '~/repositories/tenantRepository';
import { UnitRepository } from '~/repositories/unitRepository';
import { emptyPlaceholder } from '~/utils/constant';
import type { BetterForm } from '~/components/common/BetterForm';
import type { FileType } from '~/swagger/Api';

const unitRepo = new UnitRepository();
const tenantRepo = new TenantRepository();
const applicationRepo = new RentalApplicationRepository();
const rentalApplicationPaymentSettingsRepo = new RentalApplicationPaymentSettingRepository();

export const NEW_RENTAL_APPLICATION_REVIEW_STEP = Infinity;

const initStep = 0;

export const [NewRentalApplicationProvider, useNewRentalApplication] = createMagicDoorContext('NewRentalApplication', () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const [store, setStore] = createStore<{
    applicationId: string;
    currentStep: number;
    stepChangeLoading: boolean;
    disableNext: boolean;
    isSubmittingStep: number;
    currentTenant: Partial<MagicDoor.Api.HydratedTenantDto>;
    activeTab: string;
    intent: Partial<MagicDoor.Api.RentalApplicationPaymentIntentDto>;
    steps: string[];
    needPayment: boolean;
    selectedUnits: MagicDoor.Api.HydratedUnitDto[];
    selectedApplyWith: MagicDoor.Api.HydratedTenantDto[];
    rentalApplication?: MagicDoor.Api.RentalApplicationDto;
  }>({
    applicationId: '',
    currentStep: initStep,
    stepChangeLoading: false,
    disableNext: false,
    isSubmittingStep: -Infinity,
    currentTenant: {},
    activeTab: 'new-applicant',
    intent: {},
    steps: [t('Tenant profile'), t('Rental history'), t('Employment'), t('Identity'), t('Questions & terms')],
    needPayment: false,
    selectedUnits: [],
    selectedApplyWith: [],
  });
  const [units] = createResource(async () => {
    try {
      const unitRes = await unitRepo.getUnits();
      return { ...unitRes, loading: false };
    } catch (error) {
      return { loading: true, items: [] };
    }
  });
  const [tenants] = createResource(async () => {
    try {
      const tenantRes = await tenantRepo.getTenants();
      return { ...tenantRes, loading: false };
    } catch (error) {
      return { loading: true, items: [] };
    }
  });
  const [questions] = createResource(() => applicationRepo.getApplicationQuestions());
  const [regions] = createResource(() => applicationRepo.getRentalApplicationRegions());

  const form = useFormContext();
  const firstTentantOptions = createMemo(() => {
    const selectedApplyWith = form.formStore.applyingWith;
    return tenants()?.items.filter((item) => !selectedApplyWith?.includes(item.id));
  });

  createEffect(() => {
    if (!firstTentantOptions()?.includes(form.formStore.tenantId)) {
      form.setFieldsValue({ tenantId: undefined });
    }
  });

  createEffect(
    on(
      () => store.activeTab,
      () => {
        form.setFieldsValue({ tenantId: undefined, lastName: '', firstName: '' });
        if (store.activeTab === 'new-applicant') {
          form.resetFields(['tenantId', 'phone', 'email', 'dateOfBirth']);
        }
      }
    )
  );

  const applyWithOptions = createMemo(() => {
    const selectedTenant = form.formStore.tenantId;
    return tenants()?.items.filter((item) => item.id !== selectedTenant);
  });

  createEffect(() => {
    form.setFieldsValue({
      applyingWith: untrack(() => form.formStore.applyingWith)?.filter(
        (it: string) => applyWithOptions()?.findIndex((item) => item.id === it) !== -1
      ),
    });
  });

  function getValueFromStore() {
    const baseData = { ...form.formStore } as any;
    if (store.currentTenant.id === baseData.tenantId && store.currentTenant.id) {
      baseData.lastName = store.currentTenant.lastName || emptyPlaceholder;
      baseData.firstName = store.currentTenant.firstName || emptyPlaceholder;
    }
    return baseData;
  }

  async function onCreate() {
    const res = await applicationRepo.createApplication(getValueFromStore());

    if (res.id) {
      setStore('applicationId', res.id);
      setStore('rentalApplication', res);
    }
  }

  async function onEdit() {
    const res = await applicationRepo.updateApplication(store.applicationId, getValueFromStore());
    setStore('rentalApplication', res);
  }

  async function onStepChange(step: number) {
    try {
      setStore('stepChangeLoading', true);
      await _onStepChange(step);
    } finally {
      setStore('stepChangeLoading', false);
    }
  }

  const checkPaymentSettings = async () => {
    const paymentSettings = await rentalApplicationPaymentSettingsRepo.getRentalApplicationPaymentSettings();

    if (paymentSettings.requirePaymentForApplication) {
      setStore({
        needPayment: true,
      });
    }
  };

  async function _onStepChange(step: number) {
    if (step > store.currentStep) {
      setStore('isSubmittingStep', store.currentStep);
      const { validateStatus } = form.validateForm();
      if (!validateStatus) return;
    } else {
      setStore('isSubmittingStep', -Infinity);
    }

    if (step === NEW_RENTAL_APPLICATION_REVIEW_STEP) {
      if (store.needPayment) {
        navigate(`/leasing/rental-applications/new/${store.applicationId}/payment`);
      } else {
        toast.success(t('Rental application created successfully'));
        navigate('/leasing/rental-applications', { replace: true });
      }
      return;
    }

    if (step > store.currentStep || step === store.currentStep) {
      if (!store.applicationId) {
        await onCreate();
      } else if (store.applicationId) {
        await onEdit();
      }
      setStore('currentStep', step);
    } else {
      setStore('currentStep', step);
    }
  }

  const validFormItem = (field: BetterForm.NamePath, setError = true, specificFieldPath?: string) => {
    return form.validateForm(field, setError, specificFieldPath);
  };

  type SelectedFiles = {
    Identification?: File;
    EmploymentVerification?: File;
  };

  const [selectedFiles, setSelectedFiles] = createSignal<SelectedFiles>({});

  const setFile = (file: File | undefined, type: FileType) => {
    untrack(() => {
      setSelectedFiles({
        ...selectedFiles(),
        [type]: file,
      });
    });
  };

  const file = new FileRepository();
  const uploadAllFiles = async (url: string) => {
    const files = selectedFiles();
    for (const key of Object.keys(files) as (keyof SelectedFiles)[]) {
      if (files[key]) {
        await file.uploadFile(url, files[key]!);
      }
    }
  };

  return {
    units,
    tenants,
    firstTentantOptions,
    applyWithOptions,
    onStepChange: onStepChange,
    store: store,
    questions,
    regions,
    setStore: setStore,
    checkPaymentSettings: checkPaymentSettings,
    form,
    validFormItem,
    selectedFiles,
    setSelectedFiles,
    setFile,
    uploadAllFiles,
  };
});
