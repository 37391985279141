import { useNavigate } from '@solidjs/router';
import dayjs from 'dayjs';
import { createMemo } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import fallbackListingImage from '~/assets/images/units/listing/defaultListing.jpg';
import DisplayAddress from '~/components/common/DisplayAddress';
import { propertyTypes } from '~/components/properties/PropertyType';
import { Image } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
// import { ListingStatus } from '~/pages/listing/components/ListingStatus';
import { ListingIs } from '~/pages/listing/components/ListingIs';
import { cn } from '~/utils/classnames';
import { getFileAllUrl } from '~/utils/file';
import type { Component } from 'solid-js';

type PropertyDistributionProps = {
  class?: string;
  listing?: any;
};

const ListingCard: Component<PropertyDistributionProps> = (props) => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const imgSrc = createMemo(() => {
    if (props.listing === null || !props.listing?.primaryImage || props.listing?.primaryImage?.length === 0) return fallbackListingImage;
    return getFileAllUrl(props.listing?.primaryImage?.signedUrl);
  });

  return (
    <div class={cn('overflow-hidden rounded-lg bg-[#F6F6F6] shadow-lg', props.class)}>
      <div class="bg-white p-2">
        <Image class="h-[143px] w-full rounded-lg" src={imgSrc()} />
        <div class="mt-4 flex items-center justify-between gap-2 text-lg font-semibold text-text-level01">
          <span class="truncate">{props.listing?.title}</span>
          <div class="flex items-center gap-1 text-nowrap rounded-full bg-[#8712CF] px-3 py-1 text-xs text-white">
            <Dynamic component={propertyTypes[props.listing?.property?.type as MagicDoor.Api.PropertyType].icon} class="size-4" />
            {propertyTypes[props.listing?.property?.type as MagicDoor.Api.PropertyType].name}
          </div>
        </div>
        <DisplayAddress class="mt-1 truncate text-xs leading-5 text-text-level03" address={props.listing?.property?.address} />
        <div class="my-3 h-[0.5px] w-full bg-[#BABABA]" />
        <div class="mb-1 flex items-center justify-between">
          <div class="flex items-center gap-2">
            {/* <ListingStatus status={props.listing?.status} listed={props.listing?.listed} /> */}
            <ListingIs listed={props.listing?.listed} />
            <div class="text-xs text-text-level03">
              {t('Updated')} {dayjs(props.listing?.lastUpdated).format('MMM DD')}
            </div>
          </div>
          <span class="cursor-pointer text-sm text-essential-colour" onClick={() => navigate(`/listing/details/${props.listing.id}`)}>
            {t('View listing')}
          </span>
        </div>
      </div>
      <div class="my-3 w-full text-center text-xs text-text-level03">Past 30 days</div>
      <div class="gird mb-4 flex w-full grid-cols-3 px-2 text-center">
        <div class="w-full border-r border-[#BABABA]">
          <div class="text-xs font-medium text-[#6A6A6B]">{t('Views')}</div>
          <div class="mt-1 text-sm font-medium text-black">{props.listing?.views}</div>
        </div>
        <div class="w-full border-r border-[#BABABA]">
          <div class="text-xs font-medium text-[#6A6A6B]">{t('Guest cards')}</div>
          <div class="mt-1 text-sm font-medium text-black">{props.listing?.guestCards}</div>
        </div>
        <div class="w-full">
          <div class="text-xs font-medium text-[#6A6A6B]">{t('Applications')}</div>
          <div class="mt-1 text-sm font-medium text-black">{props.listing?.recentRentalApplications}</div>
        </div>
      </div>
    </div>
  );
};

export default ListingCard;
