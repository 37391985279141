import { useNavigate, useParams } from '@solidjs/router';
import { createEffect, createSignal, Show } from 'solid-js';
import { LinkButton } from '~/components/common/Buttons';
import { AiButton } from '~/components/common/Buttons/AiButton';
import LabeledTextArea from '~/components/common/Inputs/LabeledTextArea';
import { Section } from '~/components/common/Section';
import { Skeleton } from '~/components/ui';
import { useLocalization, useUnitListings } from '~/contexts/global';

export const AiGeneratedListing = (props: { description: string | undefined }) => {
  const { t } = useLocalization();
  const [textAreaVal, setTextAreaVal] = createSignal<string | undefined>();
  const { updateUnitLising } = useUnitListings();
  const params = useParams<{ listingId: `${number}` }>();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      const param = { description: textAreaVal() };
      await updateUnitLising(param, params.listingId);
      navigate(-1);
    } catch (e) {
      console.log(e);
    }
  };
  createEffect(() => {
    if (props.description) {
      setTextAreaVal(props.description);
    }
  });
  return (
    <Section title={t('AI generated listing')} class="flex-1">
      <div class="px-6 py-4">
        <Show when={props.description} fallback={<Skeleton class="h-24 w-full" />}>
          <LabeledTextArea
            rows={5}
            class="border-0"
            onInput={(val) => setTextAreaVal(val)}
            value={textAreaVal()}
            placeholder={t('Please enter')}
          />
        </Show>
        <div class="mt-4 flex justify-end gap-4 align-middle">
          <LinkButton
            href={`/listing/details/${params.listingId}`}
            variant="outlined"
            class="border-input-border py-2 font-medium text-link hover:bg-link/10">
            {t('Cancel')}
          </LinkButton>
          <AiButton text={t('Approve')} onClick={handleSubmit} />
        </div>
      </div>
    </Section>
  );
};
