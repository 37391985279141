import { LeaseSigningHeader } from './header/LeaseSigningHeader';
import type { JSX } from 'solid-js';

export interface LeaseSigningContainerProps {
  children: JSX.Element;
  onSendReminders: () => void;
}

export const LeaseSigningContainer = (props: LeaseSigningContainerProps) => {
  return (
    <div class="flex h-[calc(100vh_-_125px)] flex-col overflow-auto px-8 pt-5">
      <LeaseSigningHeader shouldShowReminderButton={false} onSendRemindersClicked={props.onSendReminders} />
      {props.children}
    </div>
  );
};
