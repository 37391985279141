import { useParams } from '@solidjs/router';
import { createMemo, Show } from 'solid-js';
import PlusIcon from '~/assets/images/common/plus.svg?component-solid';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { Form, FormItem, FormList } from '~/components/common/BetterForm';
import { FormListRemoverWrapper } from '~/components/common/BetterForm/FormListRemover';
import { Button } from '~/components/common/Buttons';
import { Section } from '~/components/common/FormSectionTitle';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { BillFileListView } from '~/components/file-attachments/BillFileListView';
import { LabeledVendorSearch } from '~/components/vendors';
import { useLocalization } from '~/contexts/global';
import { AttachmentsProvider } from '~/contexts/local';
import { PropertyUnitLeaseSelect } from '~/pages/bills/components/PropertyUnitLeaeSelect';
import { currency } from '~/utils/number';
import { PaymentTable } from './PaymentTable';
import type { Payment } from './PaymentTable';
import type { Component } from 'solid-js';
import type { BetterForm } from '~/components/common/BetterForm';
import type { TBillStore } from '~/pages/bills/store';

interface BillDetailsProps {
  form: BetterForm.ContextValue;
  store: TBillStore;
  bill?: MagicDoor.Api.HydratedBillDto;
  payment: Payment[];
}

const BillDetailsView: Component<BillDetailsProps> = (props) => {
  const { t } = useLocalization();
  const { billId } = useParams();

  const billTotal = createMemo(() =>
    props.form.formStore.lineItems?.reduce((previous: any, current: any) => previous + Number(current.amount || 0), 0)
  );
  const paymentTotal = createMemo(() =>
    props.form.formStore.lineItems?.reduce((previous: any, current: any) => previous + Number(current.paid || 0), 0)
  );

  return (
    <div class="mx-auto my-8 w-10/12 min-w-[730px]">
      <Form defaultForm={props.form}>
        <Section title={`${t('Vendor')}/${t('Payee')}`} border>
          <div class="grid grid-cols-3 gap-5">
            <div class="col-span-2">
              <FormItem
                class="w-full"
                label={t(`Vendor`)}
                formFieldName="vendorId"
                component={LabeledVendorSearch}
                rules={[{ required: true, message: t(`Please select vendor`) }]}
                placeholder={t('Please select vendor')}
                valuePropName="defaultSelectedIds"
              />
            </div>

            <PropertyUnitLeaseSelect form={props.form} />

            <FormItem
              rules={[{ required: true, message: t(`Please enter the {name}`, { name: t('Bill date') }) }]}
              required
              class="col-start-1"
              formFieldName="billDate"
              label={t('Bill date')}
              component={DueDateInputField}
            />
            <FormItem
              rules={[{ required: true, message: t(`Please enter the {name}`, { name: t('Due date') }) }]}
              required
              class="col-span-1"
              formFieldName="dueDate"
              label={t('Due date')}
              component={DueDateInputField}
            />
            {/* TODO */}
            {/* <div class="col-span-full mt-4 flex items-center gap-2 text-sm text-text-level02">
              <Checkbox
                checked={props.form.formStore.allocationHandling === 'failIfAllocationsExist'}
                onInput={(e) => {
                  props.form.setFormStore('allocationHandling', e ? 'failIfAllocationsExist' : 'leavePaymentAsIs');
                }}
              />
              {t('Fail to update if allocation exist')}
            </div> */}
          </div>
        </Section>

        <Section title={t('Bill detail')}>
          <div class="w-full overflow-hidden rounded-lg border bg-input">
            <div class="flex items-center justify-between border-b bg-white py-3 text-xs ">
              <div class="text-lef w-3/12 px-4  text-text-level03">{t('Chart of account')}</div>
              <div class="w-4/12 px-4 text-left   text-text-level03">{t('Description')}</div>
              <div class="w-2/12 px-4 text-left  text-text-level03 ">{t('Unpaid balance')}</div>
              <div class="w-3/12 px-4 text-right  text-text-level03 ">{t('Amount')}</div>
            </div>
            <FormList
              formFieldName={'lineItems'}
              renderItemsGroup={({ index, remove }) => {
                return (
                  <FormListRemoverWrapper index={index} remove={remove} showRemove={true} class=" py-3">
                    <div class="flex items-start justify-between text-xs">
                      <div class="w-3/12 px-4">
                        <FormItem
                          class="bg-white"
                          placeholder={t('Please select chart of account')}
                          formFieldName={['chartOfAccountId']}
                          component={LabeledChartOfAccountSelect}
                          types={['expense'] as MagicDoor.Api.ChartOfAccountType[]}
                          rules={[{ message: t(`Please select chart of account`), required: true }]}
                        />
                      </div>
                      <div class="w-4/12 px-4">
                        <FormItem
                          inputContainerClass="bg-white"
                          placeholder={t('Please input description')}
                          formFieldName={['description']}
                          component={LabeledTextInput}
                        />
                      </div>
                      <div class="mt-2.5 w-2/12 px-4">
                        {currency((props.bill?.lineItems[index()]?.amount || 0) - (props.bill?.lineItems[index()]?.paid || 0))}
                      </div>
                      <div class="w-3/12 px-4">
                        <FormItem
                          inputContainerClass="bg-white text-right"
                          inputClass="text-right"
                          prepend="$"
                          placeholder={t('Please input the amount')}
                          formFieldName={['amount']}
                          component={LabeledNumberInput}
                          rules={[{ message: t(`Please input the amount`), required: true }]}
                        />
                      </div>
                    </div>
                  </FormListRemoverWrapper>
                );
              }}
              autoCombineFieldName={false}>
              {() => <></>}
            </FormList>
          </div>
          <div class="mt-3 flex items-center justify-between text-sm uppercase text-text-level02">
            <Button
              variant="text"
              color="link"
              onClick={() => {
                props.form.setFormStore('lineItems', (prev: any) => {
                  return [...prev, {}];
                });
              }}>
              <PlusIcon /> {t('Add line item')}
            </Button>

            <span>
              {t('Bill total')}：<span class="text-base text-text-level01">{currency(billTotal())}</span>
            </span>
          </div>
        </Section>

        <Show when={props.payment && props.payment.length}>
          <Section title={t('Payment')}>
            <PaymentTable data={props.payment} />
            <Show when={props.payment.length > 0}>
              <div class="mt-3 text-right text-sm uppercase text-text-level02">
                {t('Payment total')}: <span class="text-base text-text-level01">{currency(paymentTotal())}</span>
              </div>
            </Show>
          </Section>
        </Show>

        <Section title={t('Bill invoice')}>
          <AttachmentsProvider>
            <BillFileListView billId={props.bill?.id ?? billId} />
          </AttachmentsProvider>
        </Section>
      </Form>
    </div>
  );
};

export default BillDetailsView;
