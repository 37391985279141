import { UseCase } from './useCase';
import type { LeaseTemplateUpdateRequest } from './types/leaseTemplateUpdateRequest';

export class UpdateLeaseTemplateUseCase extends UseCase {
  public async runLogic(request: LeaseTemplateUpdateRequest) {
    if (!request) {
      return;
    }
    let desiredCategoryIndex = request.leaseCategoryIndex;
    if (
      desiredCategoryIndex === undefined ||
      desiredCategoryIndex < 0 ||
      desiredCategoryIndex >= this.state.leaseTemplateCategories.length
    ) {
      desiredCategoryIndex = 0;
    }

    if (
      this.state.dataPathManager.doesDataPathWithKeyExist(request.dataPathKey) &&
      this.state.dataPathManager.getSelectedNodeKey() !== request.dataPathKey
    ) {
      this.state.dataPathManager.selectNodeWithKey(request.dataPathKey);
    }
    if (this.state.selectedDocument) {
      this.state.selectedDocument.category = this.state.leaseTemplateCategories[desiredCategoryIndex];
      this.state.selectedDocument.title = request.title ? request.title : this.state.selectedDocument?.title;
    }
  }
}
