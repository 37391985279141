import { Route, Routes, useNavigate, useParams } from '@solidjs/router';
import { createMemo, createEffect } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { TabLinks } from '~/components/ui';
import { useLocalization, useUnitListings } from '~/contexts/global';
import { ListingSyndicationsTab } from './tabs/ListingSyndicationsTab';
import { UnitInformationTab } from './tabs/UnitInformationTab';
import { UnitListingPhotosTab } from './tabs/UnitListingPhotosTab';

const ListingDetailsPage = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const params = useParams<{ listingId: `${number}` }>();
  const { getUnitListingsDetail, unitListingDetail } = useUnitListings();

  const breadcrumbItems = createMemo(() => [
    { label: t('Listing'), link: '/listing' },
    { label: unitListingDetail()?.title || t('Listing details') },
  ]);

  createEffect(() => {
    getUnitListingsDetail(params.listingId);
  });
  return (
    <div class="relative">
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="my-5 flex  flex-col-reverse gap-4  px-8 lg:flex-row">
        <div class="flex w-full flex-col gap-4">
          <TabLinks
            class="mb-4 rounded-lg border border-partingline bg-white"
            items={[
              { label: t('Unit information'), href: '' },
              { label: t('Listing syndications'), href: 'listing-syndications' },
              { label: t('Photos'), href: 'photos' },
            ]}
            replace
          />
          <Routes>
            <Route path="/" component={UnitInformationTab} />
            <Route path="/listing-syndications/*" component={ListingSyndicationsTab} />
            <Route path="/photos" component={UnitListingPhotosTab} />
          </Routes>
        </div>
      </div>
      {/* <UnderConstruction /> */}
    </div>
  );
};

export default ListingDetailsPage;
