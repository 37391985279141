import { useParams, useNavigate } from '@solidjs/router';
import { createEffect, For, createSignal, Show } from 'solid-js';
import { Pagination } from '~/components/ui/Pagination';
import { usePortfolios, useUnitListings } from '~/contexts/global';
import { useLocalPagination } from '~/hooks';
import ListingCard from '~/pages/listing/components/ListingCard';
import { PortfolioSummary } from '~/pages/listing/components/PortfolioSummary';
import { PortfolioTabs } from '~/pages/listing/components/PortfolioTabs';
import type { UnitListingFilter } from '~/repositories/unitListingsRepository';

type ListInterface =
  | {
      property: MagicDoor.Api.PropertyDto;
      portfolio: MagicDoor.Api.PortfolioDto;
    }
  | MagicDoor.Api.UnitListingDto
  | null;
export const ListListingPage = () => {
  const { portfolios, current, currentId, setCurrentId } = usePortfolios();
  const { unitListings, getUnitListings } = useUnitListings();
  const params = useParams<{ portfolioId?: `${number}` }>();
  const [filter, setFilter] = createSignal<UnitListingFilter>();
  const [listings, setListings] = createSignal<ListInterface[]>([]);
  const [totalPages, setTotalPages] = createSignal<number>(0);
  const { page, pageSize, setPage, setPageSize } = useLocalPagination('UnitListings');
  const navigate = useNavigate();

  const handleChange = (page: number) => {
    setPage(page);
    setFilter({ ...(filter() as UnitListingFilter), page: page });
    getUnitListings(filter() as UnitListingFilter);
  };

  createEffect(() => {
    if (params.portfolioId && /^\d+$/.test(params.portfolioId)) {
      setCurrentId(params.portfolioId);
    } else if (portfolios()?.[0]?.id) {
      const currentId = current()?.id ?? setCurrentId(portfolios()?.[0]?.id);
      params.portfolioId == null && navigate(`/listing/${currentId}`, { replace: true });
      setPage(1);
    }
    setFilter({ page: page(), pageSize: pageSize(), portfolioId: currentId() });
    getUnitListings(filter() as UnitListingFilter);
  });
  createEffect(() => {
    const arrTemp = [] as ListInterface[];
    setTotalPages(unitListings()?.totalPages || 0);
    unitListings()?.items.forEach((item) => {
      const property = unitListings()?.properties?.find((pro) => pro.id === item.propertyId);
      const portfolio = unitListings()?.portfolios?.find((portfolio) => portfolio.id === item.portfolioId);
      const listingTemp = { ...item, property, portfolio };
      arrTemp.push(listingTemp);
    });
    setListings(arrTemp);
  });
  return (
    <div class="space-y-5 p-8 pt-5">
      <PortfolioTabs portfolios={portfolios()} selected={current()} />
      <PortfolioSummary />
      <div class="grid w-full grid-cols-4 gap-3">
        <For each={listings()}>{(listing) => <ListingCard listing={listing} class="col-span-1" />}</For>
      </div>
      <Show when={totalPages()}>
        <div class="flex justify-center">
          <Pagination total={totalPages()} value={page()} onChange={(payload) => handleChange(payload)} />
        </div>
      </Show>
      {/* <UnderConstruction /> */}
    </div>
  );
};
