import IconRemoveHandler from '~/assets/images/lease-template/removerHandler.svg';

export interface RemoveHandlerProps {
  onClick: () => void;
}

export const RemoveHandler = (props: RemoveHandlerProps) => {
  return (
    <div class="absolute inset-0 size-full cursor-pointer" onClick={() => props.onClick()}>
      <img src={IconRemoveHandler} draggable={false} class="size-full select-none" />
    </div>
  );
};
