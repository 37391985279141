import { useNavigate, useParams } from '@solidjs/router';
import { createMemo } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { useLocalization } from '~/contexts/global';
import Composer from '~/pdfsigner/ui/screens/Composer';

export const LeaseTemplateDetails = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { templateId } = useParams();

  const breadcrumbItems = createMemo(() => [{ label: t('Lease templates'), link: '/leasing/lease-templates' }, { label: templateId }]);

  return (
    <div>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <Composer />
    </div>
  );
};
