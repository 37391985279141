import { leaseTemplateRepository } from '~/pdfsigner/repositories/leaseTemplateRepository';
import { ChangeNumberOfSignersUseCase } from './changeNumberOfSignersUseCase';
import { GetDataPathsUseCase } from './getDataPathsUseCase';
import { UseCase } from './useCase';

export class LoadLeaseTemplateUseCase extends UseCase {
  public async runLogic(templateId?: string) {
    if (!templateId) {
      return;
    }
    const [leaseTemplateCategories] = await Promise.all([
      leaseTemplateRepository.getLeaseTemplateCategories(),
      new GetDataPathsUseCase().execute(),
    ]);
    this.state.leaseTemplateCategories = leaseTemplateCategories;
    const defaultCategory = this.state.leaseTemplateCategories.find((category) => category.isDefault);
    const selectedCategory = this.state.leaseTemplateCategories.find(
      (category) => category.id === this.state.selectedDocument?.category?.id
    );
    if (!selectedCategory && this.state.selectedDocument) {
      this.state.selectedDocument.category = defaultCategory ? defaultCategory : this.state.leaseTemplateCategories[0];
    }
    const document = await leaseTemplateRepository.getLeaseTemplate(templateId);
    document.category = this.state.leaseTemplateCategories.find((category) => category.id === document.category?.id);
    this.state.selectedDocument = document;
    if (document.signers.length === 0) {
      new ChangeNumberOfSignersUseCase().execute(2);
    }
  }
}
