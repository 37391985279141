import { For } from 'solid-js';
import IconArrowDown from '~/assets/images/lease-template/arrowDown.svg';
import IconDateField from '~/assets/images/lease-template/dateFiled.svg';
import IconInitialField from '~/assets/images/lease-template/initialFeild.svg';
import IconSignatureField from '~/assets/images/lease-template/signatureField.svg';
import IconTextFiled from '~/assets/images/lease-template/textFiled.svg';
import { TintedSVG } from '~/components/common/TintedSvg';
import { useLocalization } from '~/contexts/global';
import { AnnotationType } from '~/pdfsigner/usecases/types/annotation';
import { cn } from '~/utils/classnames';
import { DropdownWithTree } from './TreeDropdown';
import type { DataPathNode } from '~/pdfsigner/usecases/types/dataPathNode';

export interface DataFieldsSelectorProps {
  selectedAnnotationType?: AnnotationType;
  onChangeSelectedAnnotationType: (value: AnnotationType) => void;
  dataPath?: DataPathNode[];
  selectedNode?: DataPathNode;
  onUpdateDataPath?: (key: string) => void;
}

const { TEXT, INITIALS, SIGNATURE, DATE, AUTOFILL } = AnnotationType;

export const DataFieldsSelector = (props: DataFieldsSelectorProps) => {
  const { t } = useLocalization();
  const dataFieldOptions = [
    { label: t('Signature'), icon: IconSignatureField, value: SIGNATURE },
    { label: t('Initials'), icon: IconInitialField, value: INITIALS },
    { label: t('Date'), icon: IconDateField, value: DATE },
    { label: t('Text'), icon: IconTextFiled, value: TEXT },
  ];

  const onChangeSelectedNode = (node: DataPathNode) => {
    props.onChangeSelectedAnnotationType(AUTOFILL);
    props.onUpdateDataPath?.(node.key);
  };

  return (
    <div class="flex flex-col gap-2">
      <p class="text-sm text-text-level03">{t('Data Field')}</p>
      <For each={dataFieldOptions}>
        {(option) => (
          <div
            class={cn(
              'flex w-full cursor-pointer items-center gap-1.5 rounded-lg border border-input-border px-5 py-2.5 text-text-level02 transition-all hover:border-primary',
              { 'bg-primary border-primary text-white': props.selectedAnnotationType === option.value }
            )}
            onClick={() => props.onChangeSelectedAnnotationType(option.value)}>
            <TintedSVG svg={option.icon} color={props.selectedAnnotationType === option.value ? '#FFFFFF' : '#464F74'} />
            <span class="text-sm text-inherit">{option.label}</span>
          </div>
        )}
      </For>
      <p class="mt-2 text-sm text-text-level03">{t('Autofill')}</p>
      <div class="flex items-center">
        <div
          onClick={() => props.onChangeSelectedAnnotationType(AUTOFILL)}
          class={cn(
            'inline-flex grow cursor-pointer rounded-l-lg border border-input-border px-4 py-2.5 text-text-level02 transition-all hover:border-primary',
            {
              'bg-primary border-primary text-white': props.selectedAnnotationType === AUTOFILL,
            }
          )}>
          <span class="text-sm font-medium">{props.selectedNode?.name ?? t('Please select')}</span>
        </div>
        <DropdownWithTree
          data={props.dataPath}
          selectedNode={props.selectedNode}
          enabled={true}
          onSelect={onChangeSelectedNode}
          triggerElement={
            <div class="inline-flex items-center justify-center rounded-r-lg border border-l-0 border-input-border p-2.5">
              <img src={IconArrowDown} />
            </div>
          }
        />
      </div>
    </div>
  );
};
