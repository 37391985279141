import { FormItem, FormList } from '~/components/common/BetterForm/Form';
import { FormListRemoverWrapper } from '~/components/common/BetterForm/FormListRemover';
import { Button } from '~/components/common/Buttons';
import { Section } from '~/components/common/FormSectionTitle';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { useLocalization } from '~/contexts/global';
import { useNewRentalApplication } from '~/contexts/local';
import { EmploymentSubForm } from '~/pages/properties/property-details/rental-application-details/edit-components/WorkHistoryModal';

export const Employment = () => {
  const { t } = useLocalization();
  const { validFormItem } = useNewRentalApplication();

  return (
    <>
      <Section required border title={t('Income Information')}>
        <FormItem
          label={t('Annual income')}
          placeholder={t('Input Annual Income')}
          formFieldName={['incomeInformation', 'annualIncome']}
          component={LabeledNumberInput}
          prepend="$"
          max={2147483647}
          min={0}
          rules={[
            { required: true, message: t('Please input annual income') },
            { type: 'number', message: t('Please input a valid number') },
          ]}
          onInput={() => {
            validFormItem('firstname');
          }}
        />
      </Section>
      <Section border title={t('Employment')}>
        <FormList
          formFieldName={'employment'}
          renderItemsGroup={({ index, remove }) => {
            return (
              <FormListRemoverWrapper index={index} remove={remove} showRemove={index() > 0}>
                <EmploymentSubForm index={index()} />
              </FormListRemoverWrapper>
            );
          }}>
          {({ add }) => {
            return (
              <Button variant="outlined" onClick={() => add()}>
                {t('Add more')}
              </Button>
            );
          }}
        </FormList>
      </Section>
    </>
  );
};
