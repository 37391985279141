import { Show } from 'solid-js';
import { FormItem } from '~/components/common/BetterForm';
import { LabeledLeaseSearch } from '~/components/leases/LeaseSearch';
import { LabeledPropertySearch } from '~/components/properties/PropertySearch';
import { LabeledUnitSearch } from '~/components/units/UnitSearch';
import { useLocalization } from '~/contexts/global';
import type { BetterForm } from '~/components/common/BetterForm';

export const PropertyUnitLeaseSelect = (props: { form?: BetterForm.ContextValue }) => {
  const { t } = useLocalization();

  return (
    <>
      <div class="col-span-full lg:col-span-2">
        <FormItem
          label={t(`Property`)}
          formFieldName="propertyId"
          component={LabeledPropertySearch}
          rules={[{ required: true, message: t(`Select property`) }]}
          placeholder={t('Select property')}
          valuePropName="defaultSelectedIds"
          onInput={() => {
            props.form?.setFormStore('unitId', undefined);
            props.form?.setFormStore('leaseId', undefined);
          }}
        />
      </div>
      <Show when={props.form?.formStore.propertyId}>
        <div class="col-span-full lg:col-span-2">
          <FormItem
            label={`${t(`Unit`)} (${t('Optional')})`}
            formFieldName="unitId"
            component={LabeledUnitSearch}
            placeholder={t('Select unit')}
            filter={{
              propertyId: props.form?.formStore.propertyId,
            }}
            valuePropName="defaultSelectedIds"
            onInput={() => {
              props.form?.setFormStore('leaseId', undefined);
            }}
          />
        </div>
      </Show>

      <Show when={props.form?.formStore.unitId}>
        <div class="col-span-full lg:col-span-2">
          <FormItem
            label={`${t(`Lease`)} (${t('Optional')})`}
            formFieldName="leaseId"
            component={LabeledLeaseSearch}
            placeholder={t('Select lease')}
            filter={{
              unitId: props.form?.formStore.unitId,
            }}
            valuePropName="defaultSelectedIds"
          />
        </div>
      </Show>
    </>
  );
};
