import { For, Show, createMemo, createSignal, Switch, Match } from 'solid-js';
import { leasePeriodString } from '~/components/leases/utils';
import { PortfolioIcon } from '~/components/portfolios/PortfolioIcon';
import { TenantTitle } from '~/components/tenants';
import { IconChevronDown } from '~/components/ui';
import { UnitImage } from '~/components/units';
import { useLocalization } from '~/contexts/global';

export const PortfolioTargetList = (props: {
  portfolioTargets: MagicDoor.Api.HydratedAnnouncementPortfolioTargetsDto[];
  targetType?: string;
}) => {
  const { t } = useLocalization();
  const [expandedPortfolios, setExpandedPortfolios] = createSignal<string[]>([]);
  const [expandedProperties, setExpandedProperties] = createSignal<string[]>([]);

  const toggleExpand = (id: string, expandedItems: string[], setExpandedItems: (items: string[]) => void) => {
    setExpandedItems(expandedItems.includes(id) ? expandedItems.filter((item) => item !== id) : [...expandedItems, id]);
  };

  const renderPortfolio = (portfolio: MagicDoor.Api.HydratedAnnouncementPortfolioTargetsDto) => {
    const isExpanded = () => expandedPortfolios().includes(portfolio.portfolio!.id);
    const unitNumber = createMemo(() =>
      portfolio.properties!.reduce((accumulator, currentValue) => accumulator + currentValue.units!.length, 0)
    );

    const leaseNumber = createMemo(() => {
      let num = 0;

      portfolio.properties!.forEach((element) => {
        num += element.units!.reduce((accumulator, currentValue) => accumulator + (currentValue?.leases?.length || 0), 0);
      });

      return num;
    });

    const tenantNumber = createMemo(() => {
      let num = 0;

      portfolio.properties!.forEach((element) => {
        num += element.units!.reduce((accumulator, currentValue) => accumulator + (currentValue?.tenants?.length || 0), 0);
      });

      return num;
    });
    return (
      <div class="mb-4">
        <div
          classList={{ 'bg-input': isExpanded() }}
          class="rounded-md border border-input-border  p-4 hover:cursor-pointer"
          onClick={() => toggleExpand(portfolio.portfolio!.id, expandedPortfolios(), setExpandedPortfolios)}>
          <div class="flex items-center justify-between">
            <div class="flex items-center gap-2">
              <PortfolioIcon class="size-11 shrink-0 rounded-lg bg-[#F4DCFF] p-2 md:block" data-slot="icon" />
              <div>
                <h6 class="text-sm font-medium text-text-level01">
                  <a href={`/portfolios/${portfolio.portfolio!.id}`} target="_blank" rel="noopener noreferrer" class="hover:underline">
                    {portfolio.portfolio!.name}
                  </a>
                </h6>
                <div class="mt-0.5 flex items-center gap-1 text-xs text-text-level03">
                  <span>
                    {portfolio.properties!.length} {t('Properties')}
                  </span>
                  <span>|</span>
                  <span>
                    <span>{unitNumber()} </span>
                    {t('Units')}
                  </span>
                </div>
              </div>
            </div>

            <div class="flex items-center gap-4">
              <div class="text-xs text-essential-colour">
                {props.targetType === 'lease' ? leaseNumber() : tenantNumber()}
                <span class="ml-1">{props.targetType === 'lease' ? t('Leases') : t('Tenants')}</span>
              </div>
              <IconChevronDown
                classList={{ 'rotate-180': isExpanded() }}
                class="size-5 flex-none text-text-level02 transition-all duration-300 ease-in-out"
              />
            </div>
          </div>
        </div>
        <Show when={isExpanded()}>
          <For each={portfolio.properties}>{(property) => renderProperty(property, portfolio.portfolio!.id)}</For>
        </Show>
      </div>
    );
  };

  const renderProperty = (property: MagicDoor.Api.HydratedAnnouncementPropertyTargetsDto, portfolioId: string) => {
    const isExpanded = () => expandedProperties().includes(property.property!.id);
    const leaseNumber = createMemo(() =>
      property.units!.reduce((accumulator, currentValue) => accumulator + (currentValue?.leases?.length || 0), 0)
    );

    const tenantNumber = createMemo(() =>
      property.units!.reduce((accumulator, currentValue) => accumulator + (currentValue?.tenants?.length || 0), 0)
    );

    return (
      <div class="mt-2 border-l pb-2">
        <div
          classList={{ 'border-[#A126EC]': isExpanded() }}
          class="ml-4 flex rounded-md border p-4 hover:cursor-pointer"
          onClick={() => toggleExpand(property.property!.id, expandedProperties(), setExpandedProperties)}>
          <div class="flex w-full items-center justify-between bg-[#FAFCFD]">
            <div class="flex w-full items-center gap-2">
              <PortfolioIcon class="size-11 shrink-0 rounded-lg bg-[#F4DCFF] p-2 md:block" data-slot="icon" />
              <div class="flex w-full flex-col gap-0.5">
                <h6 class="text-sm font-medium text-text-level01">
                  <a
                    href={`/portfolios/${portfolioId}/properties/${property.property!.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    class="hover:underline">
                    {property.property!.name}
                  </a>
                </h6>
                <div class="text-xs text-text-level03">
                  <span>{property.property!.unitCount} </span>
                  {t('Units')}
                </div>
              </div>
            </div>
            <div class="flex items-center gap-4">
              <div class="text-xs text-essential-colour">
                {props.targetType === 'lease' ? leaseNumber() : tenantNumber()}
                <span class="ml-1">{props.targetType === 'lease' ? t('Leases') : t('Tenants')}</span>
              </div>
              <IconChevronDown
                classList={{ 'rotate-180': isExpanded() }}
                class="size-5 flex-none text-text-level02 transition-all duration-300 ease-in-out"
              />
            </div>
          </div>
        </div>
        <Show when={isExpanded()}>
          <For each={property.units}>{(unit) => renderUnitAndLease(unit, portfolioId, property.property)}</For>
        </Show>
      </div>
    );
  };

  const renderUnitAndLease = (
    unit: MagicDoor.Api.HydratedAnnouncementUnitTargetsDto,
    portfolioId: string,
    property?: MagicDoor.Api.PropertyDto
  ) => {
    // const isExpanded = () => expandedUnits().includes(unit.unit!.id);
    // onClick={() => toggleExpand(unit.unit!.id, expandedUnits(), setExpandedUnits)}
    return (
      <Show when={unit}>
        <div class="ml-4 mt-2 pl-4">
          <Switch>
            <Match when={props.targetType === 'lease'}>
              <For each={unit.leases}>
                {(lease) => (
                  <div class="flex w-full gap-2 rounded-md border p-4 hover:cursor-pointer">
                    <UnitImage class="mr-2 size-11 rounded-lg" unit={unit.unit} />
                    <div class="flex w-full items-center justify-between">
                      <div class="flex w-full flex-col gap-0.5">
                        <div class="text-sm font-medium text-text-level01">
                          <a
                            href={`/portfolios/${portfolioId}/properties/${property!.id}/units/${unit.unit!.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            class="hover:underline">
                            {property?.name},{unit.unit!.name}
                          </a>
                        </div>
                        <div class="text-xs text-text-level03">{leasePeriodString(lease)}</div>
                      </div>
                    </div>
                  </div>
                )}
              </For>
            </Match>
            <Match when={props.targetType === 'tenant'}>
              <For each={unit.tenants}>
                {(tenant) => (
                  <div class="mb-2 rounded-md border py-3 pl-4">
                    <TenantTitle tenant={tenant} feedbackIconReadonly />
                  </div>
                )}
              </For>
            </Match>
          </Switch>
        </div>
      </Show>
    );
  };

  return (
    <Show when={props.portfolioTargets && props.portfolioTargets.length > 0}>
      <div class="mt-4">
        <h4 class="mb-5 border-b py-3 text-base font-semibold uppercase text-text-level02">{t('Send to')}</h4>
        <For each={props.portfolioTargets}>{renderPortfolio}</For>
      </div>
    </Show>
  );
};
