import { createMemo, Show } from 'solid-js';
import { cn } from '~/utils/classnames';
import type { JSX } from 'solid-js';

export interface HandlerWrapperProps {
  isVisible: boolean;
  placement: 'tl' | 'tr' | 'br';
  children: JSX.Element;
  class?: string;
}

export const HandlerWrapper = (props: HandlerWrapperProps) => {
  const placementClass = createMemo(() => {
    switch (props.placement) {
      case 'tl': {
        return 'top-0 left-0 -translate-y-1/2 -translate-x-1/2';
      }

      case 'tr': {
        return 'top-0 left-full -translate-y-1/2 -translate-x-1/2';
      }

      case 'br': {
        return 'top-full left-full -translate-y-1/2 -translate-x-1/2';
      }
    }
  });

  return (
    <Show when={props.isVisible}>
      <div class={cn('absolute flex size-5 items-center justify-center rounded-full', placementClass(), props.class)}>{props.children}</div>
    </Show>
  );
};
