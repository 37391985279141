import { createEffect, createSignal, For, Show } from 'solid-js';
import IconCheck from '~/assets/images/settings/portal/chek.svg?component-solid';
import IconImgUpload from '~/assets/images/settings/portal/imgUpload.svg?component-solid';
import magicDoorThemeBg from '~/assets/images/settings/portal/magicDoorTheme.png';
import IconColorTheme from '~/assets/images/settings/portal/portalColorTheme.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { DragAndDrop } from '~/components/ui';
import { useLocalization, useCompanies } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { cloneDeep } from '~/utils/tool';

export const PortalColorTheme = () => {
  const { t } = useLocalization();
  const themeColor = ['magicDoorTheme', '#2F49D0', '#06603A', '#763521', '#4D0061', '#2D2D2D'];
  const { portalSettings, uploadFiles, updatePortalSettings } = useCompanies();
  const [activeColorTheme, setActiveColorTheme] = createSignal<string>(portalSettings()?.metadata.themeColor || 'magicDoorTheme');

  const switchColorTheme = async (color: string) => {
    setActiveColorTheme(color);
    const params = cloneDeep(portalSettings());
    params.metadata.themeColor = color;
    try {
      await updatePortalSettings(params);
    } catch (err) {
      console.log(err);
    }
  };
  const SwitchColorTheme = () => {
    return (
      <ul class="flex gap-5">
        <For each={themeColor}>
          {(theme) => (
            <>
              <Show when={theme === 'magicDoorTheme'}>
                <li
                  class="relative size-16 cursor-pointer rounded-full"
                  style={{ 'background-image': `url(${magicDoorThemeBg})` }}
                  onClick={() => switchColorTheme(theme)}>
                  <Show when={activeColorTheme() === theme}>
                    <IconCheck class="absolute right-0" />
                  </Show>
                </li>
              </Show>
              <Show when={theme !== 'magicDoorTheme'}>
                <li
                  class="relative size-16 cursor-pointer rounded-full border border-[#E5E3F2]"
                  style={{ background: theme }}
                  onClick={() => switchColorTheme(theme)}>
                  <Show when={activeColorTheme() === theme}>
                    <IconCheck class="absolute right-0" />
                  </Show>
                </li>
              </Show>
            </>
          )}
        </For>
      </ul>
    );
  };
  const LogoDisplay = () => {
    const [logoSrc, setLogoSrc] = createSignal<string>('');
    const handleUploadLogo = async (e: Event) => {
      const input = e.target as HTMLInputElement;
      if (input.files?.length !== 1) return;
      const file = input.files[0];
      try {
        const result = await uploadFiles(file, 'logo');
        if (result) {
          setLogoSrc(result.file?.signedUrl);
          await updatePortalSettings({ logoFileId: result.file.fileId });
        }
      } catch (err) {
        console.log(err);
      }
    };
    createEffect(() => {
      if (portalSettings()?.logoUrl) {
        setLogoSrc('/api' + portalSettings()?.logoUrl);
      }
    });
    return (
      <div class="my-8 border-y border-input-border py-6">
        <h2 class="text-base font-semibold capitalize text-title-gray">{t('Logo display')}</h2>
        <p class="mt-1 text-xs text-text-level03">
          {t('Upload your logo according to the theme. For dark theme, a lighter or white version of your logo is recommended.')}
        </p>
        <div class="mt-4 flex items-center gap-3">
          <img src={logoSrc()} />
          <label class="group relative cursor-pointer">
            <span class="flex cursor-pointer items-center gap-1 rounded-lg bg-primary px-5 py-2.5 text-base text-white">
              <IconImgUpload />
              {t('Upload logo')}
              <input hidden type="file" accept="image/*" onChange={handleUploadLogo} />
            </span>
          </label>
        </div>
      </div>
    );
  };
  const PhotoDisplay = () => {
    const handleDropFiles = async (fileList: FileList) => {
      try {
        const result = await uploadFiles(fileList[0], 'cover');
        if (result) {
          await updatePortalSettings({ coverFileId: result.file.fileId });
        }
      } catch (err) {
        console.log(err);
      }
    };
    return (
      <>
        <h2 class="text-base font-semibold capitalize text-title-gray">{t('Photo display')}</h2>
        <p class="mt-1 text-xs text-text-level03">{t('Select and upload a background photo.')}</p>
        <div
          class="rounded-lg bg-light-gray"
          style={{
            'background-image': `url(/api${portalSettings()?.coverUrl})`,
            'background-repeat': 'no-repeat',
            'background-position': 'center',
            'background-size': 'cover',
          }}>
          <DragAndDrop
            accept="image/*"
            class="mt-6 flex h-52 cursor-pointer flex-col items-center justify-center"
            onDropFiles={handleDropFiles}>
            <span class="flex items-center gap-1 rounded-lg bg-primary px-5 py-2.5 text-base text-white">
              <IconImgUpload />
              {t('Upload background photo')}
            </span>
          </DragAndDrop>
        </div>
      </>
    );
  };
  return (
    <ThreeSectionBox
      class="border-0"
      topTitle={<SettingTitle title={t('Color theme')} icon={IconColorTheme} />}
      middle={
        <div class="p-6">
          <SwitchColorTheme />
          <LogoDisplay />
          <PhotoDisplay />
        </div>
      }
    />
  );
};
