import { useNavigate, useParams } from '@solidjs/router';
import { Show, createMemo, onMount } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { RingLoader } from '~/components/common/Loaders';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { LeaseDraftPresenter } from '~/pdfsigner/presenters/PdfPresenter';
import { LeaseDraftPreviewContainer } from '~/pdfsigner/ui/components/LeaseDeaftPreviewContainer';
import { LeaseSigningContainer } from '~/pdfsigner/ui/components/LeaseSigningContainer';
import { PdfDocument } from '~/pdfsigner/ui/components/PdfDocument';
import { LoadLeaseDraftUseCase } from '~/pdfsigner/usecases/loadLeaseDraftUseCase';
import { SaveLeaseDraftUseCase } from '~/pdfsigner/usecases/saveLeaseDraftUseCase';
import { SendDraftForSigningUseCase } from '~/pdfsigner/usecases/sendDraftForSigningUseCase';
import { DocumentStatus } from '~/pdfsigner/usecases/types/paginatedDocumentDescriptor';
import type { JSX } from 'solid-js';

const LeasePreview = (): JSX.Element => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { draftId, leaseId } = useParams();
  const { execute: save, isLoading: isSaving } = useUseCase(SaveLeaseDraftUseCase);
  const { execute: sendForSigning, isLoading: isSendingForSigning } = useUseCase(SendDraftForSigningUseCase);
  const { execute: onSendReminders, isLoading: isSendingReminders } = useUseCase(LoadLeaseDraftUseCase);
  const { execute: loadDraft, isLoading: isLoadingDraft } = useUseCase(LoadLeaseDraftUseCase);
  const { model: presentablePdf } = usePresenter(LeaseDraftPresenter);
  const breadcrumbItems = createMemo(() => [{ label: t('Files'), link: `/leasing/leases/${leaseId}/files` }, { label: draftId }]);

  onMount(() => {
    loadDraft(draftId);
  });

  const isDraft = (): boolean => {
    return presentablePdf()?.status === DocumentStatus.Draft;
  };

  return (
    <div>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <Show
        when={!isLoadingDraft() && !isSaving() && !isSendingForSigning() && !isSendingReminders()}
        fallback={
          <div class="flex h-[calc(100vh_-_125px)] w-full items-center justify-center">
            <RingLoader color="#a126ec" size={125} borderWidth={5} showIcon />
          </div>
        }>
        <Show
          when={isDraft()}
          fallback={
            <LeaseSigningContainer onSendReminders={onSendReminders}>
              <PdfDocument pages={presentablePdf()?.pdf || []} />
            </LeaseSigningContainer>
          }>
          <LeaseDraftPreviewContainer onSave={save} onStartSigning={sendForSigning}>
            <PdfDocument pages={presentablePdf()?.pdf || []} />
          </LeaseDraftPreviewContainer>
        </Show>
      </Show>
    </div>
  );
};

export default LeasePreview;
