import { Show } from 'solid-js';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { PdfPresenter } from '~/pdfsigner/presenters/PdfPresenter';
import { AddPdfUseCase } from '~/pdfsigner/usecases/addPdfUseCase';
import { PdfDocument } from './PdfDocument';
import type { JSX } from 'solid-js';

const DocumentUpload = (): JSX.Element => {
  const { execute: addPdf } = useUseCase(AddPdfUseCase);
  const { model: presentablePdf } = usePresenter(PdfPresenter);

  const handleDrop = async (event: DragEvent) => {
    event.preventDefault();
    await addPdf(event.dataTransfer?.files);
  };

  const preventDefaultBehavior = (event: DragEvent) => {
    event.preventDefault();
  };

  const isPdfLoaded = () => {
    const pdf = presentablePdf();
    return pdf !== undefined && pdf?.length > 0;
  };

  return (
    <div
      id="pdf-viewer"
      class="flex h-full min-w-0 grow"
      onDrop={handleDrop}
      onDragOver={preventDefaultBehavior}
      onDragLeave={preventDefaultBehavior}>
      <Show
        when={isPdfLoaded()}
        fallback={<div class="flex h-full items-center justify-center bg-gray-100">Drag and drop a PDF file here</div>}>
        <div class="thinscroll max-h-full grow overflow-hidden overflow-y-auto rounded-lg">
          <PdfDocument pages={presentablePdf() || []} />
        </div>
      </Show>
    </div>
  );
};

export default DocumentUpload;
