import { useParams, useNavigate } from '@solidjs/router';
import { createMemo, onMount, Show } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { RingLoader } from '~/components/common/Loaders';
import { Panel } from '~/components/common/Panels';
import { useLocalization } from '~/contexts/global';
import { useAnnouncements } from '~/contexts/local';
import { OtherTargetsList } from '~/pages/announcements/components/OtherTargetsList';
import { PortfolioTargetList } from '~/pages/announcements/components/PortfolioTargetList';
import { renderAttachmentLists, renderField } from '~/pages/announcements/components/Utils';
import { dateFormat } from '~/utils/date';
import type { OtherTarget } from '~/pages/announcements/components/OtherTargetsList';

export const AnnouncementDetails = () => {
  const { t } = useLocalization();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { announcement, getAnnouncement, isLoading } = useAnnouncements();

  onMount(() => {
    getAnnouncement(id);
  });

  const breadcrumbItems = createMemo(() => [
    { label: t('Announcements'), link: '/communications/announcements' },
    { label: t('Announcement detail') },
  ]);

  return (
    <Show when={!isLoading()} fallback={<RingLoader />}>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />

      <div class="p-8 text-left">
        <Panel title={t('Announcement detail')} class="thinscroll h-section1 overflow-auto">
          <div class="px-4 py-10 lg:px-[137px]">
            <div class="flex flex-col gap-4">
              <h4 class="border-b text-xl font-semibold">{t('Details')}</h4>

              <div class="w-1/2 rounded-md border p-3">
                <h5 class="font-semibold">{t('Subject')}</h5>
                <p>{announcement()?.subject}</p>
              </div>

              {renderField('Sent at', dateFormat('', announcement()?.sentAt), t)}
              {renderField('Chat message', announcement()?.chatMessage, t)}
              {renderAttachmentLists(announcement()?.chatAttachments, 'Chat attachments', t)}
              {renderField('Email message', announcement()?.emailMessage, t)}
              {renderAttachmentLists(announcement()?.emailAttachments, 'Email attachments', t)}

              <Show when={announcement()?.targets}>
                <PortfolioTargetList
                  portfolioTargets={announcement()?.targets.portfolioTargets as MagicDoor.Api.HydratedAnnouncementPortfolioTargetsDto[]}
                />
                <OtherTargetsList targets={announcement()?.targets.owners as OtherTarget[]} title="Owners" />
                <OtherTargetsList targets={announcement()?.targets.vendors as OtherTarget[]} title="Vendors" />
                <OtherTargetsList targets={announcement()?.targets.tenants as OtherTarget[]} title="Tenants" />
              </Show>
            </div>
          </div>
        </Panel>
      </div>
    </Show>
  );
};
