import { createEffect, createSignal, Show } from 'solid-js';
import AccountSettingIcon from '~/assets/images/leases/accountSetting.svg?component-solid';
import ModalBgIcon from '~/assets/images/leases/transactions/modalBgIcon.svg';
import { LabeledChartOfAccountSelect } from '~/components/chart-of-accounts/ChartOfAccountSelect';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Radio } from '~/components/common/Inputs/Radio';
import { LeaseOverviewBanner } from '~/components/leases';
import { Modal } from '~/components/modals/Modal';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useUpdates } from '~/pages/leases/lease-details//UpdatePagesContext';
import { formConfirmationContent } from '~/utils/constant';
import { currency, isNumber } from '~/utils/number';
import { isEmptyData } from '~/utils/tool';
import type { Component } from 'solid-js';
import type { ModalProps } from '~/components/modals/Modal';

export type AddDepositTransactionType = {
  dueDate?: string;
  chartOfAccountId?: string;
  amount?: string;
  billDate?: string;
  description?: string;
  companyCharge?: boolean;
};

type LeaseAddDepositTransactionModalProps = Omit<ModalProps, 'title'> & {
  currentLease: MagicDoor.Api.HydratedLeaseDto;
  title: string;
  submitText: string;
  onSubmit: (data: AddDepositTransactionType) => Promise<void>;
  showBillDate?: boolean;
  chartOfAccountType: `${MagicDoor.Api.ChartOfAccountType}`;
  showAllChartOfAccountsSelector?: boolean;
  formDefaultValues?: AddDepositTransactionType;
};

export const LeaseAddDepositTransactionModal: Component<LeaseAddDepositTransactionModalProps> = (props) => {
  const { t } = useLocalization();
  const { triggerUpdate } = useUpdates();

  const formId = 'transaction_form';
  const today = new Date().toISOString().split('T')[0];

  const [loading, setLoading] = createSignal<boolean>(false);
  const [formData, setFormData] = createSignal<AddDepositTransactionType>({
    dueDate: today,
    amount: undefined,
    chartOfAccountId: undefined,
    description: '',
    billDate: undefined,
    companyCharge: false,
    ...props.formDefaultValues,
  });
  const [amount, setAmount] = createSignal<string>();

  const [formErrors, setFormErrors] = createSignal<AddDepositTransactionType>({});
  const [showCloseConfirmation, setShowCloseConfirmation] = createSignal<boolean>(false);

  const [allChartOfAccounts, setAllChartOfAccounts] = createSignal<boolean>(false);

  const setFormDataCommon = (value: any, key: keyof AddDepositTransactionType) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));

    if (key === 'amount' && typeof value === 'string') {
      setAmount(value);
    }
  };

  const validator = (): boolean => {
    let result = true;
    const errors: Partial<Record<keyof AddDepositTransactionType, string | undefined>> = {};
    (Object.keys(formData()) as Array<keyof AddDepositTransactionType>).forEach((key) => {
      if (key !== 'description' && key !== 'billDate' && key !== 'companyCharge') {
        const nameObj: Record<string, string> = {
          dueDate: 'Due date',
          chartOfAccountId: 'Chart of account',
          amount: 'Amount',
        };
        errors[key] = (() => {
          const name = nameObj[key];
          const value = formData()[key];
          const translatedName = t(name);

          if (!value) {
            return t('{name} is required', { name: translatedName });
          }
          if (typeof value === 'string' && value.length > 255) {
            return t('{name} must be {length} characters or less', { name: translatedName, length: '255' });
          }
          if (key === 'amount' && !isNumber(amount())) {
            return t('{name} must be a number', { name: translatedName });
          }
          return undefined;
        })();
      }
    });
    for (const key in errors) {
      if (errors[key as keyof AddDepositTransactionType]) {
        result = false;
        break;
      }
    }
    setFormErrors(errors as AddDepositTransactionType);
    return result;
  };

  const handleSubmit = async (ev: Event) => {
    ev.preventDefault();
    const validateResult = validator();
    if (!validateResult) return;

    setLoading(true);
    try {
      await props.onSubmit({
        dueDate: formData().dueDate,
        chartOfAccountId: formData().chartOfAccountId,
        amount: amount(),
        description: formData().description,
        billDate: formData().billDate,
        companyCharge: formData().companyCharge,
      });

      props.onCancel && props.onCancel();
      triggerUpdate();

      toast.success(t('{name} has been added successfully', { name: props.title }));
    } finally {
      setLoading(false);
    }
  };

  createEffect(() => {
    setShowCloseConfirmation(!isEmptyData(formData()));
  });

  return (
    <Modal
      {...props}
      title={props.title}
      doneText={props.submitText}
      submitId={formId}
      loading={loading()}
      confirmation={showCloseConfirmation() ? formConfirmationContent(t) : false}>
      <form class="thinscroll flex flex-col gap-5 overflow-auto px-8 pb-16 pt-6" id={formId} onSubmit={handleSubmit}>
        <LeaseOverviewBanner lease={props.currentLease} />
        <div class="grid grid-cols-2 gap-x-7 gap-y-6">
          <DueDateInputField
            class="col-span-full lg:col-span-1"
            required
            label={t('Due date')}
            value={formData().dueDate}
            onInput={(value) => setFormDataCommon(value, 'dueDate')}
            error={formErrors().dueDate}
          />
          <Show when={props.showBillDate}>
            <DueDateInputField
              class="col-span-full lg:col-span-1"
              label={t('Bill date')}
              value={formData().billDate}
              onInput={(value) => setFormDataCommon(value, 'billDate')}
            />
          </Show>
        </div>
        <div class="w-full pb-5 pt-4">
          <Radio
            class="flex w-full flex-col gap-4 sm:flex-row sm:justify-between [&>*]:w-full [&>*]:sm:w-auto"
            items={[
              { label: t('This is a property bill'), value: false },
              { label: t('This is a company bill'), value: true },
            ]}
            value={formData().companyCharge}
            onInput={(value) => setFormDataCommon(value as boolean, 'companyCharge')}
          />
        </div>
        <div>
          <div class="flex flex-wrap items-center justify-between border-b border-partingline py-3 text-text-level01">
            <div class="flex items-center gap-2">
              <AccountSettingIcon />
              <span class="font-semibold">{t('Chart of account settings')}</span>
            </div>
            <Show when={props.showAllChartOfAccountsSelector}>
              <div class="flex items-center gap-2">
                <Checkbox
                  checked={allChartOfAccounts()}
                  onInput={(val) => {
                    setAllChartOfAccounts(val);
                    !val && setFormDataCommon('', 'chartOfAccountId');
                  }}
                />
                {t('Allow all chart of accounts')}
              </div>
            </Show>
          </div>
          <div class="grid grid-cols-2 gap-x-7 gap-y-6 pb-5 pt-4">
            <LabeledChartOfAccountSelect
              class="col-span-full lg:col-span-1"
              required
              label={t('Chart of account')}
              value={formData().chartOfAccountId}
              error={formErrors().chartOfAccountId}
              onInput={(value) => setFormDataCommon(value, 'chartOfAccountId')}
              placeholder={t('Please select')}
              types={allChartOfAccounts() ? ['revenue', 'expense', 'liability'] : props.chartOfAccountType}
            />
            <LabeledNumberInput
              class="col-span-full lg:col-span-1"
              required
              label={t('Amount')}
              value={amount() ? Number(amount()) : ''}
              step={0.01}
              onInput={(value) => setFormDataCommon(`${value}`, 'amount')}
              error={formErrors().amount}
              placeholder={t('Please enter')}
            />
            <LabeledTextInput
              class="col-span-full lg:col-span-1"
              label={t('Memo')}
              value={formData().description}
              onInput={(value) => setFormDataCommon(value, 'description')}
              placeholder={t('Please enter')}
            />
          </div>
        </div>
      </form>
      <div class="relative flex w-full items-center justify-end bg-pink-100 py-5">
        <img src={ModalBgIcon} class="absolute bottom-0 left-4 h-20 w-52" />
        <div class="mr-4 flex items-center">
          <span class="mr-2 text-xs uppercase lg:text-sm">{t('Total amount')}:</span>
          <span class="text-sm font-bold text-essential-colour lg:text-2xl">
            {currency(isNumber(formData().amount) ? formData().amount : 0)}
          </span>
        </div>
      </div>
    </Modal>
  );
};
