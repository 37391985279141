import { Show } from 'solid-js';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import { ToolbarPresenter } from '~/pdfsigner/presenters/ToolbarPresenter';
import type { JSX } from 'solid-js';

export interface LeaseSigningHeaderProps {
  shouldShowReminderButton: boolean;
  onSendRemindersClicked?: () => void;
}

export const LeaseSigningHeader = (props: LeaseSigningHeaderProps): JSX.Element => {
  const { model: presentableToolbar } = usePresenter(ToolbarPresenter);
  const { t } = useLocalization();

  return (
    <Show when={presentableToolbar()}>
      <div class="left-0 top-0 z-20 w-full">
        <div class="flex w-full flex-row items-center justify-center border-r border-gray-300 bg-dark-essential py-2">
          <div class="justify-center text-lg text-white">{presentableToolbar()?.title}</div>
        </div>
        <div class="flex w-full flex-row items-center justify-between border-r border-gray-300 bg-dark-essential pb-3">
          <div class={'ml-3 text-white'}>{`${t('Received signatures')}: ${presentableToolbar()?.receivedSignaturesText}`}</div>
          <Show when={props.shouldShowReminderButton}>
            <button
              class={`mr-3 rounded-lg border border-white bg-dark-essential px-4 py-1 text-white hover:bg-light-essential`}
              onClick={() => props.onSendRemindersClicked?.()}>
              {t('Send reminders')}
            </button>
          </Show>
        </div>
      </div>
    </Show>
  );
};
