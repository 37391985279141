import { Route, Routes } from '@solidjs/router';
import { ListListingPage } from './list-listing';
import { AddUnitListingModal } from './listing-add';
import ListingDetailsPage from './listing-detail';
import { EditUnit } from './listing-detail/edit-unit';
import { ListingMagicAiPage } from './listing-detail/listing-magic-ai';

const ListingRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/details/:listingId/*" component={ListingDetailsPage} matchFilters={{ listingId: /^\d+$/ }} />
        <Route path="/:portfolioId?/*" component={ListListingPage} matchFilters={{ portfolioId: /^\d+$/ }} />
        <Route path="/details/:listingId/edit-unit" component={EditUnit} />
        <Route path="/details/:listingId/magic-ai" component={ListingMagicAiPage} />
      </Routes>
      <Routes>
        <Route path="/:portfolioId/add" component={AddUnitListingModal} />
      </Routes>
    </>
  );
};
export default ListingRoutes;
