import { DocumentStatus } from '~/pdfsigner/usecases/types/paginatedDocumentDescriptor';
import { Presenter } from './Presenter';
import type { PresentablePaginatedLeaseDrafts } from '~/pdfsigner/ui/types/presentablePaginatedLeaseDrafts';
import type { PdfState } from '~/pdfsigner/usecases/state/pdfAppState';

export class LeaseDraftsPresenter extends Presenter<PresentablePaginatedLeaseDrafts> {
  createModel(state: PdfState): PresentablePaginatedLeaseDrafts {
    return {
      page: state.paginatedLeaseDrafts?.page || 0,
      totalPages: state.paginatedLeaseDrafts?.totalPages || 0,
      pageSize: state.paginatedLeaseDrafts?.pageSize || 0,
      items:
        state.paginatedLeaseDrafts?.items.map((item) => {
          return {
            id: item.id,
            name: item.name,
            signedUrl: item.signedUrl,
            status: this.getStatusText(item.status),
          };
        }) || [],
    };
  }

  private getStatusText(status: DocumentStatus): string {
    switch (status) {
      case DocumentStatus.Draft:
        return 'Draft';
      case DocumentStatus.PendingSignatures:
        return 'Pending Signatures';
      case DocumentStatus.Completed:
        return 'Completed';
      default:
        return 'Unknown';
    }
  }
}
