import { useParams } from '@solidjs/router';
import { createMemo, createEffect } from 'solid-js';
import { Panel } from '~/components/common/Panels';
import { PhotosEditor } from '~/components/photos';
import { useLocalization, useUnitListings } from '~/contexts/global';
import { getFileAllUrl } from '~/utils/file';
import type { Component } from 'solid-js';

export const UnitListingPhotosTab: Component = () => {
  const { t } = useLocalization();
  const params = useParams<{ listingId: `${number}` }>();
  const { unitListingDetail, addUnitListingImage, updateUnitLising, refetchUnitListingDetail } = useUnitListings();
  const images = createMemo(() => {
    return unitListingDetail()?.images?.map((i) => ({ id: i.fileId || '', url: getFileAllUrl(i.signedUrl as string) })) || [];
  });
  const handleUpload = async (files: File[]) => {
    if (params.listingId == null) return;
    for (const file of files) {
      const res = await addUnitListingImage(params.listingId, { file });
      const param = { images: [...(unitListingDetail()?.images as []), (res[0] as any)?.imageFile] };
      await updateUnitLising(param, params.listingId);
    }
  };
  const handleDelete = async (fileId: string) => {
    if (params.listingId == null) return;
    const images = unitListingDetail()?.images?.filter((item) => item.fileId !== fileId);
    await updateUnitLising({ images }, params.listingId);
  };
  createEffect(() => {
    refetchUnitListingDetail();
  });
  return (
    <Panel title={t('Unit listing photos')}>
      <PhotosEditor photos={images()} onUpload={handleUpload} onDelete={handleDelete} />
    </Panel>
  );
};
