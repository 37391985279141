import { useNavigate, useSearchParams } from '@solidjs/router';
import { createMemo, createEffect, Show, createSignal, onMount, createResource } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { PrintButton } from '~/components/common/Buttons';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { PrintPageHeader } from '~/components/print';
import { IconLoader } from '~/components/ui';
import { useLocalization, useCompanies } from '~/contexts/global';
import { useReports } from '~/contexts/local';
import { dateFormat } from '~/utils/date';
import { PropertySelect } from './components/PropertySelect';
import { ReportTable } from './components/Report';
import type { LineItem } from './components/Report';
import type { Component } from 'solid-js';

const DelinquencyReport: Component<{ class?: string; report?: MagicDoor.Api.DelinquencyReportDto }> = (props) => {
  const { t } = useLocalization();

  const columns = () => [
    {
      label: t('Name'),
      type: 'string' as const,
      render: ({ values }: LineItem) => values?.name,
    },
    {
      label: t('Tenant status'),
      type: 'string' as const,
      render: ({ values }: LineItem) => {
        if (!Object.hasOwn(values ?? {}, 'tenantStatus')) {
          return '';
        }
        return values?.tenantStatus ? 'Current' : 'Notice';
      },
    },
    {
      label: t('Tags'),
      type: 'string' as const,
      render: ({ values }: LineItem) => values?.tags,
    },
    {
      label: t('Phone numbers'),
      type: 'string' as const,
      render: ({ values }: LineItem) => values?.phoneNumbers,
    },
    {
      label: t('Move in'),
      type: 'string' as const,
      render: ({ values }: LineItem) => values?.moveIn && dateFormat('MM/DD/YYYY', values.moveIn as string),
    },
    {
      label: t('Move out'),
      type: 'string' as const,
      render: ({ values }: LineItem) => {
        if (!Object.hasOwn(values ?? {}, 'moveOut')) {
          return '';
        }
        return values?.moveOut ? dateFormat('MM/DD/YYYY', values?.moveOut as string) : '-';
      },
    },
    {
      label: t('Deposit'),
      type: 'string' as const,
      render: ({ values }: LineItem) => (values?.deposit ? Number(values?.deposit).toFixed(2) : values?.deposit),
      style: {
        'font-weight': '700',
      },
    },
    {
      label: t('Amount receivable'),
      type: 'string' as const,
      render: ({ values }: LineItem) => (values?.amountReceivable ? Number(values?.amountReceivable).toFixed(2) : values?.amountReceivable),
      style: {
        'font-weight': '700',
      },
    },
    {
      label: t('Delinquent subsidy amount'),
      type: 'string' as const,
      render: ({ values }: LineItem) => {
        if (!Object.hasOwn(values ?? {}, 'delinquentSubsidyAmount')) {
          return '';
        }
        return Number(values?.delinquentSubsidyAmount).toFixed(2);
      },
      style: {
        'font-weight': '700',
      },
    },
    {
      label: t('Last payment'),
      type: 'string' as const,
      render: ({ values }: LineItem) => values?.lastPayment,
      style: {
        'font-weight': '700',
      },
    },
    {
      label: t('Payment amount'),
      type: 'string' as const,
      render: ({ values }: LineItem) => {
        if (!Object.hasOwn(values ?? {}, 'paymentAmount')) {
          return '';
        }
        return Number(values?.paymentAmount).toFixed(2);
      },
      style: {
        'font-weight': '700',
      },
    },
    {
      label: t('Late count'),
      type: 'string' as const,
      render: ({ values }: LineItem) => values?.lateCount,
      style: {
        'font-weight': '700',
      },
    },
  ];

  const lines = (): LineItem[] => {
    if (!props.report?.groups || props.report?.groups?.length == 0) {
      return [];
    }

    const totalInfo = {
      label: 'Total',
      level: 0,
      values: {
        deposit: 0,
        amountReceivable: 0,
        delinquentSubsidyAmount: 0,
        paymentAmount: 0,
        lateCount: 0,
      },
    };

    return [
      ...props.report.groups.flatMap((item) => {
        const address = item.property?.address;
        const fullAddress = `${address?.streetAddress1}, ${address?.city}, ${address?.state} ${address?.zipCode}, ${address?.country}`;
        const propertyDelinquencyReport: LineItem[] = [
          {
            label: fullAddress as string,
            level: 0,
            values: {},
          },
          ...(item.items
            ?.map(({ lease, tenants, unit }) => {
              const unitInfo =
                tenants?.map(({ tenant, lease: tenantLease }, index) => {
                  const tenantInfo: LineItem = {
                    label: index === 0 ? unit?.name : '',
                    level: 1,
                    values: {
                      name: `${tenant?.firstName} ${tenant?.lastName}`,
                      tenantStatus: tenant?.active,
                      tags: '',
                      phoneNumbers: tenant?.phone,
                      moveIn: tenantLease?.moveIn,
                      moveOut: tenantLease?.moveOut || '',
                    },
                  };
                  return tenantInfo;
                }) ?? [];

              totalInfo.values.deposit += lease?.securityDepositPaid ?? 0;
              totalInfo.values.amountReceivable += lease?.balance ?? 0;
              totalInfo.values.delinquentSubsidyAmount += lease?.securityDepositDue ?? 0;
              totalInfo.values.paymentAmount += lease?.currentRent ?? 0;
              totalInfo.values.lateCount += lease?.pastDueDays ?? 0;

              return [
                ...unitInfo,
                {
                  label: '',
                  level: 1,
                  values: {
                    deposit: lease?.securityDepositPaid,
                    amountReceivable: lease?.balance,
                    delinquentSubsidyAmount: lease?.securityDepositDue,
                    lastPayment: lease?.lastPaymentDate || '',
                    paymentAmount: lease?.currentRent,
                    lateCount: lease?.pastDueDays || 0,
                  },
                },
              ];
            })
            .flat() ?? []),
        ];

        return propertyDelinquencyReport;
      }),
      totalInfo,
    ];
  };

  return <ReportTable class="[&_[data-level='1']]:!font-normal" label={t('Unit')} lines={lines()} columns={columns()} />;
};

export const DelinquencyReportPage = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  // const { properties } = useProperties();
  // const { portfolios } = usePortfolios();
  const { companies } = useCompanies();

  const [searchParams, setSearchParams] = useSearchParams<{
    portfolioIds?: string;
    propertyIds?: string;
    minimumDue?: string;
    includeEmptyProperties: string;
  }>();

  const { getFilterDisplay, delinquencyReport, getDelinquencyReport } = useReports();
  const [routeLevel, setRouteLevel] = createSignal<number>(-1);

  const [printContainer, setPrintContainer] = createSignal<HTMLDivElement>();

  const breadcrumbItems = createMemo(() => [{ label: t('Reports'), link: '/reports' }, { label: t('Delinquency report') }]);

  onMount(() => {
    setSearchParams({
      // minimumDue: 0,
      includeEmptyProperties: 'false',
    });
  });

  createEffect(() => {
    // if (!searchParams.start || !searchParams.end) return;
    const params: any = {
      // dateRange: { start: searchParams.start, end: searchParams.end },
      // includeChartOfAccountIds: searchParams.chartOfAccountIds?.split(','),
      minimumDue: searchParams.minimumDue,
      includeEmptyProperties: searchParams.includeEmptyProperties === 'true',
    };
    if (searchParams.propertyIds) params.propertyIds = searchParams.propertyIds?.split(',').filter(Boolean);
    else if (searchParams.portfolioIds) params.portfolioIds = searchParams.portfolioIds?.split(',').filter(Boolean);

    getDelinquencyReport(params);
    setRouteLevel((prevLevel) => prevLevel - 1);
  });

  const [filterDisplay] = createResource(
    () => [searchParams.propertyIds, searchParams.portfolioIds],
    async ([propertyIds, portfolioIds]) => {
      return await getFilterDisplay(propertyIds, portfolioIds);
    }
  );

  // 打印
  const printHeader = createMemo(() => {
    // const portfolio = searchParams.portfolioIds;
    // const property = searchParams.propertyIds;
    // const filterDisplay = getFilterDisplay(property, portfolio);
    return (
      <div>
        <PrintPageHeader companies={companies()} />
        <div class="flex flex-col gap-1 pb-3 pt-6">
          <h1 class="text-center text-xl font-semibold text-[#3F4156]">{t('Delinquency report')}</h1>
        </div>
        <div class="text-center text-lg font-medium text-title-gray">
          <Show when={filterDisplay()?.portfoliosStr}>
            <div class="pb-3">
              {t('Portfolio')} : {filterDisplay()?.portfoliosStr}
            </div>
          </Show>
          <Show when={filterDisplay()?.propertiesStr}>
            <div class="pb-3">
              {t('Property')} : {filterDisplay()?.propertiesStr}
            </div>
          </Show>
          <Show when={Object.hasOwn(searchParams, 'minimumDue')}>
            <div class="pb-3">
              {t('Minimum due')} : {searchParams.minimumDue}
            </div>
          </Show>
          <div class="pb-3">
            {t('Include empty properties')} : {searchParams.includeEmptyProperties === 'true' ? t('Yes') : t('No')}
          </div>
        </div>
      </div>
    );
  });

  const [printContainerWidth, setPrintContainerWidth] = createSignal<string>('2000px');

  createEffect(() => {
    if (!delinquencyReport.loading) {
      const clientWidth = printContainer()?.children[0]?.children[1]?.clientWidth;
      clientWidth && setPrintContainerWidth(`${clientWidth + clientWidth * 0.2}px`);
    }
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(routeLevel())} items={breadcrumbItems()} />
      <div class="m-8 rounded-lg border border-partingline bg-white p-8">
        <div class="flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Delinquency report')}</h1>

          <PrintButton
            color="primary"
            variant="outlined"
            disabled={delinquencyReport.loading}
            printHeader={printHeader() as Element}
            printWrapClass="w-auto"
            isReport={true}
            printContainer={printContainer() as HTMLDivElement}
            extraStyle={`width:${printContainerWidth()};zoom:0.5`}
            orientation="landscape"
          />
        </div>
        <div class="my-6 flex flex-wrap items-center gap-3">
          <PropertySelect />
          <div class="flex items-center rounded-md border bg-inputbox-bg focus-within:ring-1 focus-within:ring-essential-colour">
            <span class="flex min-w-32 items-center border-r border-[#5551] px-3 text-sm text-text-level03">{t('Minimum due')}:</span>
            <input
              class="w-full rounded-md border border-transparent bg-inputbox-bg px-3 py-2 text-sm text-black outline-none placeholder:text-auxiliary-text"
              value={searchParams.minimumDue}
              onChange={(e) => setSearchParams({ ...searchParams, minimumDue: e.target.value })}
              type="number"
            />
          </div>
          <Checkbox
            showLabel
            label={<span class="text-sm text-title-gray">{t('Include empty properties')}</span>}
            checked={searchParams.includeEmptyProperties === 'true'}
            onChange={(e) => setSearchParams({ ...searchParams, includeEmptyProperties: e.target.checked })}
          />
        </div>
        <div ref={(e) => setPrintContainer(e)}>
          <Show when={delinquencyReport.loading} fallback={<DelinquencyReport report={delinquencyReport()} />}>
            <IconLoader class="mx-auto my-56 animate-spin" />
          </Show>
        </div>
      </div>
    </>
  );
};
