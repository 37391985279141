import { useNavigate, useParams } from '@solidjs/router';
import { For, Show, createMemo } from 'solid-js';
import { Dynamic } from 'solid-js/web';
import IconEdit from '~/assets/images/common/edit.svg?component-solid';
import IconPic from '~/assets/images/common/pic.svg?component-solid';
import IconBathroom from '~/assets/images/property/bathroom.svg';
import IconBedroom from '~/assets/images/property/bedroom.svg';
import IconDateAvaliable from '~/assets/images/property/date.svg';
import IconUnitSize from '~/assets/images/property/unitSize.svg';
import IconSelect from '~/assets/images/units/listing/select.svg?component-solid';
import { LinkButton } from '~/components/common/Buttons';
import { AiButton } from '~/components/common/Buttons/AiButton';
import DisplayAddress from '~/components/common/DisplayAddress';
import { Dropdown } from '~/components/common/Dropdown';
import { Checkbox } from '~/components/common/Inputs/Checkbox';
import { Panel } from '~/components/common/Panels';
import { propertyTypes } from '~/components/properties';
import { useLocalization, useUnitListings } from '~/contexts/global';
import { GoogleMap } from '~/pages/listing/components/GoogleMap';
// import { ListingStatus, statusMap } from '~/pages/listing/components/ListingStatus';
import { ListingIs } from '~/pages/listing/components/ListingIs';
import { UnitMetric } from '~/pages/units/components/UnitMetric';
import { emptyPlaceholder } from '~/utils/constant';
import { getImageUrl } from '~/utils/file';
import { currency } from '~/utils/number';
import { hydratedCheckbox, cloneDeep } from '~/utils/tool';

export const UnitInformationTab = () => {
  const { t } = useLocalization();
  const { unitListingDetail, updateUnitLising } = useUnitListings();
  const navigate = useNavigate();
  const params = useParams<{ listingId: `${number}` }>();
  const listingArr = ['Listing', 'Listed'];

  const amenityOverrides = createMemo(() => {
    return hydratedCheckbox(unitListingDetail()?.amenities as MagicDoor.Api.AmenityDto[]);
  });
  const petPolicy = createMemo(() => {
    const petPolicyArr = [];
    if (unitListingDetail()?.petPolicy?.catsAllowed) petPolicyArr.push({ label: t('Cats allowed'), value: true });
    if (unitListingDetail()?.petPolicy?.largeDogsAllowed) petPolicyArr.push({ label: t('Large dogs allowed'), value: true });
    if (unitListingDetail()?.petPolicy?.smallDogsAllowed) petPolicyArr.push({ label: t('Small dogs allowed'), value: true });
    if (unitListingDetail()?.petPolicy?.description) petPolicyArr.push({ label: unitListingDetail()?.petPolicy?.description, value: true });
    return petPolicyArr;
  });
  const ImageList = (props: { listing: MagicDoor.Api.HydratedUnitListingDto; images: MagicDoor.Api.EmbedFileDto[] }) => {
    const imgLists = cloneDeep(props.images);
    return (
      <>
        <For each={imgLists.splice(0, 4)}>
          {(item, index) => (
            <div class="relative overflow-hidden rounded-lg">
              <img src={getImageUrl('unit', props.listing?.id || '', item?.fileId || '')} class="h-52 w-full rounded-lg" />
              <Show when={index() === 3 && imgLists?.length > 0}>
                <div class="absolute inset-0 flex items-center justify-center bg-black/50">
                  <IconPic class="size-6" />
                  <span class="ml-1 text-2xl text-white">{imgLists?.length}</span>
                </div>
              </Show>
            </div>
          )}
        </For>
      </>
    );
  };

  const handleUpdateStatus = async (status: string) => {
    try {
      const listed = status === 'Listed';
      const model = { ...unitListingDetail(), listed };
      await updateUnitLising(model as MagicDoor.Api.UpdateUnitListingDto, params.listingId);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div class="grid grid-cols-12 gap-5">
      <Panel class="col-span-9 px-6 py-5">
        <div>
          <div class="flex justify-between">
            <div>
              <div class="flex items-center gap-2 text-2xl font-semibold text-text-level01">
                <span class="w-fit truncate">{unitListingDetail()?.title || unitListingDetail()?.unit?.name}</span>
                <div class="flex items-center gap-1 rounded-full bg-[#8712CF] px-3 py-1 text-xs text-white">
                  <Dynamic
                    component={propertyTypes[unitListingDetail()?.property?.type as MagicDoor.Api.PropertyType]?.icon}
                    class="size-4"
                  />
                  {propertyTypes[unitListingDetail()?.property?.type as MagicDoor.Api.PropertyType]?.name}
                </div>
                <div class="flex gap-2">
                  {/* <Show when={unitListingDetail()?.status}>
                    <ListingStatus
                      status={unitListingDetail()?.status as MagicDoor.Api.UnitListingStatus}
                      listed={unitListingDetail()?.listed}
                    />
                  </Show> */}
                  <ListingIs listed={unitListingDetail()?.listed} />
                  <Dropdown
                    class="cursor-pointer"
                    contentPosition={['bottom']}
                    renderLabel={(isOpen, open) => <IconSelect onClick={() => open(!isOpen)} />}>
                    {(isOpen, open) => (
                      <div class="flex flex-col gap-1">
                        {/* <For each={Object.keys(statusMap)}>
                          {(item) => (
                            <p
                              class="cursor-pointer hover:text-essential-colour"
                              onClick={() => {
                                open(!isOpen);
                                handleUpdateStatus(item);
                              }}>
                              {t(upperFirst(item))}
                            </p>
                          )}
                        </For> */}
                        <For each={listingArr}>
                          {(item) => (
                            <p
                              class="cursor-pointer hover:text-essential-colour"
                              onClick={() => {
                                open(!isOpen);
                                handleUpdateStatus(item);
                              }}>
                              {t(item)}
                            </p>
                          )}
                        </For>
                      </div>
                    )}
                  </Dropdown>
                </div>
              </div>
              <DisplayAddress class="truncate text-xs text-text-level03" address={unitListingDetail()?.property?.address} />
            </div>
            <LinkButton href="./edit-unit" variant="outlined" class="border-link py-2 font-medium text-link hover:bg-link/10">
              <IconEdit /> {t('Edit unit')}
            </LinkButton>
          </div>
          <div class="my-6 flex gap-20">
            <div>
              <div class="text-xs text-text-level03">{t('Rent')}</div>
              <div class="text-xl font-semibold text-essential-colour">
                {currency(unitListingDetail()?.rentalPrice || emptyPlaceholder)}
              </div>
            </div>
            <div>
              <div class="text-xs text-text-level03">{t('Security deposit')}</div>
              <div class="text-xl font-semibold text-text-level01">{unitListingDetail()?.securityDeposit || emptyPlaceholder}</div>
            </div>
          </div>
          <div class="flex flex-wrap gap-10 py-6">
            <UnitMetric icon={IconBedroom} title={t('Bedroom')} value={unitListingDetail()?.unit?.beds || emptyPlaceholder} />
            <UnitMetric icon={IconBathroom} title={t('Bathroom')} value={unitListingDetail()?.unit?.baths || emptyPlaceholder} />
            <UnitMetric
              icon={IconUnitSize}
              title={t('Unit size')}
              value={unitListingDetail()?.unit?.unitSizeSqft || emptyPlaceholder}
              suffix={t('Sqft')}
            />
            <UnitMetric
              icon={IconDateAvaliable}
              title={t('Date avaliable')}
              value={unitListingDetail()?.availableFrom || emptyPlaceholder}
            />
          </div>
          {/* <div class="mt-6 flex items-center gap-3">
            <span class="text-xs capitalize text-text-level03">{t('Previous lease')}:</span>
            <Dropdown
              class="cursor-pointer"
              contentPosition={['bottom']}
              renderLabel={(isOpen, open) => (
                <div onClick={() => open(!isOpen)} class="relative flex items-center gap-2">
                  <div class="flex w-64 items-center gap-2 rounded-lg border border-input-border bg-light-faded p-3 text-left text-xs">
                    <Avatar name="J W" size="large" />
                    <div>
                      <div>8/16/2023 - 8/1/2024</div>
                      <div>James Walker,sophia martin</div>
                    </div>
                  </div>
                  <div class="rotate-180">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                      <rect x="22" y="22" width="22" height="22" rx="11" transform="rotate(180 22 22)" fill="#3C36DD" />
                      <path
                        d="M12.0704 7.9657C11.4824 7.36665 10.5173 7.36665 9.92932 7.9657L6.75557 11.1993C5.82421 12.1482 6.49647 13.75 7.82608 13.75L14.1736 13.75C15.5032 13.75 16.1755 12.1482 15.2441 11.1993L12.0704 7.9657Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              )}>
              {(_, open) => (
                <div class="flex flex-col gap-1">
                </div>
              )}
            </Dropdown>
          </div> */}
        </div>
      </Panel>
      <div class="col-span-3 overflow-hidden rounded-lg bg-white p-2">
        <GoogleMap address={unitListingDetail()?.singleLineAddress} />
      </div>
      <Panel title={t('Facts and features')} class="col-span-6">
        <div class="flex w-full flex-wrap items-center gap-y-3 px-6 py-4">
          <Show when={amenityOverrides()?.length}>
            <For each={amenityOverrides()}>
              {(item) => (
                <span class="flex w-1/3 items-center gap-1 text-sm text-text-level02">
                  <Checkbox checked={item?.value} disabled />
                  {item?.label}
                </span>
              )}
            </For>
          </Show>
          <Show when={petPolicy()?.length}>
            <For each={petPolicy()}>
              {(item) => (
                <span class="flex w-1/3 items-center gap-1 text-sm text-text-level02">
                  <Checkbox checked={item?.value} disabled />
                  {item?.label}
                </span>
              )}
            </For>
          </Show>
        </div>
      </Panel>
      <Panel title={t('AI features')} class="col-span-6">
        <div class="px-6 py-2 text-text-level02">
          <div>{t('Here is what AI found from you pictures')}:</div>
          <div class="mt-2 flex flex-wrap items-center gap-1.5">
            <For each={unitListingDetail()?.tags}>
              {(tag) => <div class="rounded-md bg-light-pink px-3 py-1 text-sm text-[#B140F5]">{tag}</div>}
            </For>
          </div>
        </div>
      </Panel>
      <Panel
        title={t('Master listing descriprion')}
        class="col-span-full"
        actions={
          <div class="flex items-center gap-2">
            <LinkButton href="./listing-syndications" variant="outlined" class="border-link py-2 font-medium text-link hover:bg-link/10">
              <IconEdit /> {t('Edit')}
            </LinkButton>
            <AiButton text={t('Magic AI')} onClick={() => navigate('./magic-ai')} />
          </div>
        }>
        <div class="px-12 py-7 text-sm text-[#575757]">{unitListingDetail()?.description}</div>
      </Panel>
      <div class="col-span-full grid grid-cols-4 gap-3">
        <Show when={unitListingDetail()?.images?.length}>
          <ImageList images={unitListingDetail()?.images || []} listing={unitListingDetail() as MagicDoor.Api.HydratedUnitListingDto} />
        </Show>
      </div>
    </div>
  );
};
