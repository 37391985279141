import { createMemo, For, Show } from 'solid-js';
import PrintIcon from '~/assets/images/common/print.svg?component-solid';
import { Button } from '~/components/common/Buttons';
import { PropertyTitle } from '~/components/properties';
import { useLocalization } from '~/contexts/global';
import { useAddOwnerDistributions, PAYMENT_METHOD_OPTIONS } from '~/contexts/local';
import { cn } from '~/utils/classnames';
import { currency } from '~/utils/number';
import type { JSX } from 'solid-js';
import type { OwnerDistributionPrintCheckResultDto, OwnerDistributionResultDto } from '~/swagger/Api';

export const SummaryTable = () => {
  const { store, bankAccountOptions } = useAddOwnerDistributions();
  const { t } = useLocalization();
  const paymentMethodOptions = PAYMENT_METHOD_OPTIONS.map((option) => ({ label: t(option.label), value: option.value }));
  let printDocumentDom!: HTMLDivElement;

  const handlePrint = () => {
    try {
      if (!store.response?.printCheckResults || store.response.printCheckResults.length === 0) {
        console.warn('No print check results available');
        return;
      }

      const printWindow = window.open(' ', ' ', 'width=' + '1024px' + ', height=' + '800px');
      if (!printWindow) {
        console.error('Failed to open print window');
        return;
      }

      let checkContainers = '';
      for (const item of store.response.printCheckResults) {
        const checkData = item;

        const headers = checkData.check?.dataHeaders ? [...checkData.check.dataHeaders, 'Amount'] : ['Amount'];

        const lineItems = checkData.properties
          ?.map((property) => {
            const rowData: { [key: string]: string } = {
              Property: property.property?.name || 'Unknown Property',
              Reference: property.bill?.reference || '',
              Memo: checkData.check?.memo || '',
              Amount: currency(property.bill?.totalAmount || 0),
            };

            return `
            <tr>
              ${headers.map((header) => `<td>${rowData[header] || ''}</td>`).join('')}
            </tr>`;
          })
          .join('');

        const tableHeader = `
          <tr>
            ${headers.map((header) => `<th>${header}</th>`).join('')}
          </tr>`;

        checkContainers += `
          <div class="check-container">
            <div class="top-routing">${checkData.check?.checkNumber}</div>
            <div class="check-header">
              <div>
                <div>${checkData.check?.company?.name || ''}</div>
                <div>${checkData.check?.company?.address?.streetAddress1 || ''}</div>
                <div>${checkData.check?.company?.address?.city || ''}, ${checkData.check?.company?.address?.state || ''} ${
          checkData.check?.company?.address?.zipCode || ''
        }</div>
              </div>
              <div>Date: ${new Date(checkData.check?.date || '').toLocaleDateString()}</div>
            </div>
            <div class="payee-section">
              <div class="half-size">Pay to the order of:</div>
              <div>
                <strong>${checkData.owner?.firstName || ''} ${checkData.owner?.lastName || ''}</strong>
              </div>
            </div>
            <div class="payee-section">
          <div class="half-size">This amount:</div>
              <div class="amount-section-wrapper">
            <div> **** ${checkData.check?.amountString}</div>
                <div class="amount-section">
                  ${currency(checkData.check?.amount || 0)}
                </div>
              </div>
            </div>
            <div class="payee-address">
              ${checkData.check?.owner?.address?.streetAddress1 || ''}
              ${checkData.check?.owner?.address?.streetAddress2 ? ', ' + checkData.check?.owner?.address?.streetAddress2 : ''}<br>
              ${checkData.check?.owner?.address?.city || ''}, ${checkData.check?.owner?.address?.state || ''} ${
          checkData.check?.owner?.address?.zipCode || ''
        }
            </div>
            <div class="memo-section">
              ${checkData.check?.memo || '--'}
            </div>
            <div class="micr-font">
              c${checkData.check?.checkNumber}c A${checkData.bankAccount?.routingNumber}A ${checkData.bankAccount?.accountNumber}C
            </div>
              <div class="check-info-wrapper">
          <div class="check-information">
            <div class="date-section">
              <div>Date: ${new Date(checkData.check?.date || '').toLocaleDateString()}</div>
              <div>Check #${checkData.check?.checkNumber}</div>
              <div>Account: ${checkData.bankAccount?.name}</div>
            </div>
            <div class="account-section">
              Pay to: ${checkData.owner?.firstName || ''} ${checkData.owner?.lastName || ''}
            </div>
          </div>
          <div class="check-routing">${checkData.check?.checkNumber}</div>
        </div>
            <table class="line-items-table">
              <thead>
                ${tableHeader}
              </thead>
              <tbody>
                ${lineItems}
                <tr>
                  <td colspan="${headers.length - 1}" class="line-items-total">Total:</td>
                  <td>${currency(checkData.check?.amount || 0)}</td>
                </tr>
              </tbody>
            </table>
          </div>`;
      }

      const printContent = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <style>
        @font-face {
          font-family: 'MICR';
          src: url('/fonts/micr-encoding.ttf') format('truetype');
        }


        @page { size: auto;  margin: 0mm; }


        body {
          font-family: Arial, sans-serif;
          font-size: 14px;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        .check-container {
          width: 1000px;
          margin: 20px auto;
          padding: 30px;
          padding-top: 10px;
          padding-bottom: 20px;
          position: relative;
                    font-size: 15px;

          page-break-before: always;
        }
       .check-header {
          display: flex;
          justify-content: space-between;
           margin-bottom: 64px;
          padding: 0 60px;
        }
        .check-header div {
          text-align: left;
        }
        .half-size {
          width: 80px;
        }
      .top-routing {
          text-align: right;
          padding-bottom: 10px;
                padding: 0 60px;
        }
        .payee-section {
          margin: 0 20px 10px 0;
          display: flex;
        }
        .payee-section strong {
          font-size: 16px;
          display: block;
        }
        .amount-section-wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;
             font-size: 16px;
        }
    .amount-section {
          font-size: 16px;
          font-weight: bold;
                padding: 0 60px;
        }

        .payee-address {
          font-size: 16px;
          margin-left: 80px;
          height: 74px;
        }
        .memo-section {
          margin-top: 20px;
                              font-size: 15px;

        }
        .memo-section:before {
          content: 'MEMO';
          display: inline-block;
          margin-right: 10px;
        }
       .date-section {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          gap: 50px;
        }
        .account-section {
          margin-bottom: 10px;
          font-size: 14px;
        }
        .check-info-wrapper {
          display: flex;
          justify-content: space-between;
          margin-top: 60px;
        }
        .check-number {
          font-size: 20px;
          font-weight: bold;
          position: absolute;
          right: 30px;
          top: 30px;
        }
        .check-routing {
          font-size: 20px;
        }
         .line-items-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 10px;
          font-size: 14px;
        }
        .line-items-table th,
        .line-items-table td {
          border: 1px solid #000;
          padding: 8px;
          text-align: left;
        }
        .line-items-table th {
          background-color: #f2f2f2;
        }
        .line-items-total {
          text-align: right;
          padding-right: 10px;
          font-weight: bold;
          font-size: 14px;
        }
  .micr-font {
          font-family: 'MICR', monospace;
          font-size: 32px;
          text-align: center;
          margin-top: 30px;
          letter-spacing: 2px;
        }
      </style>
      <title>Print check</title>
    </head>
    <body>
      ${checkContainers}
    </body>
    </html>
    `;

      printWindow.document.write(printContent);
      printWindow.document.close();

      const checkContentLoaded = () => {
        if (printWindow.document.readyState === 'complete') {
          printWindow.print();
          printWindow.close();
        } else {
          setTimeout(checkContentLoaded, 100);
        }
      };

      setTimeout(checkContentLoaded, 1000);
    } catch (error) {
      console.error('Error in handlePrint:', error);
    }
  };

  const manualColumns: { title: string; class: string; render: (item: OwnerDistributionResultDto) => JSX.Element }[] = [
    {
      title: t('Property'),
      class: 'w-[30%]',
      render: (record) => (
        <PropertyTitle property={record.properties?.[0]?.property} class="h-9 [&>img]:size-9 [&_h4]:text-xs [&_h4]:font-medium" />
      ),
    },
    {
      title: t('Payment method'),
      class: 'w-[20%]',
      render: (record) => paymentMethodOptions.find((option) => option.value === record.transaction?.transactionPaymentMethod)?.label || '',
    },
    {
      title: t('External transaction ID'),
      class: 'w-[30%]',
      render: (record) => record.transaction?.externalTransactionId || '',
    },
    {
      title: t('Amount'),
      class: 'w-[20%] text-right',
      render: (record) => currency(record.transaction?.amount),
    },
  ];

  const printCheckColumns: { title: string; class: string; render: (item: OwnerDistributionPrintCheckResultDto) => JSX.Element }[] = [
    {
      title: t('Property'),
      class: 'w-[25%]',
      render: (record) => (
        <PropertyTitle property={record.properties?.[0]?.property} class="h-9 [&>img]:size-9 [&_h4]:text-xs [&_h4]:font-medium" />
      ),
    },
    {
      title: t('Check #'),
      class: 'w-[15%]',
      render: (record) => <div>#{record.check?.checkNumber?.toString()}</div>,
    },
    {
      title: t('Owner name'),
      class: 'w-[25%]',
      render: (record) => `${record.owner?.firstName || ''} ${record.owner?.lastName || ''}`,
    },
    {
      title: t('Bank account'),
      class: 'w-[20%]',
      render: (record) => bankAccountOptions()?.find((account) => account.value === record.bankAccount?.id)?.label || '',
    },
    {
      title: t('Amount'),
      class: 'w-[15%] text-right',
      render: (record) => currency(record.check?.amount),
    },
  ];

  const renderSummary = (data: any[]) => {
    const propertiesCount = createMemo(
      () => new Set(data.flatMap((r) => r.properties.map((p: { property: { id: any } }) => p.property.id))).size
    );
    const ownersCount = createMemo(() => new Set(data.map((r) => r.owner.id)).size);
    const totalAmount = createMemo(() => data.reduce((acc, cur) => acc + (cur.transaction?.amount || cur.check?.amount || 0), 0));

    return (
      <div class="mt-[14px] flex items-center justify-end gap-8 pr-5 text-text-level02">
        <div class="flex items-center justify-end gap-4">
          <div class="inline-flex items-center gap-3 text-xs">
            <span class="inline-flex gap-1">
              <span>{t('Properties')}</span>
              <span class="font-semibold">{propertiesCount()}</span>
            </span>
            <span class="inline-flex gap-1">
              <span>{t('Owners')}</span>
              <span class="font-semibold">{ownersCount()}</span>
            </span>
            <span class="inline-flex gap-1">
              <span>{t('Number of payments')}</span>
              <span class="font-semibold">{data.length}</span>
            </span>
          </div>
          <div class="inline-flex items-center">
            <span>{`${t('Distributions Amount')}:`}&nbsp;</span>
            <span class="font-semibold text-essential-colour">{currency(totalAmount())}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderTable = (title: string, columns: any[], data: any[]) => (
    <Show when={data.length > 0}>
      <div class="flex justify-between">
        <h4 class="mb-2 mt-4 font-semibold">{title}</h4>
        <Show when={title === t('Check payments')}>
          <Button variant="outlined" onClick={handlePrint}>
            <PrintIcon />
            <span>{t('Print')}</span>
          </Button>
        </Show>
      </div>
      <div class="w-full overflow-hidden rounded-lg border border-input">
        <table class="w-full border-collapse text-xs">
          <thead class="border-b border-input">
            <tr>
              <For each={columns}>
                {(column) => <th class={cn('h-[54px] px-4 py-2 text-left font-normal text-text-level03', column.class)}>{column.title}</th>}
              </For>
            </tr>
          </thead>
          <tbody>
            <For each={data}>
              {(record) => (
                <tr class="border-y border-input last-of-type:border-b-0">
                  <For each={columns}>
                    {(column) => (
                      <td class={cn('h-[54px] border-r border-input px-4 py-2 last-of-type:border-r-0', column.class)}>
                        {column.render(record)}
                      </td>
                    )}
                  </For>
                </tr>
              )}
            </For>
          </tbody>
        </table>
      </div>
      {renderSummary(data)}
    </Show>
  );

  return (
    <div class="flex flex-col gap-3 px-6">
      <div class="flex items-center justify-between">
        <h3 class="font-semibold text-text-level01">{t('Table of distributions')}</h3>
      </div>

      <div ref={printDocumentDom} class="w-full">
        {renderTable(t('Check payments'), printCheckColumns, store.response?.printCheckResults || [])}
        {renderTable(t('Manual payments'), manualColumns, store.response?.manualResults || [])}
      </div>
    </div>
  );
};
