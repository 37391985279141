import { BaseRestRepository } from '~/repositories/baseRestRepository';
import type { Signer } from '~/pdfsigner/usecases/types/signer';

class SignerRepository extends BaseRestRepository {
  public async getSignersForLease(leaseId: string): Promise<Signer[]> {
    const result: Signer[] = [];
    const pmUrl = '/api/property-managers';
    const pmJson = await this.getJsonResponse(await this.fetchWithAuth(pmUrl));
    const pms: Signer[] = this.getPmSignersFromJson(pmJson);
    result.push(...pms);
    const leaseUrl = `/api/leases/${leaseId}`;
    const tenantsJson = (await this.getJsonResponse(await this.fetchWithAuth(leaseUrl))).tenants;
    const tenants = this.getTenantSignersFromJson(tenantsJson);
    result.push(...tenants);
    return result;
  }

  private getPmSignersFromJson(json: any): Signer[] {
    return json.map((item: any) => {
      return {
        id: item.id,
        name: `${item.firstName} ${item.lastName}`,
        isPropertyManager: true,
      };
    });
  }
  private getTenantSignersFromJson(json: any): Signer[] {
    return json.map((item: any) => {
      return {
        id: item.tenant.id,
        name: `${item.tenant.firstName} ${item.tenant.lastName}`,
        isPropertyManager: false,
      };
    });
  }
}
export const signerRepository = new SignerRepository();
