import { useNavigate } from '@solidjs/router';
import { createMemo, createSignal, For, Show } from 'solid-js';
import addListingBg from '~/assets/images/units/listing/add_listing_bg.png';
import { OptionButton } from '~/components/common/OptionButton';
import { Modal } from '~/components/modals/Modal';
import { LabeledPortfolioSearch } from '~/components/portfolios';
import { LabeledPropertySearch } from '~/components/properties';
import { toast } from '~/components/ui';
import { LabeledUnitSearch } from '~/components/units';
import { useLocalization, usePortfolios, useUnitListings } from '~/contexts/global';
import { IconPortfolioColored, IconPropertyColored, IconUnitColored } from '~/pages/settings/components/Icons';
import type { Component } from 'solid-js';
import type { addUnitListingPayload } from '~/repositories/unitListingsRepository';
import type { ImportUnitListingDto } from '~/swagger/Api';

export const AddUnitListingModal: Component = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const [showError, setShowError] = createSignal<boolean>(false);
  const [selectionType, setSelectionType] = createSignal<string>('');
  const [selectedIds, setSelectedIds] = createSignal<addUnitListingPayload>();
  const { current } = usePortfolios();
  const { addUnitListing } = useUnitListings();

  const selectionTypes = createMemo(() => [
    { value: 'portfolio', label: t('Portfolio'), Icon: IconPortfolioColored },
    { value: 'property', label: t('Property'), Icon: IconPropertyColored },
    { value: 'unit', label: t('Unit'), Icon: IconUnitColored },
  ]);

  const handleClose = () => {
    const currentId = current()?.id;
    navigate(currentId ? `/listing/${currentId}` : '/listing', { scroll: false, replace: true });
  };

  const handleSubmit = async () => {
    const values = selectedIds() && Object.values(selectedIds() as addUnitListingPayload);
    if (!values) {
      setShowError(true);
    } else {
      try {
        setShowError(false);
        const res = await addUnitListing(selectedIds() as addUnitListingPayload);
        handleClose();
        handleImportTip(res);
      } catch (e) {
        console.log(e);
      }
    }
  };
  const handleImportTip = (res: ImportUnitListingDto) => {
    const count = res?.unitListingIds?.length || 0;
    if (count) {
      toast.success(t('Successfully added {count} items of data', { count: count }));
    } else {
      toast.error(t('No data imported'));
    }
  };
  const handleSelectionTypeChange = (value: string) => {
    setSelectionType(value);
  };
  const handleIdSelection = (ids: string | string[], type: 'portfolioIds' | 'propertyIds' | 'unitIds') => {
    const idArray = Array.isArray(ids) ? ids : [ids];
    setSelectedIds({ [type]: idArray });
  };

  return (
    <Modal
      visible
      class="animate-zoom-in"
      loading={false}
      title={t('Select listing units')}
      doneText={t('Confirm')}
      onDone={handleSubmit}
      onCancel={handleClose}
      confirmation={false}>
      <div
        class="h-32"
        style={{
          'background-image': `url(${addListingBg})`,
          'background-position': 'center',
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
        }}
      />
      <div class="px-7 py-6">
        <div class="mb-3 flex w-full flex-wrap gap-2">
          <For each={selectionTypes()}>
            {(type) => (
              <OptionButton
                class="grow"
                label={type.label}
                isActive={selectionType() === type.value}
                Icon={type.Icon}
                onClick={() => handleSelectionTypeChange(type.value)}
              />
            )}
          </For>
        </div>
        <Show when={selectionType() === 'portfolio'}>
          <div class="w-full">
            <LabeledPortfolioSearch
              label={t('Select portfolios')}
              multiple
              value={selectedIds()?.portfolioIds}
              onInput={(portfolios) => handleIdSelection(portfolios, 'portfolioIds')}
            />
          </div>
        </Show>

        <Show when={selectionType() === 'property'}>
          <div class="w-full">
            <LabeledPropertySearch
              label={t('Select properties')}
              multiple
              value={selectedIds()?.propertyIds}
              onInput={(properties) => handleIdSelection(properties, 'propertyIds')}
            />
          </div>
        </Show>

        <Show when={selectionType() === 'unit'}>
          <div class="w-full">
            <LabeledUnitSearch
              multiple
              label={t('Select units')}
              value={selectedIds()?.unitIds}
              onInput={(units) => handleIdSelection(units, 'unitIds')}
            />
          </div>
        </Show>
        <Show when={showError()}>
          <p class="w-full text-red-500">{t('Please select a portfolio, property, or unit')}</p>
        </Show>
      </div>
    </Modal>
  );
};
