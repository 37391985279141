import { Route, Routes, useNavigate, useParams } from '@solidjs/router';
import IconAiBot from '~/assets/images/chat/aiChatBot.svg';
import IconAiGradientBg from '~/assets/images/chat/aiMagicItGradientBg.png';
import { Panel } from '~/components/common/Panels';
import { useLocalization, useUnitListings } from '~/contexts/global';
import { FreeListingSites } from '~/pages/listing/components/FreeListingSites';
import { ListingSyndications } from '~/pages/listing/components/ListingSyndications';
import { MasterListing } from '~/pages/listing/components/MasterListing';
import { PaidListingSites } from '~/pages/listing/components/PaidListingSites';
export const ListingSyndicationsTab = () => {
  const { t } = useLocalization();
  const { unitListingDetail } = useUnitListings();
  const navigate = useNavigate();
  const params = useParams<{ listingId: `${number}` }>();

  return (
    <div class="flex flex-col gap-5">
      <Panel
        title={t('Master listing descriprion')}
        class="col-span-full"
        actions={
          <div class="mb-1.5 mr-1 rounded-lg bg-gradient-to-tl">
            <button
              class="flex h-10 w-fit items-center justify-center gap-2 bg-contain bg-left bg-no-repeat px-4	text-white"
              onClick={() => navigate(`/listing/details/${params.listingId}/magic-ai`)}
              style={{
                'background-image': `url(${IconAiGradientBg})`,
                'background-position': 'left, right',
                'background-repeat': 'no-repeat, no-repeat',
              }}>
              <img src={IconAiBot} class="hide-on-mobile" />
              <span class="flex whitespace-nowrap text-base	font-medium">{t('Magic AI')}</span>
            </button>
          </div>
        }>
        <div class="px-12 py-7 text-sm text-[#575757]">{unitListingDetail()?.description}</div>
      </Panel>
      <div>
        <ListingSyndications />
        <PaidListingSites />
        <FreeListingSites />
      </div>
      <Routes>
        <Route path="/master-listing" component={MasterListing} />
      </Routes>
    </div>
  );
};
