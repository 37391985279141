import { signerColorsTranslucent } from '~/pdfsigner/ui/types/signerColors';
import { AnnotationType } from '~/pdfsigner/usecases/types/annotation';
import { DocumentStatus } from '~/pdfsigner/usecases/types/paginatedDocumentDescriptor';
import { dateFormat } from '~/utils/date';
import { Presenter } from './Presenter';
import type { PresentableAnnotatedPdfPage } from '~/pdfsigner/ui/types/presentableAnnotatedPdfPage';
import type { PresentableAnnotation } from '~/pdfsigner/ui/types/presentableAnnotation';
import type { PresentableUnsignedPdfPage } from '~/pdfsigner/ui/types/presentableUnsignedPdfPage';
import type { DataPathManager } from '~/pdfsigner/usecases/state/dataPathManager';
import type { PdfState } from '~/pdfsigner/usecases/state/pdfAppState';
import type { Annotation } from '~/pdfsigner/usecases/types/annotation';
import type { Signer } from '~/pdfsigner/usecases/types/signer';

interface AnnotationDescriptor {
  annotation: Annotation;
  selectedAnnotationId: string | undefined;
  isEditing: boolean;
  pageBounds: DOMRect;
  signers: Signer[];
  dataPathManager?: DataPathManager;
}

export class PdfPresenter extends Presenter<PresentableAnnotatedPdfPage[]> {
  createModel(state: PdfState): PresentableAnnotatedPdfPage[] {
    const result: PresentableAnnotatedPdfPage[] = [];
    const pdfPages = state.selectedDocument?.pdfPages || [];
    if (pdfPages.length === 0) {
      return result;
    }
    let pageNumber = 0;
    for (let i = 0; i < pdfPages.length; i++) {
      const element = document.getElementById('pdfPage' + pageNumber);
      const rect: DOMRect | undefined = element?.getBoundingClientRect();
      const page = pdfPages[i];
      const annotations = this.createPresentableAnnotations(state, page.annotations, rect);
      result.push({
        page: page.page,
        annotations,
        pageNumber,
      });
      pageNumber++;
    }
    return result;
  }

  private createPresentableAnnotations(state: PdfState, annotations: Annotation[], pageBounds?: DOMRect): PresentableAnnotation[] {
    return annotations.map((annotation: Annotation): PresentableAnnotation => {
      const dataSource: AnnotationDescriptor = {
        annotation,
        isEditing: state.isEditing,
        selectedAnnotationId: state.selectedAnnotation?.id,
        pageBounds: pageBounds || new DOMRect(),
        signers: state.selectedDocument?.signers || [],
        dataPathManager: state.dataPathManager,
      };
      return this.createPresentableAnnotation(dataSource);
    });
  }

  protected createPresentableAnnotation(descriptor: AnnotationDescriptor): PresentableAnnotation {
    const signerIndex = descriptor.signers.findIndex((signer) => signer.id === descriptor.annotation.signerId);
    const isSelected = descriptor.annotation.id === descriptor.selectedAnnotationId;
    const isEditing = isSelected && !isClientInput(descriptor.annotation.type) && descriptor.isEditing;
    let isReadOnly = isSelected && !isEditing;
    let borderColor = 'lightgray';
    let backgroundColor = 'white';
    let text = this.getAnnotationText(descriptor.annotation, descriptor.dataPathManager);
    if (isClientInput(descriptor.annotation.type)) {
      text = `${descriptor.signers[signerIndex].name}: ${text}`;
    }
    switch (descriptor.annotation.type) {
      case AnnotationType.SIGNATURE:
      case AnnotationType.INITIALS:
      case AnnotationType.DATE:
        borderColor = 'purple';
        backgroundColor = descriptor.annotation.signerId !== undefined ? signerColorsTranslucent[signerIndex] : 'white';
        isReadOnly = true;
        break;
      case AnnotationType.AUTOFILL:
        borderColor = 'blue';
        backgroundColor = 'rgba(48, 168, 255, 0.2)';
        isReadOnly = true;
        break;
    }

    const presentableAnnotation: PresentableAnnotation = {
      id: descriptor.annotation.id,
      x: descriptor.annotation.x * descriptor.pageBounds.width,
      y: descriptor.annotation.y * descriptor.pageBounds.height,
      width: descriptor.annotation.width * descriptor.pageBounds.width,
      height: descriptor.annotation.height * descriptor.pageBounds.height,
      text,
      borderColor,
      backgroundColor,
      fontType: 'Helvetica',
      fontSize: descriptor.annotation.fontSize,
      isSelected,
      isReadOnly,
      isNewlyAdded: descriptor.annotation.isNewlyAdded,
    };
    return presentableAnnotation;
  }

  private getAnnotationText(annotation: Annotation, dataPathManager?: DataPathManager) {
    switch (annotation.type) {
      case AnnotationType.DATE:
        return 'MM/DD/YYY';
      case AnnotationType.INITIALS:
        return 'XX';
      case AnnotationType.SIGNATURE:
        return 'Signature';
      case AnnotationType.AUTOFILL:
        return (
          dataPathManager?.getValueOfDataPathWithKey(annotation.dataPathKey) ||
          dataPathManager?.getNameOfDataPathWithKey(annotation.dataPathKey) ||
          ''
        );
      default:
        return annotation.text;
    }
  }
}

export class LeaseDraftPresenter extends Presenter<PresentableUnsignedPdfPage> {
  createModel(state: PdfState): PresentableUnsignedPdfPage {
    const result: PresentableUnsignedPdfPage = {
      pdf: [],
      status: state.selectedDocument?.status || DocumentStatus.Draft,
    };

    const pdfPages = state.selectedDocument?.pdfPages || [];
    if (pdfPages.length === 0) {
      return result;
    }
    let pageNumber = 0;
    for (let i = 0; i < pdfPages.length; i++) {
      const element = document.getElementById('pdfPage' + pageNumber);
      const rect: DOMRect | undefined = element?.getBoundingClientRect();
      const page = pdfPages[i];
      const annotations =
        state.selectedDocument?.status === DocumentStatus.Draft ? this.createPresentableAnnotations(state, page.annotations, rect) : [];
      result.pdf.push({
        page: page.page,
        annotations,
        pageNumber,
      });
      pageNumber++;
    }
    return result;
  }

  private createPresentableAnnotations(state: PdfState, annotations: Annotation[], pageBounds?: DOMRect): PresentableAnnotation[] {
    return annotations.map((annotation: Annotation): PresentableAnnotation => {
      const dataSource: AnnotationDescriptor = {
        annotation,
        isEditing: state.isEditing,
        selectedAnnotationId: state.selectedAnnotation?.id,
        pageBounds: pageBounds || new DOMRect(),
        signers: state.selectedDocument?.signers || [],
        dataPathManager: state.dataPathManager,
      };
      return this.createPresentableAnnotation(dataSource);
    });
  }
  private createPresentableAnnotation(descriptor: AnnotationDescriptor): PresentableAnnotation {
    const signerIndex = descriptor.signers.findIndex((signer) => signer.id === descriptor.annotation.signerId);
    const isSelected = descriptor.annotation.id === descriptor.selectedAnnotationId;
    const isEditing = isSelected && !isClientInput(descriptor.annotation.type) && descriptor.isEditing;
    let isReadOnly = isSelected && !isEditing;
    let borderColor = 'lightgray';
    let backgroundColor = 'white';
    switch (descriptor.annotation.type) {
      case AnnotationType.SIGNATURE:
      case AnnotationType.INITIALS:
      case AnnotationType.DATE:
        borderColor = 'purple';
        backgroundColor = descriptor.annotation.signerId !== undefined ? signerColorsTranslucent[signerIndex] : 'white';
        isReadOnly = true;
        break;
      case AnnotationType.AUTOFILL:
        borderColor = 'blue';
        backgroundColor = 'rgba(48, 168, 255, 0.2)';
        isReadOnly = true;
        break;
    }
    return {
      id: descriptor.annotation.id,
      x: descriptor.annotation.x * descriptor.pageBounds.width,
      y: descriptor.annotation.y * descriptor.pageBounds.height,
      width: descriptor.annotation.width * descriptor.pageBounds.width,
      height: descriptor.annotation.height * descriptor.pageBounds.height,
      text: this.getAnnotationTextFromDescriptor(descriptor.annotation, descriptor.signers[signerIndex], descriptor.dataPathManager),
      borderColor,
      backgroundColor,
      fontType: this.getFontType(descriptor.annotation.type),
      fontSize: descriptor.annotation.fontSize,
      isSelected,
      isReadOnly,
      isNewlyAdded: descriptor.annotation.isNewlyAdded,
    };
  }
  private getAnnotationTextFromDescriptor(annotation: Annotation, signer: Signer, dataPathManager?: DataPathManager): string {
    switch (annotation.type) {
      case AnnotationType.DATE:
        return dateFormat('YYYY/MM/DD hh:mm', new Date());
      case AnnotationType.INITIALS:
        return this.getInitials(signer.name);
      case AnnotationType.SIGNATURE:
        return signer.name;
      case AnnotationType.AUTOFILL:
        return (
          dataPathManager?.getValueOfDataPathWithKey(annotation.dataPathKey) ||
          dataPathManager?.getNameOfDataPathWithKey(annotation.dataPathKey) ||
          ''
        );
      default:
        return annotation.text;
    }
  }

  private getInitials(name: string): string {
    const parts = name.split(/[\s-]+/);
    const initials = parts.map((part) => (part ? part[0].toUpperCase() : '')).join('');
    return initials;
  }

  private getFontType(annotationType: AnnotationType): string {
    return annotationType === AnnotationType.SIGNATURE || annotationType === AnnotationType.INITIALS ? 'SignatureFont' : 'Helvetica';
  }
}

const isClientInput = (annotationType: AnnotationType): boolean => {
  return (
    annotationType === AnnotationType.SIGNATURE || annotationType === AnnotationType.INITIALS || annotationType === AnnotationType.DATE
  );
};
