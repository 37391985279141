import { useNavigate, useParams } from '@solidjs/router';
import { createMemo, onMount, Show } from 'solid-js';
import PlusIcon from '~/assets/images/common/plus.svg?component-solid';
import UploadIcon from '~/assets/images/common/upload.svg?component-solid';
import BuildingIcon from '~/assets/images/rental-application/building.svg?component-solid';
import HistoryIcon from '~/assets/images/rental-application/history.svg?component-solid';
import ReportIcon from '~/assets/images/rental-application/report.svg?component-solid';
import StarIcon from '~/assets/images/rental-application/star.svg?component-solid';
import WorkIcon from '~/assets/images/rental-application/work.svg?component-solid';
import { CategoryComponentSection, CategoryComponentWrapper, CategoryContentSection } from '~/components/common/Category';
import { useLocalization, useRentalApplication } from '~/contexts/global';
import { currentUpdateType } from '~/utils/constant';
import { AIScore } from './AiScore';
import { Documents } from './Documents';
import { PotentialUnits } from './PotentialUnits';
import { RentalHistory } from './RentalHistory';
import { RentalInformation } from './RentalInfomation';
import { WorkHistory } from './WorkHistory';
import type { Section } from '~/components/common/Category';

const Skeleton = () => {
  return (
    <div class="flex flex-1 flex-col items-stretch">
      <div class="mb-7 border-b py-3 text-lg font-semibold text-text-level01">
        <div class="skeleton-shimmer h-5 " />
      </div>
      <div class="flex flex-col gap-4">
        <div class="skeleton-shimmer h-4 w-2/3" />
        <div class="skeleton-shimmer h-4 w-2/3" />
        <div class="skeleton-shimmer h-4 w-2/3" />
      </div>
    </div>
  );
};

export const RenterReport = () => {
  const { t } = useLocalization();
  const { applicationStore, setApplicationStore, onAddPotentialUnit, fetchApplication, isNewApplicant } = useRentalApplication();
  const { applicationId } = useParams();
  const navigate = useNavigate();

  const defaultSection = 'ai-score';

  const sections = createMemo<Section[]>(() => [
    {
      key: defaultSection,
      name: t('Magicdoor AI-score'),
      component: AIScore,
      icon: StarIcon,
    },
    {
      key: 'renter-information',
      name: t('Rental information'),
      component: RentalInformation,
      icon: BuildingIcon,
      titleActions: [
        !isNewApplicant() && {
          label: t('View tenant detail'),
          onClick() {
            navigate(`/users/tenants/${applicationStore.application?.tenant?.id}`);
          },
        },
        {
          label: t('Update'),
          icon: UploadIcon,
          onClick() {
            setApplicationStore('currentUpdateType', currentUpdateType.rentalInfo);
          },
        },
      ].filter(Boolean),
    },
    {
      key: 'potential-units',
      name: t('Potential units'),
      component: PotentialUnits,
      icon: StarIcon,
      titleActions: [
        {
          label: t('Add'),
          icon: PlusIcon,
          onClick() {
            onAddPotentialUnit();
          },
        },
      ],
    },
    {
      key: 'rental-history',
      name: t('Rental history'),
      component: RentalHistory,
      icon: HistoryIcon,
      titleActions: [
        {
          label: t('Update'),
          icon: UploadIcon,
          onClick() {
            setApplicationStore('currentUpdateType', currentUpdateType.rental);
          },
        },
      ],
    },
    {
      key: 'work-history',
      name: t('Work history'),
      component: WorkHistory,
      icon: WorkIcon,
      titleActions: [
        {
          label: t('Update'),
          icon: UploadIcon,
          onClick() {
            setApplicationStore('currentUpdateType', currentUpdateType.work);
          },
        },
      ],
    },
    {
      key: 'reports',
      name: t('Documents'),
      component: Documents,
      icon: ReportIcon,
      titleActions: [
        {
          label: t('Update'),
          icon: UploadIcon,
          onClick() {
            setApplicationStore('currentUpdateType', currentUpdateType.document);
          },
        },
      ],
    },
  ]);

  let scrollContainer!: HTMLDivElement;

  onMount(() => {
    fetchApplication(applicationId);
  });

  return (
    <CategoryComponentWrapper sections={sections()} getScrollElement={() => scrollContainer} defaultSelection={defaultSection}>
      <div class="flex items-start gap-2">
        <CategoryComponentSection mode="plain" class="pt-5" footerClass="sticky bottom-0" />
        <div
          ref={scrollContainer}
          class="thinscroll flex flex-1 flex-col overflow-y-auto rounded bg-white px-7 text-left"
          style={{ height: 'calc(100vh - 256px)' }}>
          <Show when={!applicationStore.initLoadingDetail} fallback={<Skeleton />}>
            <CategoryContentSection />
          </Show>
        </div>
      </div>
    </CategoryComponentWrapper>
  );
};
