import { ValidationError } from '~/errors';
import { urlWithQuery } from '~/utils/url';
import { BaseRestRepository } from './baseRestRepository';

const PORTFOLIO_URL = '/api/portfolios';

export function validatePortfolio(portfolio: MagicDoor.Api.HydratedPortfolioDto | MagicDoor.Api.CreateOrUpdatePortfolioDto): void {
  if (!portfolio) throw new ValidationError('Portfolio object must be provided');
}

export function validateId(id: string, entityName = 'Entity'): void {
  if (!id) throw new ValidationError(`${entityName} ID must be provided`);
}

export type PortfolioFilter = MagicDoor.Filter;

export class PortfolioRepository extends BaseRestRepository {
  public async getPortfolios(filter?: PortfolioFilter): Promise<MagicDoor.Api.HydratedPortfolioDto[]> {
    const url = urlWithQuery(PORTFOLIO_URL, filter);
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async getPortfolio(portfolioId: string): Promise<MagicDoor.Api.HydratedPortfolioDto> {
    validateId(portfolioId);
    const url = `${PORTFOLIO_URL}/${portfolioId}`;
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async batchGetPortfolioByIds(portfolioIds: string[]): Promise<MagicDoor.Api.HydratedPortfolioDto[]> {
    const url = urlWithQuery(`${PORTFOLIO_URL}/batch`, { portfolioIds });
    const response = await this.fetchWithAuth(url);
    return this.getJsonResponse(response);
  }

  public async createPortfolio(portfolio: MagicDoor.Api.CreateOrUpdatePortfolioDto): Promise<MagicDoor.Api.PortfolioDto> {
    validatePortfolio(portfolio);
    const url = PORTFOLIO_URL;
    const response = await this.fetchWithAuth(url, {
      method: 'POST',
      body: JSON.stringify(portfolio),
    });
    return this.getJsonResponse(response);
  }

  public async updatePortfolio(
    portfolioId: string,
    portfolio: MagicDoor.Api.CreateOrUpdatePortfolioDto
  ): Promise<MagicDoor.Api.PortfolioDto> {
    validateId(portfolioId);
    validatePortfolio(portfolio);
    const url = `${PORTFOLIO_URL}/${portfolioId}`;
    const response = await this.fetchWithAuth(url, {
      method: 'PUT',
      body: JSON.stringify(portfolio),
    });
    return this.getJsonResponse(response);
  }

  /** Not preferred. use deactivatePortfolio instead in most cases */
  public async deletePortfolio(portfolioId: string): Promise<void> {
    validateId(portfolioId);
    const url = `${PORTFOLIO_URL}/${portfolioId}`;
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async deactivatePortfolio(portfolioId: string, forceDeactivateChildren?: boolean): Promise<void> {
    validateId(portfolioId);
    const url = urlWithQuery(`${PORTFOLIO_URL}/${portfolioId}/deactivate`, { forceDeactivateChildren });
    await this.fetchWithAuth(url, { method: 'DELETE' });
  }

  public async getPortfolioOverview(portfolioId: string, useUpdatedAt?: boolean): Promise<MagicDoor.Api.PortfolioOverviewDto> {
    let url = `${PORTFOLIO_URL}/${portfolioId}/overview`;
    if (useUpdatedAt) {
      url += `?updatedAt=` + new Date().getTime();
    }
    const response = await this.fetchWithAuth(url, { method: 'GET' });
    return this.getJsonResponse(response);
  }
}
