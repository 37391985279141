import { useNavigate, useSearchParams } from '@solidjs/router';
import { createEffect, createMemo, createSignal, onMount } from 'solid-js';
import { createStore } from 'solid-js/store';
import Breadcrumb from '~/components/common/Breadcrumb';
import { MultiStepsPanel } from '~/components/common/Panels/MultiStepsPanel';
import { Stepper } from '~/components/common/Stepper';
import { toast } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useBills } from '~/contexts/local';
import BillStore from '~/pages/bills/store';
import { TransactionPaymentMethod } from '~/swagger/Api';
import { cloneDeep, isEmptyData, isNull, isUndefined } from '~/utils/tool';
import AllocationsForm from './components/AllocationsForm';
import ChooseBillsForm from './components/ChooseBillsForm';
import PaymentForm from './components/PaymentForm';
import ReviewPrintForm from './components/ReviewPrintForm';
import type { Bill, BillPayments, BillVendors, PayBillsForm, PayBillsFormError } from './types';

const initPayBillsForm = {
  isAllProperties: true,
  isAllVendors: true,
  properties: [],
  vendors: [],
  consolidatePayments: true,
  billPayments: [],
  paymentDate: new Date().toISOString().split('T')[0],
};

export const PayBillView = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const [query] = useSearchParams<{ propertyId?: string; vendorId?: string }>();
  const breadcrumbItems = createMemo(() => [{ label: t('Bills'), link: '/accounting/bills' }, { label: t('Pay bills') }]);

  const [responseData, setResponseData] = createSignal<MagicDoor.Api.PayBillsResultsDto>({});
  const { store, getVendorBillsPay, getChartOfAccounts, getBankAccounts } = BillStore;
  const { payBill } = useBills();
  const [currentStep, setCurrentStep] = createSignal<number>(0);
  const [isValidate, setIsValidate] = createSignal<boolean>(false);
  const [formData, setFormData] = createStore<PayBillsForm>(cloneDeep(initPayBillsForm));
  const [formError, setFormError] = createStore<PayBillsFormError>({});
  const [needToUpdate, setNeedToUpdate] = createSignal<boolean>(true);

  const steps = createMemo(() => [t('Choose bills'), t('Allocations'), t('Payment'), t('Review & print')]);

  const extractBills = () => {
    const billPayments = formData.billPayments?.map((item) => {
      const bankAccountId = store?.bankAccountOptions ? store?.bankAccountOptions[0].value : '';
      const bills: BillPayments['bills'] = [];

      item.vendors?.forEach((vendor) => {
        if (vendor.bills) {
          vendor.bills.forEach((bill) => {
            const updatedBill = {
              ...bill,
              bankAccountId: bill.bankAccountId || bankAccountId,
              paymentMethod: bill.paymentMethod || TransactionPaymentMethod.Ach,
              memo: bill.memo || '',
              paymentType: bill.paymentType || item.paymentType,
            };
            bills.push(updatedBill);
          });
        }
      });

      return {
        ...item,
        bankAccountId: item.bankAccountId || bankAccountId,
        paymentMethod: item.paymentMethod || TransactionPaymentMethod.Ach,
        paymentType: item.paymentType,
        bills,
      };
    });

    console.log('Final extracted billPayments:', billPayments);
    setFormData('billPayments', billPayments);
  };

  const extractAllBills = (key: 'vendors' | 'bills'): BillPayments['bills'] => {
    const result: BillPayments['bills'] = [];
    formData.billPayments?.forEach((item) => {
      if (key === 'vendors') {
        item.vendors?.forEach((vendor) => {
          result.push(...(vendor.bills || []));
        });
      } else {
        result.push(...(item.bills || []));
      }
    });
    return result;
  };

  const validateBills = (bills: Bill[]): string | null => {
    for (const bill of bills) {
      if (!bill.id) return t('Bill ID is required for all bills');
      if (typeof bill.amount !== 'number' || isNaN(bill.amount) || bill.amount < 0) return t('Valid amount is required for all bills');
      if (!bill.paymentType) return t('Payment type is required for all bills');
    }
    return null;
  };

  const stepValidator = (step: number): boolean => {
    let flag = true;
    setIsValidate(true);
    switch (step) {
      case 1:
        const properties = !formData.isAllProperties && isEmptyData(formData.properties);
        const vendors = !formData.isAllVendors && isEmptyData(formData.vendors);
        properties ? setFormError('properties', t('Select property')) : setFormError('properties', '');
        vendors ? setFormError('vendors', t('Please select vendor')) : setFormError('vendors', '');
        if (properties || vendors) {
          flag = false;
          setIsValidate(false);
        }
        break;
      case 2:
        const bills = extractAllBills('vendors');
        if (bills?.length === 0) {
          flag = false;
          setIsValidate(false);
          setFormError('bills', t('No bills selected for payment'));
          const validationError = validateBills(bills);
          if (validationError) {
            toast.error(validationError);
          }
        } else {
          const isValid = bills?.every((item) => {
            if (isUndefined(item.amount) || isNull(item.amount)) {
              setFormError('bills', t('All bills must have an amount entered'));
              return false;
            }
            const amount = Number(item.amount);
            if (isNaN(amount) || amount < 0) {
              setFormError('bills', t('All amounts must be valid positive numbers'));
              return false;
            }
            if (amount > Number(item.due)) {
              setFormError('bills', t('Payment amount cannot exceed due amount'));
              return false;
            }
            return true;
          });
          if (!isValid) {
            flag = false;
            setIsValidate(false);
          } else {
            setFormError('bills', '');
          }
        }
        break;
    }
    return flag;
  };

  const handlePayBills = async () => {
    try {
      const bills = formData.billPayments.flatMap(
        (payment) =>
          payment.vendors
            .flatMap((vendor) =>
              vendor.bills.map((bill) => ({
                billId: bill.id,
                bankAccountId: bill.bankAccountId || payment.bankAccountId,
                amount: bill.amount,
                paymentType: bill.paymentType || payment.paymentType,
                memo: bill.memo || '',
                paymentMethod: bill.paymentMethod || payment.paymentMethod || undefined,
                externalTransactionId: bill.externalTransactionId || '',
              }))
            )
            .filter((bill) => bill.amount !== 0) as MagicDoor.Api.PayVendorBillDto[]
      );

      const param: MagicDoor.Api.PayVendorBillsDto = {
        paymentDate: formData.paymentDate, // Use the paymentDate from formData
        bills,
      };

      console.log('Final Payload:', JSON.stringify(param, null, 2));

      const response = await payBill(param);
      setResponseData(response);
      toast.success(t('{name} has been added successfully', { name: t('Pay bills') }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const onStepChange = async (step: number, isBack?: boolean) => {
    if (step === 1 && !isBack) {
      needToUpdate() &&
        getVendorBillsPay({
          propertyIdOr: formData.properties.filter((item) => item),
          vendorIdOr: formData.vendors.filter((item) => item),
        });
      setNeedToUpdate(false);
    } else if (step === 2 && !isBack && currentStep() !== 2) {
      extractBills();
    }
    setCurrentStep(step);
  };

  createEffect(() => {
    const result: BillPayments[] = [];
    const data = store.bills ?? [];

    for (const bill of data) {
      const bisIndex = result.findIndex((item) => item.property?.id === bill.property?.id);
      const propertyId = bill.property?.id ?? '';

      if (bisIndex !== -1) {
        const vendorName = bill.vendor?.name;
        if (vendorName && !result[bisIndex].vendorName?.includes(vendorName)) {
          result[bisIndex].vendorName?.push(vendorName);
        }

        const bisVendorIndex = result[bisIndex].vendors?.findIndex((item) => item.id === bill.vendor?.id);
        if (bisVendorIndex !== -1 && !isUndefined(bisVendorIndex)) {
          (result[bisIndex].vendors as BillVendors[])[bisVendorIndex].bills?.push({
            ...bill,
            amount: bill.due,
            chartOfAccountName: bill.lineItems
              .map((item) => store.chartOfAccountMap?.get(item.chartOfAccountId ?? '') || item.chartOfAccountId)
              .filter(Boolean) as string[],
          });
        } else if (bill.vendor) {
          result[bisIndex].vendors?.push({
            ...bill.vendor,
            bills: [
              {
                ...bill,
                amount: bill.due,
                chartOfAccountName: bill.lineItems
                  .map((item) => store.chartOfAccountMap?.get(item.chartOfAccountId ?? '') || item.chartOfAccountId)
                  .filter(Boolean),
              },
            ],
          } as BillVendors);
        }
        result[bisIndex].paymentAmount = Number(result[bisIndex].paymentAmount || 0) + Number(bill.due || 0);
        result[bisIndex].propertyId = propertyId;
      } else {
        result.push({
          property: bill.property,
          propertyId: propertyId,
          vendorName: bill.vendor?.name ? [bill.vendor.name] : [],
          paymentAmount: bill.due,
          vendors: bill.vendor
            ? [
                {
                  ...bill.vendor,
                  bills: [
                    {
                      ...bill,
                      amount: bill.due,
                      chartOfAccountName: bill.lineItems
                        .map((item) => store.chartOfAccountMap?.get(item.chartOfAccountId ?? '') || item.chartOfAccountId)
                        .filter(Boolean),
                    },
                  ],
                } as BillVendors,
              ]
            : [],
          bills: [],
          paymentType: 'printCheck',
        });
      }
    }

    setFormData('billPayments', result);
  });

  createEffect(() => {
    switch (currentStep()) {
      case 0:
        stepValidator(1);
        break;
      case 1:
        stepValidator(2);
        break;
    }
  });

  onMount(() => {
    getChartOfAccounts();
    getBankAccounts();

    if (query.propertyId) {
      setFormData('isAllProperties', false);
      setFormData('properties', [query.propertyId]);
    }
    if (query.vendorId) {
      setFormData('isAllVendors', false);
      setFormData('vendors', [query.vendorId]);
    }
    if (query.propertyId || query.vendorId) {
      setCurrentStep(1);
      getVendorBillsPay({
        propertyIdOr: formData.properties.filter((item) => item),
        vendorIdOr: formData.vendors.filter((item) => item),
      });
      setNeedToUpdate(false);
    }
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <MultiStepsPanel
        stepper={
          <div class="flex items-center justify-center pb-10">
            <Stepper class="p-9" steps={steps()} step={currentStep()} />
          </div>
        }
        currentStep={currentStep()}
        showPrevious
        onClose={() => navigate(-1)}
        title={t('Pay bills')}
        disableNext={!isValidate()}
        successPage={<ReviewPrintForm payment={responseData()} />}
        submitText={t('Pay bills')}
        onSubmit={handlePayBills}
        onStepChange={(step, isBack) => onStepChange(step, isBack)}>
        <ChooseBillsForm store={store} form={formData} formError={formError} setFormData={setFormData} setNeedToUpdate={setNeedToUpdate} />
        <AllocationsForm store={store} form={formData} formError={formError} setFormData={setFormData} />
        <PaymentForm store={store} form={formData} formError={formError} setFormData={setFormData} />
      </MultiStepsPanel>
    </>
  );
};
