import { useNavigate, useParams } from '@solidjs/router';
import { createMemo, createSignal, For, Show, onMount } from 'solid-js';
import starIcon from '~/assets/images/chat/aiGenerateStar.png';
import learnIcon from '~/assets/images/dashboard/learnIcon.png';
import magicAiBg from '~/assets/images/units/listing/magic_ai_bg.png';
import Breadcrumb from '~/components/common/Breadcrumb';
import { AiButton } from '~/components/common/Buttons/AiButton';
import LabeledTextarea from '~/components/common/Inputs/LabeledTextArea';
import { Skeleton } from '~/components/ui';
import { IconCircleX } from '~/components/ui/Icons';
import { useLocalization, useUnitListings } from '~/contexts/global';
import { AiGeneratedListing } from './AiGeneratedListing';

export const ListingMagicAiPage = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { unitListingDetail, generateAiListing, updateUnitLising } = useUnitListings();
  const [prompt, setPrompt] = createSignal<string>();
  const [description, setDescription] = createSignal<string | undefined>();
  const params = useParams<{ listingId: `${number}` }>();
  const [loading, setLoading] = createSignal<boolean>(false);
  const [keywords, setKeywords] = createSignal<string[]>();
  const tagsWidth = ['w-9', 'w-52', 'w-12', 'w-32', 'w-16', 'w-12', 'w-20', 'w-16'];

  const breadcrumbItems = createMemo(() => [
    { label: t('Listing'), link: '/listing' },
    { label: unitListingDetail()?.title || t('Listing details'), link: `/listing/details/${unitListingDetail()?.id}` },
    { label: t('Magic AI') },
  ]);
  const handleUpdate = async () => {
    try {
      await updateUnitLising({ tags: keywords() }, params.listingId);
    } catch (e) {
      console.log(e);
    }
  };
  const handleDelTag = (tag: string) => {
    const newTags = keywords()?.filter((item) => item !== tag);
    setKeywords(newTags);
    handleUpdate();
  };
  const handleGenerate = async () => {
    try {
      setLoading(true);
      const res = await generateAiListing(params.listingId, { prompt: prompt() });
      setDescription(res.description);
      setKeywords(res.keywords);
      setPrompt();
      handleUpdate();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  onMount(() => handleGenerate());

  return (
    <>
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="my-5 px-8 text-white lg:flex-row">
        <div class="mb-2 rounded-lg bg-gradient-to-tl">
          <div
            style={{
              'background-image': `url(${magicAiBg})`,
              'background-position': 'center -70%',
              'background-repeat': 'no-repeat, no-repeat',
            }}>
            <div class="px-12 py-4 font-semibold">{t('Master listing')}</div>
            <div class="bg-light-pink px-12 py-4 text-text-level02">{t('Unleash the magic power of AI: customized your listing!')}</div>
            <div class="px-40 pb-10">
              <div class="mb-7 flex items-center pt-12">
                <img src={learnIcon} alt="learnIcon" />
                <h1 class="ml-4 text-xl font-medium">{t("Here is the data we've analyzed for your property!")}</h1>
              </div>
              {/* <h2 class="mb-3 text-base font-semibold capitalize">{t('Google map location analysis')}</h2>
              <div class="mb-6 text-xs font-medium leading-4">
                {t(
                  '0.5 miles from the Main Ave Subway Station, providing easy access to city-wide transit. Top-rated public school located 0.3 miles away. A major shopping center with grocery stores, retail shops, and restaurants is just 0.7 miles from the unit'
                )}
              </div>
              <h2 class="mb-3 text-base font-semibold capitalize">{t('Facts and amenities')}</h2>
              <div class="mb-6 text-xs font-medium leading-4">
                {t('Fitness Center,  Grill, Office, Clubhouse, Gated, Lounge, Pool, Dog Park')}
              </div> */}
              <Show
                when={keywords()?.length}
                fallback={
                  <div class="flex flex-wrap gap-2">
                    <For each={tagsWidth}>{(item) => <Skeleton class={item} />}</For>
                  </div>
                }>
                <h2 class="mb-3 text-base font-semibold capitalize">{t('AI-Driven insights from property photos')}</h2>
                <div class="flex flex-wrap gap-2">
                  <For each={keywords()}>
                    {(tag) => (
                      <div class="group/item flex items-center rounded-md bg-white px-3 py-1">
                        <span class="mr-1 text-xs text-essential-colour">{tag}</span>
                        <IconCircleX
                          class="cursor-pointer text-xs text-light-green group-hover/item:visible"
                          width={18}
                          onClick={() => handleDelTag(tag)}
                        />
                      </div>
                    )}
                  </For>
                </div>
              </Show>
              <div class="mb-4 mt-12 flex gap-2">
                <img src={starIcon} alt="starIcon" />
                <h3 class="text-sm">
                  {t('Before Magic-AI enhances your listing, is there any specific information you’d like to provide?')}
                </h3>
              </div>
              <div class="rounded-lg bg-white">
                <LabeledTextarea value={prompt()} rows={5} placeholder={t('Massage magic-ai')} onInput={(value) => setPrompt(value)} />
                <div class="flex justify-end p-2">
                  <AiButton text={loading() ? t('Generating...') : t('Generate')} onClick={handleGenerate} disabled={loading()} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <AiGeneratedListing description={description()} />
      </div>
    </>
  );
};
