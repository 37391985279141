import { useParams } from '@solidjs/router';
import { createEffect, createSignal, For, Show } from 'solid-js';
import IconDocument from '~/assets/images/units/listing/document.svg?component-solid';
import { FormItem, SegmentFormItem } from '~/components/common/BetterForm';
import { useFormContext } from '~/components/common/BetterForm/context';
import { Button } from '~/components/common/Buttons';
// import { DropdownMenu } from '~/components/common/DropdownMenu';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import { LabeledCheckbox } from '~/components/common/Inputs/LabeledCheckbox';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import LabeledSelect from '~/components/common/Inputs/LabeledSelect';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
// import LabeledAddressInput from '~/components/common/Inputs/form-inputs/LabeledAddressInput';
// import LabeledAddressInput from '~/components/common/Inputs/form-inputs/LabeledAddressInput';
import ExpandableSection from '~/components/common/expandables/ExpandableSection';
// import { propertyTypes } from '~/components/properties/PropertyType';
import { useLocalization, useUnitListings } from '~/contexts/global';
// import { useMaintenanceRequests } from '~/contexts/local';

type leaseOption = {
  label: string;
  value: string;
};
type CollapseProps = {
  key?: string;
  title: string;
  options: {
    label: string;
    value: boolean;
  }[];
  isOpen: boolean;
};
export const UnitListingsSection = () => {
  const { t } = useLocalization();
  const form = useFormContext();
  // const { maintenanceRequestCategory } = useMaintenanceRequests();
  const { unitListingDetail, getUnitListingsDetail } = useUnitListings();
  const params = useParams<{ listingId: `${number}` }>();
  const [collapseItems, setCollapseItems] = createSignal<CollapseProps[]>();
  const [isOpen, setIsOpen] = createSignal<boolean>(false);

  const leaseTypeOptions: leaseOption[] = [
    {
      label: t('monthToMonth'),
      value: 'monthToMonth',
    },
    {
      label: t('HalfYear'),
      value: 'halfYear',
    },
    {
      label: t('OneYear'),
      value: 'oneYear',
    },
    {
      label: t('TwoYears'),
      value: 'twoYears',
    },
    {
      label: t('ThreeYears'),
      value: 'threeYears',
    },
    {
      label: t('Other'),
      value: 'other',
    },
  ];
  let expandableRef: any;
  const progress = () => {
    let fieldsFilled = 0;
    if (form.formStore['Pet policy']) fieldsFilled++;
    if (form.formStore['Included utilities']) fieldsFilled++;
    if (form.formStore['Interior amenities']) fieldsFilled++;
    if (form.formStore['Outdoor amenities']) fieldsFilled++;
    if (form.formStore['Community amenities']) fieldsFilled++;
    return fieldsFilled / 5;
  };
  const unitProgress = () => {
    let fieldsFilled = 0;
    if (form.formStore.address?.streetAddress1) fieldsFilled++;
    if (form.formStore.address?.city) fieldsFilled++;
    if (form.formStore.address?.zipCode) fieldsFilled++;
    if (form.formStore.address?.state) fieldsFilled++;
    if (form.formStore.beds) fieldsFilled++;
    if (form.formStore.baths) fieldsFilled++;
    if (form.formStore.unitSizeSqft) fieldsFilled++;
    if (form.formStore.type) fieldsFilled++;
    return fieldsFilled / 8;
  };
  const termsProgress = () => {
    let fieldsFilled = 0;
    if (form.formStore.rentalPrice) fieldsFilled++;
    if (form.formStore.securityDeposit) fieldsFilled++;
    if (form.formStore.availableFrom) fieldsFilled++;
    if (form.formStore.leaseTerm) fieldsFilled++;
    return fieldsFilled / 4;
  };
  const checkboxMap = {
    petPolicy: [
      {
        label: 'Cats allowed',
        value: false,
      },
      {
        label: 'Large dogs allowed',
        value: false,
      },
      {
        label: 'Small dogs allowed',
        value: false,
      },
      {
        label: 'Customize',
        value: false,
      },
    ],
    includedUtilities: [
      {
        label: 'Water & sewer',
        value: false,
      },
      {
        label: 'Electricity',
        value: false,
      },
      {
        label: 'Gas',
        value: false,
      },
      {
        label: 'Trash',
        value: false,
      },
      {
        label: 'Internet/cable',
        value: false,
      },
      {
        label: 'Heating/air conditioning',
        value: false,
      },
    ],
    interiorAmenities: [
      {
        label: 'In-unit washer/dryer',
        value: false,
      },
      {
        label: 'Dishwasher',
        value: false,
      },
      {
        label: 'Microwave',
        value: false,
      },
      {
        label: 'Stainless steel appliances',
        value: false,
      },
      {
        label: 'Hardwood flooring',
        value: false,
      },
      {
        label: 'Central ac/heating',
        value: false,
      },
      {
        label: 'Walk-in closets',
        value: false,
      },
      {
        label: 'High-speed internet',
        value: false,
      },
      {
        label: 'Fireplace',
        value: false,
      },
    ],
    outdoorAmenities: [
      {
        label: 'Patio',
        value: false,
      },
      {
        label: 'BBQ grills or picnic area',
        value: false,
      },
      {
        label: 'Rooftop deck',
        value: false,
      },
      {
        label: 'Parking space',
        value: false,
      },
    ],
    communityAmenities: [
      {
        label: 'Gym',
        value: false,
      },
      {
        label: 'Swimming pool',
        value: false,
      },
      {
        label: 'Community clubhouse',
        value: false,
      },
      {
        label: 'Playground or dog park',
        value: false,
      },
      {
        label: 'Laundry facilities',
        value: false,
      },
      {
        label: 'Parking or garage',
        value: false,
      },
      {
        label: '24/7 security or controlled access',
        value: false,
      },
    ],
  };

  const tempCollapseItems = [
    {
      key: '1',
      title: 'Pet policy',
      options: checkboxMap.petPolicy,
      isOpen: false,
    },
    {
      key: '2',
      title: 'Included utilities',
      options: checkboxMap.includedUtilities,
      isOpen: false,
    },
    {
      key: '3',
      title: 'Interior amenities',
      options: checkboxMap.interiorAmenities,
      isOpen: false,
    },
    {
      key: '4',
      title: 'Outdoor amenities',
      options: checkboxMap.outdoorAmenities,
      isOpen: false,
    },
    {
      key: '5',
      title: 'Community amenities',
      options: checkboxMap.communityAmenities,
      isOpen: false,
    },
  ];
  const toggleCollapse = () => {
    setIsOpen(!isOpen());
    const items = collapseItems()?.map((item) => {
      return { ...item, isOpen: isOpen() };
    });
    setCollapseItems(items);
  };
  const getFieldGroup = (name: string | undefined) => {
    const groupMap = {
      'Pet policy': checkboxMap.petPolicy,
      'Included utilities': checkboxMap.includedUtilities,
      'Interior amenities': checkboxMap.interiorAmenities,
      'Outdoor amenities': checkboxMap.outdoorAmenities,
      'Community amenities': checkboxMap.communityAmenities,
    };
    for (const [key, value] of Object.entries(groupMap)) {
      const checkItem = value.find((item) => item.label === name);
      if (checkItem) return key;
    }
  };
  const handleFormSetFields = () => {
    // Facts and features
    const obj = {
      'Pet policy': [] as string[],
      'Included utilities': [] as string[],
      'Interior amenities': [] as string[],
      'Outdoor amenities': [] as string[],
      'Community amenities': [] as string[],
    };
    unitListingDetail()?.amenities?.forEach((item) => {
      if (item.avalability === 'available') {
        const key = getFieldGroup(item.name);
        if (key) obj[key as keyof typeof obj].push(item.name as string);
      }
    });
    if (unitListingDetail()?.petPolicy?.catsAllowed) obj['Pet policy'].push('Cats allowed');
    if (unitListingDetail()?.petPolicy?.largeDogsAllowed) obj['Pet policy'].push('Large dogs allowed');
    if (unitListingDetail()?.petPolicy?.smallDogsAllowed) obj['Pet policy'].push('Small dogs allowed');
    if (unitListingDetail()?.petPolicy?.description) obj['Pet policy'].push('Customize');

    form.setFieldsValue({
      address: unitListingDetail()?.property?.address,
      beds: unitListingDetail()?.unit?.beds,
      baths: unitListingDetail()?.unit?.baths,
      unitSizeSqft: unitListingDetail()?.unit?.unitSizeSqft,
      type: unitListingDetail()?.property?.type,
      rentalPrice: unitListingDetail()?.rentalPrice,
      securityDeposit: unitListingDetail()?.securityDeposit,
      availableFrom: unitListingDetail()?.availableFrom,
      leaseTerm: unitListingDetail()?.leaseTerm,
      'Pet policy': obj['Pet policy'] || [],
      'Included utilities': obj['Included utilities'] || [],
      'Interior amenities': obj['Interior amenities'] || [],
      'Outdoor amenities': obj['Outdoor amenities'] || [],
      'Community amenities': obj['Community amenities'] || [],
      petPolicyDesc: unitListingDetail()?.petPolicy?.description,
    });
  };
  createEffect(() => {
    setCollapseItems(tempCollapseItems as CollapseProps[]);
    getUnitListingsDetail(params.listingId);
    handleFormSetFields();
  });

  return (
    <>
      {/* <SegmentFormItem title={t('Unit information')} progress={unitProgress()} error={!form.isValidate} required>
        <div class="grid grid-cols-1 gap-7 gap-y-8 p-8">
          <FormItem
            class="col-span-full lg:col-span-1"
            label={t('Category')}
            rules={[{ required: true, message: t('Please select category') }]}
            options={maintenanceRequestCategory()?.map((item) => ({ value: item.id!, label: item.name! }))}
            formFieldName="categoryId"
            onChangeMethodName="onChange"
            component={DropdownMenu}
            placeholder={t('Please select')}
          />
        </div>
        <div class="grid grid-cols-1 gap-7 gap-y-8 p-8">
          <FormItem
            class="col-span-full lg:col-span-1"
            component={LabeledAddressInput}
            formFieldName="address"
            required
            disabled={true}
            rules={[{ required: true, message: t('Please enter') }]}
          />
        </div>
        <div class="grid grid-cols-2 gap-7 gap-y-8 px-8 py-0">
          <FormItem
            component={LabeledNumberInput}
            label={t('Bedroom')}
            disabled={true}
            rules={[
              {
                range: [0, 100],
                type: 'number',
                message: t('Bedroom must be between 0 and 100'),
              },
            ]}
            placeholder={t('Please enter')}
            formFieldName="beds"
            getValueFromEvent={(e) => +e}
            step={0.5}
          />
          <FormItem
            component={LabeledNumberInput}
            disabled
            rules={[
              {
                range: [0, 100],
                type: 'number',
                message: t('Bathroom must be between 0 and 100'),
              },
            ]}
            label={t('Bathroom')}
            placeholder={t('Please enter')}
            formFieldName="baths"
            getValueFromEvent={(e) => +e}
            step={0.5}
          />
        </div>
        <div class="grid grid-cols-2 gap-7 gap-y-8 p-8">
          <FormItem
            label={t('Unit size(sqft)')}
            placeholder={t('Please enter')}
            component={LabeledNumberInput}
            formFieldName="unitSizeSqft"
            disabled={true}
            rules={[
              {
                range: [0, 2147483647],
                type: 'int',
                message: t('Unit size must be between 0 and 10000'),
              },
            ]}
            getValueFromEvent={(e) => +e}
          />
          <FormItem
            class="col-span-full lg:col-span-1"
            label={t('Unit type')}
            disabled={true}
            rules={[{ required: true, message: t('Please select') }]}
            options={Object.keys(propertyTypes).map((item) => ({ value: item!, label: item! }))}
            formFieldName="type"
            onChangeMethodName="onChange"
            component={DropdownMenu}
            placeholder={t('Please select')}
          />
        </div>
      </SegmentFormItem> */}
      <SegmentFormItem title={t('Terms')} progress={termsProgress()} error={!form.isValidate}>
        <div class="grid grid-cols-2 gap-7 gap-y-8 p-8">
          <FormItem
            class="col-span-full lg:col-span-1"
            label={t('rent amount($)')}
            placeholder={t('Please enter the name')}
            component={LabeledNumberInput}
            formFieldName="rentalPrice"
            rules={[{ required: true, message: t('Please enter the name') }]}
          />
          <FormItem
            class="col-span-full lg:col-span-1"
            label={t('security deposit($)')}
            placeholder={t('Please enter the name')}
            component={LabeledNumberInput}
            formFieldName="securityDeposit"
          />
        </div>
        <div class="grid grid-cols-2 gap-7 gap-y-8 px-8 py-0">
          <FormItem
            class="col-span-full lg:col-span-1"
            formFieldName="availableFrom"
            label={t('Date avaliable')}
            component={DueDateInputField}
          />
          <FormItem
            class="col-span-full lg:col-span-1"
            formFieldName="leaseTerm"
            label={t('Lease type')}
            options={leaseTypeOptions}
            component={LabeledSelect}
          />
        </div>
      </SegmentFormItem>
      <SegmentFormItem title={t('Facts and features')} progress={progress()} error={!form.isValidate} required>
        <div class="p-8">
          <Button class="mb-2 w-fit px-2 text-sm" color="link" variant="text" onClick={toggleCollapse}>
            {isOpen() ? t('Close all options') : t('Expand to choose all options')}
          </Button>
          <For each={collapseItems()}>
            {(item) => (
              <ExpandableSection
                ref={expandableRef}
                headerBorder
                btnClass="mb-0"
                defaultOpen={item.isOpen}
                class="mb-2 rounded-lg border border-input-border px-5 text-base"
                title={
                  <div class="flex w-full items-center justify-between">
                    <span class="capitalize text-title-gray">{t(item.title)}</span>
                    <span class="flex cursor-default gap-2 rounded-full border border-essential-colour px-2 py-1 text-xs text-primary">
                      <IconDocument />
                      {form.formStore?.[item?.title]?.length || 0}
                    </span>
                  </div>
                }>
                <div class="p-2">
                  <FormItem formFieldName={item.title} component={LabeledCheckbox} options={item.options} />
                  <Show when={item.title === 'Pet policy'}>
                    <div class="grid grid-cols-2">
                      <Show when={form.formStore['Pet policy']?.includes('Customize')}>
                        <FormItem formFieldName="petPolicyDesc" component={LabeledTextInput} placeholder={t('Description')} />
                      </Show>
                    </div>
                  </Show>
                </div>
              </ExpandableSection>
            )}
          </For>
        </div>
      </SegmentFormItem>
    </>
  );
};
