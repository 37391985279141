import UnderConstruction from '~/components/common/UnderConstruction';
import ListingPlans from './ListingPlans';
import ListingUnitTypes from './ListingUnitTypes';
import type { Component } from 'solid-js';

export const PortfolioSummary: Component = () => (
  <div class="relative grid gap-5 xl:grid-cols-5">
    <ListingUnitTypes class="col-span-3" />
    <ListingPlans class="col-span-2" />
    <UnderConstruction />
  </div>
);
