import { createMemo, mergeProps } from 'solid-js';
import IconGID from '~/assets/images/rental-application/gid-trigger.svg?component-solid';
import IconPayStub from '~/assets/images/rental-application/paystub-trigger.svg?component-solid';
import { FormItem } from '~/components/common/BetterForm';
import { FileUploader } from '~/components/common/Upload/FileUploader';
import { useLocalization } from '~/contexts/global';
import { FileType } from '~/swagger/Api';
import type { Component } from 'solid-js';

export const RentalApplicationDocuments: Component<{
  appId?: string;
  files?: MagicDoor.Api.RentalApplicationFileDto[] | null;
  autoUpload?: boolean;
  ref?: any;
}> = (originProps) => {
  const { t } = useLocalization();

  let governmentIDRef!: any;
  let payStubRef!: any;

  const props = mergeProps(
    {
      autoUpload: true,
    },
    originProps
  );

  const uploadUrl = createMemo(() => (props.appId ? `/api/rental-applications/${props.appId}/files` : ''));

  function getInitialFile(type: FileType) {
    return props.files?.find((f) => f.type === type);
  }

  props.ref &&
    props.ref({
      async submit() {
        await governmentIDRef.submit();
        await payStubRef.submit();
      },
    });

  return (
    <div class="flex flex-wrap gap-5">
      <FormItem
        ref={governmentIDRef}
        label={t('Government ID')}
        component={FileUploader}
        trigger={
          <>
            <IconGID />
            <span>{t(`Drag & drop or click to add government ID`)}</span>
          </>
        }
        url={uploadUrl()}
        type={FileType.Identification}
        initialFile={getInitialFile(FileType.Identification) as any}
        accept="image/*"
        autoUpload={props.autoUpload}
      />
      <FormItem
        ref={payStubRef}
        label={t('Pay Stub')}
        component={FileUploader}
        trigger={
          <>
            <IconPayStub />
            <span>{t(`Drag & drop or click to add pay stub`)}</span>
          </>
        }
        url={uploadUrl()}
        type={FileType.EmploymentVerification}
        initialFile={getInitialFile(FileType.EmploymentVerification) as any}
        accept="image/*"
        autoUpload={props.autoUpload}
      />
    </div>
  );
};
