import { useNavigate } from '@solidjs/router';
import starIcon from '~/assets/images/chat/aiGenerateStar.png';
import magicColorBg from '~/assets/images/units/listing/magic_color_bg.png';
import magicStarPersonIcon from '~/assets/images/units/listing/magic_star_person.png';
import magicStarBg from '~/assets/images/units/listing/stars_bg.png';
import { LinkButton } from '~/components/common/Buttons';
import { Modal } from '~/components/modals/Modal';
import { IconRefresh } from '~/components/ui/Icons';
import { useLocalization } from '~/contexts/global';

export const MasterListing = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();

  return (
    <Modal
      visible
      onDone={() => null}
      doneText={t('Save')}
      class="capitalize lg:w-modalSm"
      title={t('Magic-AI master listing')}
      onCancel={() => {
        navigate(-1);
      }}>
      <div class="bg-gradient-to-tl px-8 pb-16 pt-10">
        <div
          style={{
            'background-image': `url(${magicStarBg})`,
            'background-position': 'left top',
            'background-repeat': 'no-repeat',
          }}>
          <div
            style={{
              'background-image': `url(${magicColorBg})`,
              'background-position': 'center -100px',
              'background-repeat': 'no-repeat',
            }}>
            <div class="text-center">
              <img src={magicStarPersonIcon} alt="learnIcon" class="m-auto" />
              <h1 class="my-4 text-2xl font-semibold capitalize text-white">
                {t('Unleash the magic power of')}
                <span class="text-[#FFDB7D]"> {t('AI listing!')}</span>
              </h1>
            </div>
            <div class="mb-1 flex gap-2 text-white">
              <img src={starIcon} alt="starIcon" />
              <h3 class="text-sm">
                {t('Before Magic-AI enhances your listing, is there any specific information you’d like to provide?')}
              </h3>
            </div>
            <div class="rounded-lg bg-white">
              <div class="h-112 overflow-y-auto px-4 py-3.5 text-sm leading-5 text-[#646671]">
                Experience luxurious living at 150 Main Ave, Unit 1023. This spacious 2-bedroom, 2-bath apartment features an open-concept
                design, modern kitchen with stainless steel appliances, and a private balcony with city views. Enjoy access to premium
                amenities such as a fitness center, swimming pool, and resident lounge. Ideally located near shopping, dining, and public
                transport, making urban living a breeze. Schedule your tour today and discover the magic of this exceptional home.
              </div>
              <div class="flex items-center gap-2 border-t border-input-border px-4 py-3.5">
                <span class="text-xs text-text-level03">{t('Not satisfied with the content?')}</span>
                <LinkButton href="" class="px-3 py-1 text-xs uppercase" rounded="full" variant="outlined">
                  <IconRefresh width={16} height={16} />
                  {t('Re-generate')}
                </LinkButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
