import { useNavigate, useSearchParams } from '@solidjs/router';
import { createMemo, createEffect, Show, createSignal, createResource } from 'solid-js';
import Breadcrumb from '~/components/common/Breadcrumb';
import { PrintButton } from '~/components/common/Buttons';
import DisplayAddress from '~/components/common/DisplayAddress';
import { PrintPageHeader } from '~/components/print';
import { formatTenantNames } from '~/components/tenants';
import { IconLoader } from '~/components/ui';
import { useLocalization, useCompanies } from '~/contexts/global';
import { useReports } from '~/contexts/local';
import { emptyPlaceholder } from '~/utils/constant';
import { PropertySelect } from './components/PropertySelect';
import { parseColumns, ReportTable } from './components/Report';
import type { LineItem } from './components/Report';
import type { Component } from 'solid-js';
import type { RentRollReportFilter } from '~/repositories/reportRepository';

const RentRollReport: Component<{ class?: string; report?: MagicDoor.Api.RentRollReportDto }> = (props) => {
  const { t } = useLocalization();

  const columns = () =>
    parseColumns([
      {
        name: 'BD/BA',
        type: 'string',
        id: 'bedroomsAndBathrooms',
      },
      {
        name: 'Tenant',
        type: 'string',
        id: 'tenantName',
      },
      {
        name: 'Status',
        type: 'string',
        id: 'status',
      },
      {
        name: 'Sqft',
        type: 'number',
        id: 'squareFeet',
      },
      { name: 'Rent', id: 'rent' },
      { name: 'Deposit', type: 'string', id: 'deposit' },
      { name: 'Lease from', type: 'date', id: 'leaseFrom' },
      { name: 'Move in', type: 'string', id: 'moveIn' },
    ]);

  const lines = createMemo(() => {
    if (!props.report || !props.report.groups) return [];

    const res = props.report.groups?.flatMap((group) => {
      const leases = group.items ?? [];

      let totalRent = 0;
      let totalDeposit = 0;
      let totalSquareFeet = 0;

      let occupiedPercentage = '--';

      if (group.items && group.property?.unitCount) {
        occupiedPercentage = Math.floor((group.items?.length / group.property?.unitCount) * 100) + '%';
      }

      return [
        {
          type: 'item',
          level: 0,
          label: <DisplayAddress address={group.property?.address} />,
        },
        ...leases.flatMap((lease) => {
          totalRent += lease.lease?.currentRent ?? 0;
          totalDeposit += lease.lease?.securityDepositPaid ?? 0;
          totalSquareFeet += lease.unit?.unitSizeSqft ?? 0;
          return [
            {
              type: 'item',
              label: lease.unit?.name,
              level: 0,
              values: {
                bedroomsAndBathrooms: `${lease.unit?.beds ?? emptyPlaceholder} / ${lease.unit?.baths ?? emptyPlaceholder}`,
                tenantName: formatTenantNames(lease.tenants?.map((item) => ({ tenant: item }))),
                status: lease.lease?.status,
                squareFeet: lease.unit?.unitSizeSqft,
                rent: lease.lease?.currentRent,
                deposit: lease.lease?.securityDepositPaid,
                leaseFrom: lease.lease?.start,
                moveIn: lease.lease?.tenants?.[0].moveIn,
              },
            },
          ];
        }),
        {
          type: 'total',
          level: 0,
          label: t('Total {count} units', { count: group.property?.unitCount + '' }),
          values: {
            status: t(`{percentage} occupied`, { percentage: occupiedPercentage }),
            squareFeet: totalSquareFeet,
            rent: totalRent,
            deposit: totalDeposit,
          },
        },
      ];
    }) as LineItem[];

    return res;
  });

  return <ReportTable class="[&_[data-level='1']]:!font-normal" label={t(`Unit`)} lines={lines() as any} columns={columns() as any} />;
};

export const RentRollReportPage = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { companies } = useCompanies();
  const [searchParams] = useSearchParams<
    Omit<RentRollReportFilter, 'portfolioIds' | 'propertyIds'> & { portfolioIds?: string; propertyIds?: string }
  >();

  const { rentRollReport, getRentRollReport, getFilterDisplay } = useReports();
  const [routeLevel, setRouteLevel] = createSignal<number>(-1);

  const [printContainer, setPrintContainer] = createSignal<HTMLDivElement>();

  const breadcrumbItems = createMemo(() => [{ label: t('Reports'), link: '/reports' }, { label: t('Rent roll') }]);

  createEffect(() => {
    const params: any = {};
    if (searchParams.propertyIds) params.propertyIds = searchParams.propertyIds.split(',');
    else if (searchParams.portfolioIds) params.portfolioIds = searchParams.portfolioIds.split(',');

    getRentRollReport(params);
    setRouteLevel((prevLevel) => prevLevel - 1);
  });

  const [filterDisplay] = createResource(
    () => [searchParams.propertyIds, searchParams.portfolioIds],
    async ([propertyIds, portfolioIds]) => {
      return await getFilterDisplay(propertyIds, portfolioIds);
    }
  );

  const printHeader = createMemo(() => {
    return (
      <div>
        <PrintPageHeader companies={companies()} />
        <div class="flex flex-col gap-1 py-6">
          <p class="text-center text-xl font-semibold leading-[30px] text-title-gray">{t('Rent roll report')}</p>
          <Show when={filterDisplay()?.portfoliosStr || filterDisplay()?.propertiesStr}>
            <p class="text-center text-lg font-medium text-title-gray">
              {filterDisplay()?.portfoliosStr || t('All')} - {filterDisplay()?.propertiesStr || t('All properties')}
            </p>
          </Show>
        </div>
      </div>
    );
  });

  const [printContainerWidth, setPrintContainerWidth] = createSignal<string>('100%');

  createEffect(() => {
    if (!rentRollReport.loading) {
      const clientWidth = printContainer()?.children[0]?.children[1]?.clientWidth || 0;
      const width = clientWidth > window.innerWidth ? `${clientWidth}px` : '100%';
      setPrintContainerWidth(width);
    }
  });

  return (
    <>
      <Breadcrumb backLink={() => navigate(routeLevel())} items={breadcrumbItems()} />
      <div class="m-8 rounded-lg border border-partingline bg-white p-8">
        <div class="flex justify-between">
          <h1 class="text-3xl font-semibold text-title-gray">{t('Rent roll report')}</h1>
          <PrintButton
            color="primary"
            variant="outlined"
            disabled={rentRollReport.loading}
            printHeader={printHeader() as Element}
            isReport={true}
            printWrapClass="w-auto"
            printContainer={printContainer()!}
            extraStyle={`width:${printContainerWidth()};zoom:0.4`}
          />
        </div>
        <div class="my-6 flex flex-wrap items-center gap-3">
          <PropertySelect />
        </div>
        <div ref={(e) => setPrintContainer(e)}>
          <Show when={rentRollReport.loading} fallback={<RentRollReport report={rentRollReport()} />}>
            <IconLoader class="mx-auto my-56 animate-spin" />
          </Show>
        </div>
      </div>
    </>
  );
};
