import { Button } from '~/components/common/Buttons';
import { useLocalization } from '~/contexts/global';
import usePresenter from '~/pdfsigner/hooks/usePresenter';
import useUseCase from '~/pdfsigner/hooks/useUseCase';
import { SignerListPresenter } from '~/pdfsigner/presenters/SignerListPresenter';
import { ToolbarPresenter } from '~/pdfsigner/presenters/ToolbarPresenter';
import { FontSizeSelector } from '~/pdfsigner/ui/components/toolbar/FontSizeSelector';
import { ChangeFontSizeUseCase } from '~/pdfsigner/usecases/changeFontSizeUseCase';
import { SelectSignerUseCase } from '~/pdfsigner/usecases/selectSignerUseCase';
import { SetAnnotationTypeUseCase } from '~/pdfsigner/usecases/setAnnotationTypeUseCase';
import { UpdateLeaseTemplateUseCase } from '~/pdfsigner/usecases/updateLeaseTemplateUseCase';
import { DataFieldsSelector } from './DataFieldsSelector';
import { SignersSelector } from './SignersSelector';

export interface LeaseDraftPreviewToolbarProps {
  onSave: () => void;
}

export const LeaseDraftPreviewToolbar = (props: LeaseDraftPreviewToolbarProps) => {
  const { t } = useLocalization();
  const { model: toolbar } = usePresenter(ToolbarPresenter);
  const { execute: changeFontSize } = useUseCase(ChangeFontSizeUseCase);
  const { model: signerList } = usePresenter(SignerListPresenter);
  const { execute: selectSigners } = useUseCase(SelectSignerUseCase);
  const { execute: setAnnotationType } = useUseCase(SetAnnotationTypeUseCase);
  const { execute: updateLeaseTemplate } = useUseCase(UpdateLeaseTemplateUseCase);

  return (
    <div class="thinscroll flex h-[calc(100%_-_16px)] w-[256px] shrink-0 flex-col gap-[18px] overflow-auto rounded-lg bg-white px-[28px] py-5">
      <FontSizeSelector options={toolbar()?.fontSizeOptions} value={toolbar()?.selectedFontSize} onInput={changeFontSize} />
      <SignersSelector isPmDisabled signerList={signerList()} onSelectSigner={(index) => selectSigners(index)} />
      <DataFieldsSelector
        selectedAnnotationType={toolbar()?.selectedAnnotationType}
        onChangeSelectedAnnotationType={setAnnotationType}
        dataPath={toolbar()?.dataPaths}
        selectedNode={toolbar()?.selectedNode}
        onUpdateDataPath={(dataPathKey) => updateLeaseTemplate({ dataPathKey })}
      />
      <Button class="mt-auto" variant="outlined" color="primary" block onClick={props.onSave}>
        {t('Save')}
      </Button>
    </div>
  );
};
