import DOMPurify from 'dompurify';
import { For, Show, createMemo, createSignal } from 'solid-js';
import IconAiRewrite from '~/assets/images/chat/aiRewrite.svg';
import IconFile from '~/assets/images/chat/chatFile.png';
import IconWorld from '~/assets/images/common/world.svg?component-solid';
import { ImagePreview } from '~/components/common/ImagePreview';
import { RingLoader } from '~/components/common/Loaders';
import { Image } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
import { useChat } from '~/contexts/local';
import { isImage, isVideo, isSvg, formatFileSize } from '~/utils/file';
import { makeLinksClickable } from '~/utils/tool';
import type { JSX } from 'solid-js';
import type { PreviewList } from '~/components/common/ImagePreview/type';

interface MessageBodyProps {
  message: MagicDoor.Api.ChatMessageDto;
  allMessage: MagicDoor.Api.ChatMessageDto[];
  showOriginal: boolean;
  toggleMessageView: () => void;
  chatId: string;
}

export const MessageBody = (props: MessageBodyProps) => {
  const { t } = useLocalization();
  const { rewriteMessage } = useChat();

  const [showDropdown, setShowDropdown] = createSignal<boolean>(false);
  const [isLoading, setIsLoading] = createSignal<boolean>(true);
  const [isModalOpen, setIsModalOpen] = createSignal<boolean>(false);
  const [selectedImageIndex, setSelectedImageIndex] = createSignal<number>(0);
  const [rewrittenMessage, setRewrittenMessage] = createSignal<string | null>(null);
  const [isHovered, setIsHovered] = createSignal<boolean>(false);
  const [showOriginalDropdown, setShowOriginalDropdown] = createSignal<boolean>(false);
  const [isRewriting, setIsRewriting] = createSignal<boolean>(false);

  const toggleDropdown = () => setShowDropdown((prev) => !prev);
  const toggleOriginalDropdown = () => setShowOriginalDropdown((prev) => !prev);
  const openFile = (url: string | URL | undefined) => window.open(url, '_blank');
  const isPropertyManager = () => props.message.messageType === 'propertyManager';
  const isPropertyManagerOrSystem = () => props.message.messageType !== 'participant';

  const renderTextWithLineBreaks = (text: string | null | undefined): JSX.Element | undefined => {
    const safeText = text ?? '';
    const lines = safeText.split(/\r?\n/);

    return (
      <Show when={text}>
        <For each={lines}>
          {(line) => (
            <div
              // eslint-disable-next-line solid/no-innerhtml
              innerHTML={DOMPurify.sanitize(makeLinksClickable(line), {
                ALLOWED_ATTR: ['href', 'target', 'class', 'rel'],
              })}
            />
          )}
        </For>
      </Show>
    );
  };

  const mediaFiles = createMemo(() =>
    props.allMessage.flatMap((item) => item.files).filter((file) => file != null && (isImage(file) || isVideo(file) || isSvg(file)))
  );

  const previewList = createMemo<PreviewList[]>(() => {
    return mediaFiles().map((item) => ({ url: `/api${item.fileUrl}`, type: isVideo(item) ? 'video' : 'img' }));
  });

  const openModal = (fileIndex: number) => {
    const mediaIndex = mediaFiles().findIndex((file) => file === props.message.files?.[fileIndex]);
    if (mediaIndex !== -1) {
      setSelectedImageIndex(mediaIndex);
      setIsModalOpen(true);
    }
  };

  const messageStyles = () => {
    switch (props.message.messageType) {
      case 'participant':
        return 'rounded-r-lg bg-inputbox-bg';
      case 'announcement':
        return 'bg-warning-light text-warning rounded-tl-lg';
      case 'bot':
        return 'bg-link/70 text-white rounded-tl-lg';
      default:
        return 'rounded-tl-lg bg-essential-colour text-white';
    }
  };

  const renderTranslationInfo = () => {
    const infoText = props.showOriginal ? t('AI translated to') : t('AI translated from');
    const language = props.showOriginal ? props.message.translatedMessageLanguage : props.message.messageLanguage;
    const textClass = isPropertyManager() || props.message.messageType === 'bot' ? 'text-white/80' : 'text-text-level03';

    return (
      <div class={`flex items-center justify-center gap-1 ${textClass}`}>
        <IconWorld />
        <div>{`${infoText} ${language}`}</div>
      </div>
    );
  };

  const handleAIRewrite = async () => {
    if (props.chatId && props.message.id) {
      setIsRewriting(true);
      try {
        const result = await rewriteMessage(props.chatId, props.message.id);
        if (result && result.suggestedText) {
          setRewrittenMessage(result.suggestedText);
          setShowOriginalDropdown(false);
        } else {
          console.error('Failed to rewrite message');
        }
      } catch (error) {
        console.error('Error rewriting message:', error);
      } finally {
        setIsRewriting(false);
      }
    }
  };

  return (
    <div class="relative pb-1" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div class={`${isPropertyManagerOrSystem() ? 'w-full' : 'md:min-w-[330px] '} `}>
        <div
          class={`${
            props.message.translatedMessage || rewrittenMessage() ? 'md:min-w-[330px]' : 'w-fit'
          } flex max-w-2xl flex-col gap-0.5 rounded-b-lg px-4 py-3 shadow ${messageStyles()}`}>
          <Show
            when={rewrittenMessage() || props.message.translatedMessage}
            fallback={<div class="break-words normal-case">{renderTextWithLineBreaks(props.message.message)}</div>}>
            <Show
              when={rewrittenMessage()}
              fallback={
                <Show
                  when={props.showOriginal || !props.message.translatedMessage}
                  fallback={<div class="break-words normal-case">{renderTextWithLineBreaks(props.message.translatedMessage)}</div>}>
                  <div class="break-words normal-case">{renderTextWithLineBreaks(props.message.message)}</div>
                </Show>
              }>
              <div class="break-words normal-case">{renderTextWithLineBreaks(rewrittenMessage())}</div>
            </Show>
            <div
              class={`mt-2 flex items-center justify-between border-t pt-2 text-xxs md:text-xs ${
                isPropertyManager() || props.message.messageType === 'bot' ? 'border-white/60' : 'border-parting-line'
              }`}>
              {rewrittenMessage() ? <div class="text-[#C66DF0]">{t('AI rewritten')}</div> : renderTranslationInfo()}
              <button class="flex items-center" onClick={rewrittenMessage() ? toggleOriginalDropdown : toggleDropdown}>
                {rewrittenMessage() ? (
                  <div class="text-[#C66DF0]">{showOriginalDropdown() ? t('Hide') : t('See original message')}</div>
                ) : props.showOriginal && props.message.translatedMessage ? (
                  <div class={`${props.message.messageType === 'announcement' ? 'text-text-level03' : 'text-faded'}`}>
                    {showDropdown() ? t('Hide') : t('See translated message')}
                  </div>
                ) : !props.showOriginal && props.message.translatedMessage ? (
                  <div class="text-[#C66DF0]">{showDropdown() ? t('Hide') : t('See original message')}</div>
                ) : null}
              </button>
            </div>

            <Show when={rewrittenMessage() ? showOriginalDropdown() : showDropdown()}>
              <div
                class={`mt-2 normal-case ${
                  isPropertyManager() || props.message.messageType === 'bot' ? 'text-white/80' : 'text-text-level03'
                }`}>
                {rewrittenMessage()
                  ? renderTextWithLineBreaks(props.message.message)
                  : props.showOriginal && props.message.translatedMessage
                  ? renderTextWithLineBreaks(props.message.translatedMessage)
                  : !props.showOriginal && props.message.translatedMessage
                  ? renderTextWithLineBreaks(props.message.message)
                  : null}
              </div>
            </Show>
          </Show>

          <Show when={props.message.translatedFailed === true && props.message.messageType === 'participant'}>
            <div class="mt-2 text-xxs text-warning md:text-xs">{t('AI translation failed: original message received')}</div>
          </Show>
          <Show when={props.message.translatedFailed === true && isPropertyManagerOrSystem()}>
            <div class={`mt-2 text-xxs ${props.message.messageType === 'announcement' ? 'text-text-level03' : 'text-warning'} md:text-xs`}>
              {t('AI translation failed: original message sent')}
            </div>
          </Show>

          <Show when={props.message.files && props.message.files?.length !== 0}>
            <div
              class={`flex size-full flex-col gap-2 rounded-lg${
                props.message.message || (props.message.translatedMessage && props.message.files) ? 'mt-4' : 'mt-0'
              }`}>
              <For each={props.message.files}>
                {(file, index) => (
                  <div class="flex">
                    <Show when={isImage(file as unknown as File) || isSvg(file)}>
                      <div class={`size-28 ${isLoading() ? 'skeleton-shimmer' : ''}`}>
                        <Image
                          class="size-full cursor-pointer rounded-lg bg-[#F8EFE4] object-cover"
                          classList={{ 'p-2': isSvg(file) }}
                          src={`/api${file.fileUrl}`}
                          alt={`Attachment ${index()}`}
                          onLoad={() => setIsLoading(false)}
                          onClick={() => openModal(index())}
                        />
                      </div>
                    </Show>
                    <Show when={isVideo(file)}>
                      <video class="max-h-40 max-w-xs rounded-lg" src={`/api${file.fileUrl}`} controls />
                    </Show>
                    <Show when={!isImage(file) && !isVideo(file)}>
                      <div
                        class="flex w-40 cursor-pointer items-center justify-start gap-3 rounded-lg border bg-white p-2 md:h-18 md:w-72"
                        onClick={() => openFile(`/api${file.fileUrl}`)}>
                        <div class="relative md:h-14 md:w-18">
                          <img src={IconFile} class="object-cover md:h-14 md:w-18" />
                          <div class="absolute inset-0 flex items-center justify-center text-xs font-bold uppercase text-white">
                            {file.fileExtension}
                          </div>
                        </div>
                        <div class="flex w-full flex-col justify-center gap-1 overflow-hidden">
                          <div class="truncate text-sm text-text-level02">{file.fileName}</div>
                          <div class="flex gap-1 text-text-level03">
                            <div class="text-xs uppercase">{file.fileExtension}</div>
                            <div>{formatFileSize(file.fileSize)}</div>
                          </div>
                        </div>
                      </div>
                    </Show>
                  </div>
                )}
              </For>
            </div>
          </Show>
          <ImagePreview previewList={previewList()} initIndex={selectedImageIndex()} visible={[isModalOpen, setIsModalOpen]} />
        </div>
      </div>
      <Show when={props.message.messageType === 'participant' && !rewrittenMessage()}>
        <Show
          when={isRewriting()}
          fallback={
            <Show when={isHovered()}>
              <button
                class="absolute bottom-0 left-0 flex translate-y-full items-center gap-1 rounded px-2 py-1 text-xs text-text-level03 hover:bg-input/50"
                onClick={handleAIRewrite}>
                <img src={IconAiRewrite} />
                {t('AI rewrite')}
              </button>
            </Show>
          }>
          <div class="absolute bottom-0 left-0 flex translate-y-full items-center gap-1 rounded px-2 py-1 text-xs text-text-level03">
            <RingLoader size={16} color="#A126EC" borderWidth={2} />
            {t('AI rewriting')}...
          </div>
        </Show>
      </Show>
    </div>
  );
};
