import { useNavigate } from '@solidjs/router';
import { createEffect, createSignal, createMemo, Show } from 'solid-js';
import { SendEmail } from '~/components/common/SendEmail';
import { Html, ListView, DynamicHtml } from '~/components/ui';
import { useEmails, useLocalization, useVendors, useOwners, useTenants } from '~/contexts/global';
import { dateFormat } from '~/utils/date';
import type { TableColumns } from '~/components/ui';
import type { ListViewArgs } from '~/components/ui/ListView';
import type { EmailFilter } from '~/repositories/emailRepository';

type EmailsTabProps = {
  id: string;
  type: 'lease' | 'owner' | 'tenant' | 'vendor';
};

export const EmailsListWithType = (props: EmailsTabProps) => {
  const { t } = useLocalization();
  const { getLeaseEmails, getOwnerEmails, getTenantEmails, getVendorEmails } = useEmails();
  const navigate = useNavigate();

  const [emailResponse, setEmailResponse] = createSignal<MagicDoor.Api.EmailsDto | null>(null);
  const [loading, setLoading] = createSignal<boolean>(true);
  const [filter, setFilter] = createSignal<EmailFilter>({});
  const [propertyManagers, setPropertyManagers] = createSignal<MagicDoor.Api.PropertyManagerDto[]>([]);
  const { current } = useVendors();
  const { current: ownerCurrent } = useOwners();
  const { current: tenantCurrent } = useTenants();
  const [sendDisabled, setSendDisabled] = createSignal<boolean>(false);

  const fetchEmails = async (args: ListViewArgs<EmailFilter>) => {
    setLoading(true);
    try {
      let result;
      let emailIsHave = true;
      switch (props.type) {
        case 'lease':
          result = await getLeaseEmails(props.id, args);
          break;
        case 'owner':
          result = await getOwnerEmails(props.id, args);
          emailIsHave = !!ownerCurrent()?.email;
          break;
        case 'tenant':
          result = await getTenantEmails(props.id, args);
          emailIsHave = !!tenantCurrent()?.email;
          break;
        case 'vendor':
          result = await getVendorEmails(props.id, args);
          emailIsHave = !!current()?.contact?.email;
          break;
        default:
          throw new Error(`Invalid type: ${props.type}`);
      }
      if (result.propertyManagers) setPropertyManagers(result.propertyManagers);
      setEmailResponse(result);
      setSendDisabled(!emailIsHave);
    } finally {
      setLoading(false);
    }
  };

  createEffect(() => {
    fetchEmails({ filter: filter(), page: 1, pageSize: 10 });
  });

  const columns = createMemo<TableColumns<MagicDoor.Api.EmailListDto>>(() => [
    {
      title: t('Recipients'),
      headerClass: 'w-[20%]',
      render: (email) => (
        <div class="w-full max-w-52 overflow-hidden truncate text-sm text-text-level01">
          {email.targets?.map((target) => target.email).join(', ')}
        </div>
      ),
    },
    {
      title: t('Subject'),
      headerClass: 'w-[10%]',
      render: (email) => <div class="text-sm font-bold text-text-level01">{email.subject}</div>,
    },
    {
      title: t('Body'),
      headerClass: 'w-[20%]',
      render: (email) => (
        <Show when={typeof email.body === 'string' && email.body} fallback="--">
          <DynamicHtml as="div" class="line-clamp-2 overflow-hidden text-ellipsis text-sm text-text-level01">
            {email.body}
          </DynamicHtml>
        </Show>
      ),
    },
    {
      title: t('Sent at'),
      headerClass: 'w-[20%]',
      render: (email) => <div class="text-sm text-text-level01">{dateFormat('', email.sent)}</div>,
    },
    {
      title: t('Property manager'),
      headerClass: 'w-[30%]',
      render: (row) => {
        const propertyManager = propertyManagers().find((item: MagicDoor.Api.PropertyManagerDto) => item.id === row.propertyManagerId);
        return propertyManager ? `${propertyManager?.lastName} ${propertyManager?.firstName}` : '';
      },
    },
  ]);

  const handleChange = (args: ListViewArgs<EmailFilter>) => {
    setFilter(args.filter);
    fetchEmails(args);
  };

  const getTitle = () => {
    switch (props.type) {
      case 'lease':
        return t('Lease emails');
      case 'owner':
        return t('Owner emails');
      case 'tenant':
        return t('Tenant emails');
      case 'vendor':
        return t('Vendor emails');
    }
  };

  const handleRowClick = (item: MagicDoor.Api.EmailDto) => {
    const emailId = item.id;
    if (props.type === 'owner') {
      navigate(`/users/${props.type}s/${props.id}/emails/${emailId}`);
    } else if (props.type === 'lease') {
      navigate(`/leasing/${props.type}s/${props.id}/emails/${emailId}`);
    } else if (props.type === 'tenant') {
      navigate(`/users/${props.type}s/${props.id}/emails/${emailId}`);
    } else if (props.type === 'vendor') {
      navigate(`/maintenance/${props.type}s/${props.id}/emails/${emailId}`);
    } else {
      console.error('Email ID is undefined');
    }
  };

  const handleSend = () => {
    fetchEmails({ filter: filter(), page: 1, pageSize: 10 });
  };

  return (
    <ListView
      title={getTitle()}
      data={emailResponse()?.items ?? []}
      columns={columns()}
      loading={loading()}
      page={emailResponse()?.totalPages ?? 1}
      onRowClick={handleRowClick}
      pageSize={emailResponse()?.pageSize ?? 10}
      totalPages={emailResponse()?.totalPages ?? 1}
      onChange={handleChange}
      titleActions={
        <div>
          <SendEmail
            type={props.type}
            id={props.id}
            text={'Send ' + getTitle()}
            showModal
            onSendDone={handleSend}
            disabled={sendDisabled()}
          />
        </div>
      }
      footerSummary={
        <Html as="p" class="flex-1 text-xs text-text-level03 [&>b]:mx-1 [&>b]:font-bold [&>b]:text-essential-colour">
          {t('A total of <b>{count}</b> emails', {
            count: loading() ? '-' : emailResponse()?.totalCount?.toString(),
          })}
        </Html>
      }
    />
  );
};
