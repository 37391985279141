import { useNavigate, useParams } from '@solidjs/router';
import { createMemo } from 'solid-js';
import { SegmentForm, useForm, SegmentFormScrollSpy } from '~/components/common/BetterForm';
import Breadcrumb from '~/components/common/Breadcrumb';
import { Button } from '~/components/common/Buttons';
import { toast } from '~/components/ui';
import { useLocalization, useUnitListings } from '~/contexts/global';
import { MaintenanceRequestsProvider } from '~/contexts/local';
import { UnitListingsSection } from './components/UnitListingsSection';

export const EditUnit = () => {
  const { t } = useLocalization();
  const navigate = useNavigate();
  const { unitListingDetail, updateUnitLising } = useUnitListings();
  const params = useParams<{ listingId: `${number}` }>();
  const form = useForm();
  const breadcrumbItems = createMemo(() => [
    { label: t('Listing'), link: '/listing' },
    { label: unitListingDetail()?.title || t('Listing details'), link: `/listing/details/${unitListingDetail()?.id}` },
    { label: t('Edit unit') },
  ]);
  const formatPetPolicy = (petPolicy: string[] | undefined, petPolicyDesc: string | undefined) => {
    if (!petPolicy) {
      return {
        catsAllowed: false,
        description: '',
        largeDogsAllowed: false,
        smallDogsAllowed: false,
      };
    } else {
      return {
        catsAllowed: petPolicy.includes('Cats allowed'),
        description: petPolicy.includes('Customize') ? petPolicyDesc : '',
        largeDogsAllowed: petPolicy.includes('Large dogs allowed'),
        smallDogsAllowed: petPolicy.includes('Small dogs allowed'),
      };
    }
  };
  const formatAmenities = (values: any) => {
    let amenityArr: any = [];
    const newAmenity: MagicDoor.Api.AmenityDto[] = [];
    if (values['Included utilities']) amenityArr = [...amenityArr, ...values['Included utilities']];
    if (values['Interior amenities']) amenityArr = [...amenityArr, ...values['Interior amenities']];
    if (values['Outdoor amenities']) amenityArr = [...amenityArr, ...values['Outdoor amenities']];
    if (values['Community amenities']) amenityArr = [...amenityArr, ...values['Community amenities']];

    amenityArr.forEach((item: MagicDoor.Api.AmenityDto) => {
      newAmenity.push({
        name: item + '',
        icon: 'test',
        avalability: 'available' as MagicDoor.Api.AmenityAvalability.Available,
      });
    });
    return newAmenity;
  };

  const submit = async () => {
    const formValues = form.formStore;
    try {
      const param = {} as MagicDoor.Api.UpdateUnitListingDto;
      param.rentalPrice = formValues.rentalPrice;
      param.securityDeposit = formValues.securityDeposit;
      param.availableFrom = formValues.availableFrom;
      param.leaseTerm = formValues.leaseTerm;
      param.petPolicy = formatPetPolicy(formValues['Pet policy'], formValues?.petPolicyDesc);
      param.amenities = formatAmenities(formValues);
      await updateUnitLising(param, params.listingId);
      toast.success('Success');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div class="relative">
      <Breadcrumb backLink={() => navigate(-1)} items={breadcrumbItems()} />
      <div class="my-5 px-8">
        <SegmentForm defaultForm={form} onFormSubmit={submit} class="flex h-section flex-1 flex-col overflow-hidden">
          <div class="flex flex-1 overflow-hidden">
            <div class="thinscroll relative hidden w-72 border-r border-input-border bg-white p-6 text-text-level03 xs:block">
              <div class="text-lg font-semibold text-text-level01">{t('Edit unit listing')}</div>
              <SegmentFormScrollSpy />
            </div>
            <div class="thinscroll h-full flex-1 overflow-auto bg-white">
              <MaintenanceRequestsProvider>
                <UnitListingsSection />
              </MaintenanceRequestsProvider>
            </div>
          </div>
          <div class="flex gap-2 border-t border-input-border bg-white px-6 py-5">
            <Button class="ml-auto" variant="outlined" onClick={() => navigate(-1)}>
              {t('Cancel')}
            </Button>
            <Button type="submit">{t('Save changes')}</Button>
          </div>
        </SegmentForm>
      </div>
    </div>
  );
};
