import AccountingIcon from '~/assets/images/sidebar/accounting.svg';
import AccountingActiveIcon from '~/assets/images/sidebar/accountingActive.svg';
import IconAssociation from '~/assets/images/sidebar/association.svg';
import IconAssociationActive from '~/assets/images/sidebar/associationActive.svg';
import CommunicationsIcon from '~/assets/images/sidebar/communications.svg';
import CommunicationsActiveIcon from '~/assets/images/sidebar/communicationsActive.svg';
import IconHome from '~/assets/images/sidebar/home.svg';
import IconHomeActive from '~/assets/images/sidebar/homeActive.svg';
import IconMaintenance from '~/assets/images/sidebar/maintenance.svg';
import IconMaintenanceActive from '~/assets/images/sidebar/maintenanceActive.svg';
import IconLeasing from '~/assets/images/sidebar/paper.svg';
import IconLeasingActive from '~/assets/images/sidebar/paperActive.svg';
import IconProperty from '~/assets/images/sidebar/property.svg';
import IconPropertyActive from '~/assets/images/sidebar/propertyActive.svg';
import IconReports from '~/assets/images/sidebar/reports.svg';
import IconReportsActive from '~/assets/images/sidebar/reportsActive.svg';
import IconSettings from '~/assets/images/sidebar/settings.svg';
import IconSettingsActive from '~/assets/images/sidebar/settingsActive.svg';
import IconUsers from '~/assets/images/sidebar/users.svg';
import IconUsersActive from '~/assets/images/sidebar/usersActive.svg';
import { useLocalization } from '~/contexts/global';
import type { MenuItem } from './types/menuItem';
export const getMenuItems = (): MenuItem[] => {
  const { t } = useLocalization();
  return [
    { id: 'Home', text: t('Home'), path: '/', canNaviate: true, icon: IconHome, selectedIcon: IconHomeActive },
    {
      id: 'Properties',
      text: t('Properties'),
      path: '/portfolios',
      canNaviate: true,
      icon: IconProperty,
      selectedIcon: IconPropertyActive,
    },
    // {
    //   id: 'Listing',
    //   text: t('Listing'),
    //   path: '/listing',
    //   canNaviate: true,
    //   icon: IconListing,
    //   selectedIcon: IconListingActive,
    // },
    {
      id: 'Leasing',
      text: t('Leasing'),
      icon: IconLeasing,
      selectedIcon: IconLeasingActive,
      path: '/leasing',
      canNaviate: false,
      subMenuItems: [
        {
          id: 'Leases',
          text: t('Leases'),
          canNaviate: true,
          path: '/leasing/leases',
        },
        {
          id: 'Lease templates',
          text: t('Lease templates'),
          canNaviate: true,
          path: '/leasing/lease-templates',
        },
        {
          id: 'Rental application',
          text: t('Rental applications'),
          canNaviate: true,
          path: '/leasing/rental-applications',
        },
        {
          id: 'Draft rental applications',
          text: t('Draft rental applications'),
          canNaviate: true,
          path: '/leasing/draft-rental-applications',
        },
      ],
    },
    {
      id: 'Users',
      text: t('Users'),
      icon: IconUsers,
      selectedIcon: IconUsersActive,
      path: '/users',
      canNaviate: false,
      subMenuItems: [
        {
          id: 'Property managers',
          text: t('Property managers'),
          canNaviate: true,
          path: '/users/property-managers',
        },
        {
          id: 'Tenants',
          text: t('Tenants'),
          canNaviate: true,
          path: '/users/tenants',
        },
        {
          id: 'Owners',
          text: t('Owners'),
          canNaviate: true,
          path: '/users/owners',
        },
      ],
    },
    {
      id: 'Accounting',
      text: t('Accounting'),
      icon: AccountingIcon,
      selectedIcon: AccountingActiveIcon,
      path: '/accounting',
      canNaviate: false,
      subMenuItems: [
        {
          id: 'Bank accounts',
          text: t('Bank accounts'),
          canNaviate: true,
          path: '/accounting/bank-accounts',
        },
        {
          id: 'Bills',
          text: t('Bills'),
          canNaviate: true,
          path: '/accounting/bills',
        },
        {
          id: 'Chart of accounts',
          text: t('Chart of accounts'),
          canNaviate: true,
          path: '/accounting/chart-of-accounts',
        },

        {
          id: 'Company revenue',
          text: t('Company revenue'),
          canNaviate: true,
          path: '/accounting/company-revenue',
        },
        {
          id: 'Owner Funds',
          text: t('Owner funds'),
          canNaviate: true,
          path: '/accounting/owner-funds',
          realPath: '/accounting/owner-funds/distributions',
        },
        {
          id: 'Deposit slips',
          text: t('Deposit slips'),
          canNaviate: true,
          path: '/accounting/deposit-slips',
        },
        {
          id: 'Checks',
          text: t('Checks'),
          canNaviate: true,
          path: '/accounting/checks',
        },
      ],
    },
    {
      id: 'Reports',
      text: t('Reports'),
      path: '/reports',
      canNaviate: true,
      icon: IconReports,
      selectedIcon: IconReportsActive,
    },
    {
      id: 'Maintenance',
      text: t('Maintenance'),
      icon: IconMaintenance,
      selectedIcon: IconMaintenanceActive,
      path: '/maintenance',
      canNaviate: false,
      subMenuItems: [
        {
          id: 'Vendors',
          text: t('Vendors'),
          canNaviate: true,
          path: '/maintenance/vendors',
        },
        {
          id: 'Maintenance request',
          text: t('Maintenance requests'),
          canNaviate: true,
          path: '/maintenance/maintenance-request',
        },
        {
          id: 'Work orders',
          text: t('Work orders'),
          canNaviate: true,
          path: '/maintenance/work-orders',
        },
        {
          id: 'AI runbooks',
          text: t('AI runbooks'),
          canNaviate: true,
          path: '/maintenance/ai-runbooks',
        },
      ],
    },
    {
      id: 'Associations',
      text: t('Associations'),
      icon: IconAssociation,
      selectedIcon: IconAssociationActive,
      path: '/associations',
      canNaviate: false,
      subMenuItems: [
        {
          id: 'Violations',
          text: t('Violations'),
          canNaviate: true,
          path: '/associations/violations',
        },
      ],
    },
    {
      id: 'Communications',
      text: t('Communications'),
      icon: CommunicationsIcon,
      selectedIcon: CommunicationsActiveIcon,
      path: '/communications',
      canNaviate: false,
      subMenuItems: [
        {
          id: 'Dashboard',
          text: t('Dashboard'),
          canNaviate: true,
          path: '/communications/dashboard',
        },
        {
          id: 'Chats',
          text: t('Chats'),
          canNaviate: true,
          path: '/communications/chats',
        },
        {
          id: 'Announcements',
          text: t('Announcements'),
          canNaviate: true,
          path: '/communications/announcements',
        },
      ],
    },
    {
      id: 'Settings',
      text: t('Settings'),
      path: '/settings',
      replace: '/settings/account/information',
      canNaviate: true,
      icon: IconSettings,
      selectedIcon: IconSettingsActive,
    },
  ];
};
