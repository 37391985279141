import { Show } from 'solid-js';
import AllPropertiesIcon from '~/assets/images/bill/allProperties.svg';
import AllVendorsIcon from '~/assets/images/bill/allVendors.svg';
import SomePropertiesIcon from '~/assets/images/bill/someProperties.svg';
import SomeVendorsIcon from '~/assets/images/bill/someVendors.svg';
import { MutiSelect } from '~/components/common/MutiSelect';
import { OptionButton } from '~/components/common/OptionButton';
import { LabeledPropertySearch, PropertyTitle } from '~/components/properties';
import { LabeledVendorSearch } from '~/components/vendors';
import { VendorTitle } from '~/components/vendors/VendorTitle';
import { useLocalization } from '~/contexts/global';
import type { Component } from 'solid-js';
import type { PayBillsSubForm } from '~/pages/bills/pay-bill/types';

const ChooseBillsForm: Component<PayBillsSubForm> = (props) => {
  const { t } = useLocalization();

  const onPropertiesChange = (val: string[]) => {
    props.setFormData('properties', val);
    props.setNeedToUpdate?.(true);
  };

  const onVendorsChange = (val: string[]) => {
    props.setFormData('vendors', val);
    props.setNeedToUpdate?.(true);
  };

  return (
    <div class="mx-auto flex w-10/12 flex-col gap-5">
      <div>
        <h4 class="border-b border-partingline py-3 text-base font-semibold">{t('Properties')}</h4>
        <div class="grid grid-cols-2 gap-4 py-4">
          <OptionButton
            class="h-20"
            Icon={AllPropertiesIcon}
            label={t('All properties')}
            isActive={props.form.isAllProperties}
            onClick={() => {
              props.setFormData('isAllProperties', true);
              props.setFormData('properties', []);
              props.setNeedToUpdate && props.setNeedToUpdate(true);
            }}
          />
          <OptionButton
            class="h-20"
            Icon={SomePropertiesIcon}
            label={t('Some properties')}
            isActive={!props.form.isAllProperties}
            onClick={() => {
              props.setFormData('isAllProperties', false);
              props.setFormData('properties', ['']);
              props.setNeedToUpdate && props.setNeedToUpdate(true);
            }}
          />
          <Show when={!props.form.isAllProperties}>
            <MutiSelect<MagicDoor.Api.HydratedPropertyDto>
              as={LabeledPropertySearch}
              items={props.form.properties}
              onMutiSelectInput={(val) => onPropertiesChange(val)}
              addButtonText={t('Add a new property')}
              renderSelectedItem={(item) => <PropertyTitle property={item} />}
            />
          </Show>
        </div>
      </div>
      <div>
        <h4 class="border-b border-partingline py-3 text-base font-semibold">{t('Vendors')}</h4>
        <div class="grid grid-cols-2 gap-4 py-4">
          <OptionButton
            class="h-20"
            Icon={AllVendorsIcon}
            label={t('All vendors')}
            isActive={props.form.isAllVendors}
            onClick={() => {
              props.setFormData('isAllVendors', true);
              props.setFormData('vendors', []);
              props.setNeedToUpdate && props.setNeedToUpdate(true);
            }}
          />
          <OptionButton
            class="h-20"
            Icon={SomeVendorsIcon}
            label={t('Some vendors')}
            isActive={!props.form.isAllVendors}
            onClick={() => {
              props.setFormData('isAllVendors', false);
              props.setFormData('vendors', ['']);
              props.setNeedToUpdate && props.setNeedToUpdate(true);
            }}
          />
          <Show when={!props.form.isAllVendors}>
            <MutiSelect<MagicDoor.Api.HydratedVendorDto>
              as={LabeledVendorSearch}
              items={props.form.vendors}
              onMutiSelectInput={(val) => onVendorsChange(val)}
              addButtonText={t('Add a new vendor')}
              renderSelectedItem={(item) => <VendorTitle vendor={item} />}
            />
          </Show>
        </div>
      </div>
    </div>
  );
};

export default ChooseBillsForm;
