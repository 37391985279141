import { Show } from 'solid-js';
import IconClose from '~/assets/images/common/close.svg?component-solid';
import IconMagicSearch from '~/assets/images/common/magicSearch.png';
import { IconSearch } from '~/components/ui';
import { useLocalization } from '~/contexts/global';
interface MagicSearchInputProps {
  dropdownVisible: () => boolean;
  searchTerm: string;
  handleSearchChange: (e: InputEvent) => void;
  handleKeyDown: (e: KeyboardEvent) => void;
  handleFocus: () => void;
  handleClear: () => void;
}

export const MagicSearchInput = (props: MagicSearchInputProps) => {
  const { t } = useLocalization();

  return (
    <form>
      <div
        class={`absolute inset-0 ${
          props.dropdownVisible() ? 'rounded-t-lg bg-input-border' : 'rounded-full bg-gradient-to-r from-[#A139FC] to-[#6EACFE]'
        } `}
      />
      <div class="relative p-[2px]">
        <div class="absolute left-2 top-1/2 z-10 -translate-y-1/2">
          <Show when={props.dropdownVisible()} fallback={<img src={IconMagicSearch} class="size-8" onClick={props.handleFocus} />}>
            <span class="flex size-8 items-center justify-center">
              <IconSearch class="size-5 text-gray-500" />
            </span>
          </Show>
        </div>

        <input
          type="text"
          class={`w-full px-3 py-2.5 text-primary focus:outline-none sm:pl-12  ${
            props.dropdownVisible() ? 'rounded-t-lg pl-12 pr-14' : 'rounded-full'
          }`}
          value={props.searchTerm}
          onInput={(e) => props.handleSearchChange(e)}
          onKeyDown={(e) => props.handleKeyDown(e)}
          onFocus={() => props.handleFocus()}
          placeholder={t('Magic search...')}
        />

        <Show when={props.searchTerm && props.dropdownVisible()}>
          <div class="absolute right-2 top-1/2 z-10 -translate-y-1/2">
            <button
              type="button"
              onClick={() => props.handleClear()}
              class="rounded-full border border-input-border p-1 focus:outline-none">
              <IconClose class="size-5 text-text-level03" />
            </button>
          </div>
        </Show>
      </div>
    </form>
  );
};
