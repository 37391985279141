import { SelectAnnotationUseCase } from './selectAnnotationUseCase';
import { AnnotationType } from './types/annotation';
import { UseCase } from './useCase';
import type { Annotation } from './types/annotation';
import type { AnnotationCreationRequest } from './types/annotationCreationRequest';
import type { BoundingBox } from './types/boundingBox';
import type { Position } from './types/position';

export class CreateAnnotationUseCase extends UseCase {
  public async runLogic(request: AnnotationCreationRequest) {
    let isEditing = this.state.isEditing;
    if (this.state.selectedAnnotationType === undefined) {
      isEditing = false;
    }
    if (!request || !this.shouldCreateAnnotation(request)) {
      return;
    }
    const annotation = this.createNewAnnotationFromRequest(request);
    this.state.selectedDocument?.pdfPages[request.page].annotations.push(annotation);
    this.state.isEditing = isEditing;
    const selectAnnotationUseCase = new SelectAnnotationUseCase();
    await selectAnnotationUseCase.execute({ id: annotation.id, page: request.page });
    this.state.selectedAnnotationType = undefined;
  }

  private shouldCreateAnnotation(request: AnnotationCreationRequest): boolean {
    if (!request || !this.state.selectedDocument) {
      return false;
    }
    const pageBounds = request.relativePosition.boundingBox;
    const mousePosition = request.relativePosition.position;
    return (
      this.isPdfLoaded() &&
      this.state.selectedAnnotationType != undefined &&
      request.page >= 0 &&
      request.page < this.state.selectedDocument.pdfPages.length &&
      this.isMousePositionInsidePageBounds(mousePosition, pageBounds) &&
      pageBounds.width > 0 &&
      pageBounds.height > 0
    );
  }

  private isMousePositionInsidePageBounds(mousePosition: Position, pageBounds: BoundingBox): boolean {
    return (
      mousePosition.x >= pageBounds.x &&
      mousePosition.x <= pageBounds.x + pageBounds.width &&
      mousePosition.y >= pageBounds.y &&
      mousePosition.y <= pageBounds.y + pageBounds.height
    );
  }

  private createNewAnnotationFromRequest(request: AnnotationCreationRequest): Annotation {
    const position = request.relativePosition.position;
    const pageBounds = request.relativePosition.boundingBox;
    const x = (position.x - pageBounds.x) / pageBounds.width;
    const y = (position.y - pageBounds.y) / pageBounds.height;
    const width = 120 / pageBounds.width;
    const height = 24 / pageBounds.height;
    const time: string = JSON.stringify(new Date()).replace(/\D/g, '');
    const id = `${time}${request.relativePosition.position.x}${request.relativePosition.position.y}${request.page}`;
    const signerId = this.isClientInput(this.state.selectedAnnotationType) ? this.state.selectedSigner.id : undefined;

    return {
      id,
      x,
      y,
      width,
      height,
      type: this.state.selectedAnnotationType as AnnotationType,
      text: '',
      fontSize: this.state.selectedFontSize,
      signerId,
      isNewlyAdded: true,
      dataPathKey:
        this.state.selectedAnnotationType === AnnotationType.AUTOFILL ? this.state.dataPathManager.getSelectedNodeKey() : undefined,
    };
  }
}
