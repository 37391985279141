import { useNavigate } from '@solidjs/router';
import dayjs from 'dayjs';
import { createSignal, For, Show } from 'solid-js';
import IconPlus from '~/assets/images/common/plus.svg?component-solid';
import IconRemove from '~/assets/images/common/remove.svg?component-solid';
import { Form, FormItem, useForm } from '~/components/common/BetterForm';
import { Button } from '~/components/common/Buttons';
import DueDateInputField from '~/components/common/Inputs/DueDateInputField';
import LabeledNumberInput from '~/components/common/Inputs/LabeledNumberInput';
import { LabeledTextInput } from '~/components/common/Inputs/LabeledTextInput';
import { Modal } from '~/components/modals/Modal';
import { LabeledPropertySearch } from '~/components/properties/PropertySearch';
import { toast } from '~/components/ui';
import { useLocalization, useManagementFee } from '~/contexts/global';

interface ManagementFee {
  propertyId: string;
  feeAmount: number;
  memo?: string;
}

interface AddManualManagementFeesModalProps {
  onClose?: () => void;
}

export const AddManualManagementFeesModal = (props: AddManualManagementFeesModalProps) => {
  const { t } = useLocalization();
  const form = useForm();
  const navigate = useNavigate();
  const { addManagementFee } = useManagementFee();
  const [managementFees, setManagementFees] = createSignal<ManagementFee[]>([{ propertyId: '', feeAmount: 0, memo: '' }]);
  const MIN_AMOUNT = 0;
  const MAX_AMOUNT = 2147483647;

  const addFee = () => {
    setManagementFees([...managementFees(), { propertyId: '', feeAmount: 0, memo: '' }]);
  };

  const removeFee = (index: number) => {
    if (managementFees().length > 1) {
      const newFees = [...managementFees()];
      newFees.splice(index, 1);
      setManagementFees(newFees);
    }
  };

  const submitManagementFees = async () => {
    const { validateStatus } = form.validateForm();
    if (validateStatus) {
      const payload: MagicDoor.Api.AddManagementFeesDto = {
        calculateFeesUntil: form.formStore.calculateFeesUntil,
        managementFees: managementFees().map((_, index) => ({
          propertyId: form.formStore[`propertyId-${index}`],
          feeAmount: form.formStore[`feeAmount-${index}`],
          memo: form.formStore[`memo-${index}`] || null,
        })),
      };

      try {
        const response = await addManagementFee(payload);
        console.log(response, 'HJUH');

        if (Array.isArray(response) && response.length === 1) {
          navigate(`/accounting/company-revenue/management-fees/${response[0].id}`);
        } else {
          if (props.onClose) {
            props.onClose();
          }
        }

        toast.success(t('{name} has been added successfully', { name: t('Management fees') }));
      } catch (error) {
        console.error('Error adding management fees:', error);
      }
    }
  };

  return (
    <Modal
      visible
      onDone={submitManagementFees}
      doneText={t('Confirm')}
      class="lg:w-modalMd"
      title={t('Add management fees')}
      loading={addManagementFee.loading}
      onCancel={() => {
        if (props.onClose) {
          props.onClose();
        }
      }}>
      <Form
        class="grid grid-cols-1 gap-4 p-5"
        defaultForm={form}
        initialValues={{
          calculateFeesUntil: dayjs().format('YYYY-MM-DD'),
        }}>
        <FormItem
          formFieldName="calculateFeesUntil"
          label={t('Calculate fees until')}
          component={DueDateInputField}
          placeholder={t('Select date')}
          rules={[{ message: t('Please select a date'), required: true }]}
        />

        <For each={managementFees()}>
          {(_, index) => (
            <div class="border p-4">
              <div class="grid grid-cols-1 gap-4 rounded lg:grid-cols-3">
                <FormItem
                  formFieldName={`propertyId-${index()}`}
                  label={t('Property')}
                  component={LabeledPropertySearch}
                  placeholder={t('Select property')}
                  rules={[{ message: t('Please select a property'), required: true }]}
                />
                <FormItem
                  formFieldName={`feeAmount-${index()}`}
                  label={t('Fee amount')}
                  component={LabeledNumberInput}
                  placeholder={t('Enter fee amount')}
                  prepend="$"
                  rules={[
                    { message: t('Please enter fee amount'), required: true },
                    {
                      range: [MIN_AMOUNT, MAX_AMOUNT],
                      type: 'number',
                      message: t('{name} must be between {min} and {max}', { name: t('Fee amount'), min: MIN_AMOUNT, max: MAX_AMOUNT }),
                    },
                  ]}
                  isBlur={false}
                />
                <FormItem
                  formFieldName={`memo-${index()}`}
                  label={t('Memo')}
                  component={LabeledTextInput}
                  placeholder={t('Enter memo (optional)')}
                  value="Management fee"
                />
              </div>
              <Show when={managementFees().length > 1 && index() > 0}>
                <div class="flex w-full items-end justify-end pt-4">
                  <Button type="button" variant="outlined" onClick={() => removeFee(index())} class="ml-auto border-link text-link">
                    <IconRemove class="cursor-pointer text-link" />
                    {t('Remove')}
                  </Button>
                </div>
              </Show>
            </div>
          )}
        </For>

        <Button variant="outlined" type="button" onClick={addFee} class="lg:w-fit">
          <IconPlus />
          {t('Add another management fee')}
        </Button>
      </Form>
    </Modal>
  );
};
