import { Show, createSignal, createMemo } from 'solid-js';
import IconContact from '~/assets/images/settings/portal/portalContactInformation.svg';
import ThreeSectionBox from '~/components/common/ThreeSectionBox/ThreeSectionBox';
import { IconPencilLine } from '~/components/ui/Icons';
import { useLocalization, useCompanies } from '~/contexts/global';
import { SettingTitle } from '~/pages/settings/SettingTitle';
import { InformationForm } from '~/pages/settings/components/InformationForm';
import { InformationOverview } from '~/pages/settings/components/InformationOverview';
import { cloneDeep } from '~/utils/tool';
import type { Section } from '~/pages/settings/account-settings/types';

export const PortalSeoInformation = () => {
  const { t } = useLocalization();
  const [editing, setEditing] = createSignal<boolean>(false);
  const { portalSettings, updatePortalSettings } = useCompanies();
  const sections = createMemo<Section[]>(() => {
    const model = portalSettings()?.metadata;
    if (model == null) return [];
    return [
      {
        field: 'keywords',
        value: model.keywords,
        label: 'Keywords',
        icon: IconPencilLine,
      },
    ];
  });
  const handleSubmit = async (info: { keywords?: string | undefined }) => {
    const params = cloneDeep(portalSettings());
    try {
      const keywords = info?.keywords?.split(',');
      params.metadata.keywords = keywords;
      await updatePortalSettings(params);
    } catch (err) {
      console.log(err);
    } finally {
      setEditing(false);
    }
  };
  return (
    <ThreeSectionBox
      class="border-0 capitalize"
      topTitle={<SettingTitle title={t('Seo information')} icon={IconContact} />}
      middle={
        <div class="p-6">
          <Show when={editing()} fallback={<InformationOverview sections={sections()} onEdit={() => setEditing(true)} />}>
            <InformationForm sections={sections()} onClose={() => setEditing(false)} onSubmit={handleSubmit} />
          </Show>
        </div>
      }
    />
  );
};
