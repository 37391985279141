import { onMount } from 'solid-js';

interface IProps {
  address: string | null | undefined;
}

export const GoogleMap = (props: IProps) => {
  let mapRef: HTMLDivElement;

  onMount(() => {
    const googleMapScript = document.createElement('script');
    googleMapScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB89updWgLCG03Q81M-bZm0ESgVpSyCZ0I&libraries=places';
    googleMapScript.async = true;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener('load', () => {
      const geocoder = new (window as any).google.maps.Geocoder();
      geocoder.geocode({ address: props.address }, (results: any, status: any) => {
        if (status === 'OK') {
          const googleMap = new (window as any).google.maps.Map(mapRef, {
            center: results[0].geometry.location,
            zoom: 15,
          });
          new (window as any).google.maps.Marker({
            position: results[0].geometry.location,
            map: googleMap,
            title: 'Center Point',
          });
        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });
    });
  });

  return <div ref={mapRef!} class="size-full" />;
};
