import { leaseTemplateRepository } from '~/pdfsigner/repositories/leaseTemplateRepository';
import { DataPathManager } from './state/dataPathManager';
import { UseCase } from './useCase';

export class GetDataPathsUseCase extends UseCase {
  public async runLogic(leaseId?: string): Promise<void> {
    const dataPaths = await leaseTemplateRepository.getDataPaths(leaseId);
    this.state.dataPathManager = new DataPathManager(dataPaths);
  }
}
